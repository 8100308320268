import React, { useEffect, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from "../../service/apiService";
import { useDispatch, useSelector } from "react-redux";
import {
  updateRefreshFolderData,
  updateRefreshFolders,
} from "../../redux/documents";
import Select from "react-select";
import "./documents.css";

const AddNewFolder = ({ isOpen, onClose, activeProject }) => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([]);

  const usersData = useSelector(state => state.allUserData.users)

  useEffect(() => {
    setUsers(usersData.map((user) => ({value: `${user.first_name} ${user.last_name}`, label: `${user.first_name} ${user.last_name}`, _id: user._id})))
  }, [])

  const handleChange = (selectedUsers) => {
    setSelectedUsers(selectedUsers || []);
  };

  const handleSubmit = async () => {
    let payload = {
      folderName: name,
      parentFolderId: "",
      permissions: selectedUsers.map((user) => user._id),
      projectId: activeProject?._id
    };

    let response = [];

    try {
      response = await apiServiceHandler(
        "POST",
        "api/file/management/create/folder",
        payload
      );
      // dispatch(updateRefreshFolderData(true));
      dispatch(updateRefreshFolders(true));
      onClose();
      toastr.success(response?.message);
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

  const customStyles = {
    control: (provided) => ({
        ...provided,
        width: window.screen.width < 760 ? 359 : 460,
    }),
};



  return (
    <div>
      <div
        className={`modal fade add-user ${isOpen ? "show backdrop" : " "}`}
        id="addUserModal"
        tabindex={-1}
        aria-labelledby="addUserModalLabel"
        aria-hidden={true}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h2 className="modal-title">Add New Folder</h2>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <img src={CloseIcon} />
              </button>
            </div>

            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label class="folder-name" style={{fontSize: '1rem', fontWeight: 600}}>Folder Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="folder_name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Folder name*"
                      required
                      autoFocus
                    />
                  </div>
                </div>
              </form>
              <div className="col-md-12 mb-2">
              <label class="folder-name" style={{fontSize: '1rem', fontWeight: 600}}>Share With</label>
                <Select
                  options={users}
                  onChange={handleChange}
                  value={selectedUsers}
                  isSearchable={true}
                  isMulti
                  styles={customStyles}
                  className="multi-select"
                  placeholder="Share With*"
                />
              </div>
            </div>

            <div className="modal-footer justify-content-between">
              <button type="submit" className="bg-btn bg-btn-mobile" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="bg-btn bg-btn-mobile" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewFolder;
