import React, { useEffect, useState } from 'react';
import './welcome.css';
// import ProfilePicture from '../../assests/images/profile-pic.svg';
import ProfilePicture from '../../assests/images/avatar.svg';
import EyeIcon from '../../assests/images/eye-white.svg';
import EditwIcon from '../../assests/images/edit.svg';
import DragIcon from '../../assests/images/drag.svg';
import ViewProgressModal from './viewProgress';
import TickIcon from '../../assests/images/tick-black.svg';
import TickGreyIcon from '../../assests/images/tick-grey.svg';
import { useSelector } from 'react-redux';

const WelcomePage = ({ setWelcomePageProps }) => {
    const userData = localStorage.getItem('userData')
    const allData = JSON.parse(userData)
    const [openViewModal, setOpenViewModal] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState("Welcome to this dedicated section tailored to showcase personalized messages for specific users. Its primary purpose is to assist and orient users in navigating SouthBisonConnect effectively. By assigning this message box to user roles within the Manage Roles section, you can ensure that  only users with the appropriate access can access and view this information. This feature empowers you to guide and inform your users seamlessly as they engage with SouthBisonConnect.");
    const userInfo = useSelector(state => state.userData.userInfo)

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);

    };
    const handleTextChange = (e) => {
        setEditedText(e.target.textContent);
    };

    const handleClose = () => {
        setOpenViewModal(false)

    }
    useEffect(() => {
        if (openViewModal) {
            setWelcomePageProps({
                ModalName: 'ViewProgress',
                isOpen: openViewModal,
                onClose: handleClose
            })
        } else {
            setWelcomePageProps(null)
        }
    }, [openViewModal])

    return (
        // <div className="col-md-6 mb-4">
        <div className="welcome-box">
            <div className="welcome-top mb-2 d-flex align-items-center justify-content-between">
                <div className="profile-area d-flex align-items-center">
                    <div className="profile-img me-4">
                        <img src={userInfo?.logo ? userInfo?.logo : ProfilePicture} />
                    </div>
                    <div className="profile-info">
                        <h2 className="mb-2">Welcome, {allData?.first_name}</h2>
                        <h3 className="mb-0">{allData?.company_name}</h3>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    {/* <button type="button" className="edit-welcome"><img src={EditwIcon} /></button> */}
                    <button type="button" data-bs-target="#viewProgressModal" className="all-progress" onClick={() => setOpenViewModal(true)}>
                        <img src={EyeIcon} />
                        <span>All Progress</span>
                    </button>
                    <button type="button" className="drag-me"><img src={DragIcon} /></button>
                </div>

            </div>
            <div className="mb-3 text-end">
                {/* <button type="button" className="edit-welcome"><img src={EditwIcon} />
                    </button> */}
                {allData.role === 'client' ? '' : (!isEditing ? (
                    <button type="button" className="edit-welcome" onClick={handleEditClick}>
                        <img src={EditwIcon} />
                    </button>
                ) : (
                    <button type="button" className="edit-welcome" onClick={handleSaveClick}>
                        <img src={TickGreyIcon} />
                    </button>
                ))}
            </div>

            <p
                className="mb-0"
                contentEditable={isEditing}
                onBlur={handleTextChange}
            >
                {editedText}
            </p>


            {/* <p className="mb-0">Abc, This page has been designed for you to keep up with all of the work being done by your Deverybody team. We put deadlines and details as priority number 1. Each day review tasks assigned to you that your Deverybody team may need input on. Keep an eye on upcoimg appointments and celebrate with us as we hit. </p> */}

        </div>

    )
}

export default WelcomePage;
