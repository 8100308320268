import React, { useEffect, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshFolders } from "../../redux/documents";

const AddNewFile = ({ isOpen, onClose }) => {

  const [name, setName] = useState("")

  return (
    <div>
      <div
        className={`modal fade add-user ${isOpen ? "show backdrop" : " "}`}
        id="addUserModal"
        tabindex={-1}
        aria-labelledby="addUserModalLabel"
        aria-hidden={true}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h2 className="modal-title">Add New File</h2>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <img src={CloseIcon} />
              </button>
            </div>

            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="file_name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="File name*"
                      required
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer justify-content-between">
              <button type="submit" className="bg-btn" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="bg-btn">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewFile;
