// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const task = createSlice({
  name: 'task',
  initialState: {
    refreshTaskList: false,
    TasksList: [],
    showTasksList: [],
    editData: [],
    statusTaskList: [],
    newAppointments: [],
    commentList: []
  },
  reducers: {
    updateTasksList: (state, action) => {
      state.TasksList = action.payload; // Update the count using the payload
    },
    updateShowTasksList: (state, action) => {
      state.showTasksList = action.payload; // Update the count using the payload
    },
    updateRefreshTaskList: (state, action) => {
      state.refreshTaskList = action.payload; // Update the count using the payload
    },
    updateEditTaskData: (state, action) => {
      state.editData = action.payload; // Update the count using the payload
    },
    getTaskStatusData: (state, action) => {
      state.statusTaskList = action.payload; // Update the count using the payload
    },
    getNewAppointments: (state, action) => {
      state.newAppointments = action.payload; // Update the count using the payload
    },
    getcommentList: (state, action) => {
      state.commentList = action.payload; // Update the count using the payload
    }
    
  },
});

export const { updateTasksList, getcommentList, updateShowTasksList, updateRefreshTaskList, updateEditTaskData, getTaskStatusData, getNewAppointments } = task.actions;
export default task.reducer;
