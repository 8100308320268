import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux';
import { updatePaypalSetupData, updateopenInvoicepopup, updatopenPayPalsetup } from '../../redux/invoice';
import "./createInvoice.css";
import { isEmpty } from '../../common/helper'

const PaypalSetup = ({ setRefreshData, edituserData }) => {
    const invoiceFlag = useSelector((state) => state.invoice.openPayPalsetup);
    const dispatch = useDispatch()
    const [payPalData, setPayPalData] = useState({})
    const [userUpdatedData, setUserUpdatedData] = useState({
        email: "",
        paypal_client_id: "",
        paypal_client_secret: "",
    })



    const handleChange = (event) => {
        const { name, value } = event.target;

        setUserUpdatedData((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSave = async () => {

        const payload = {
            clientId: userUpdatedData.paypal_client_id,
            clientSecret: userUpdatedData.paypal_client_secret,
            clientEmail: userUpdatedData.email
        }

        try {
            let response = []
            if (payPalData.length) {
                response = await apiServiceHandler("POST", "api/payment/paypal/setup/update", payload)

            } else {
                response = await apiServiceHandler("POST", "api/payment/paypal/setup", payload);
            }
            toastr.success(response?.message)
            fetchPayPalSetupData()
            dispatch(updatopenPayPalsetup(false))
            setUserUpdatedData({
                email: "",
                paypal_client_id: "",
                paypal_client_secret: "",
            })
        } catch (error) {
            console.log(error)
        }
    }

    // fetch paypal setup data

    const fetchPayPalSetupData = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/payment/paypal/setup", {})
            setPayPalData(response?.data)
            let result = response?.data || {}
            if (!isEmpty(result)) {
                dispatch(updatePaypalSetupData(result))
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPayPalSetupData()
    }, [])

    useEffect(() => {
        if (payPalData) {
            setUserUpdatedData({
                email: payPalData?.client_email || "",
                paypal_client_id: payPalData?.client_id || "",
                paypal_client_secret: payPalData?.client_secret || "",
            })
        }
    }, [payPalData])




    return (
        <div>
            <div
                className={`modal fade edit-profile edit-task-viewmore ${invoiceFlag ? "show backdrop" : " "}`}
                id="editProfileModal"
                tabindex="-1"
                aria-labelledby="editProfileModalLabel"
                aria-hidden="true"
                style={{ display: invoiceFlag ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 ">
                        <div className="modal-header">
                            <h2 className="modal-title">PayPal Setup</h2>
                            <div style={{ display: "flex" }}>
                                <button type="button" style={{ width: "50px !important", marginRight: "10px" }} className="bg-btn" onClick={() => dispatch(updateopenInvoicepopup(false))}>
                                    Help
                                </button>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(updatopenPayPalsetup(false))}>
                                    <img src={CloseIcon} />
                                </button>
                            </div>

                        </div>

                        <div className="modal-body">
                            <form>
                                <div className="row">


                                    <div class="col-md-12 mb-2" >
                                        <h3>Setup your paypal account to receive payments, send invoices and charge a subscription fee from your user </h3>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <label>Paypal Client Id</label>
                                        <input type="text" className="form-control" name='paypal_client_id' placeholder="Paypal Client Id*" value={userUpdatedData?.paypal_client_id} onChange={handleChange} />
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label>Paypal Client Secret</label>
                                        <input type="text" className="form-control" placeholder="Paypal Client Secret*" name='paypal_client_secret' value={userUpdatedData?.paypal_client_secret} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-122 mb-2">
                                        <label>Paypal email</label>
                                        <input type="email" className="form-control" placeholder="PayPal email*" name='email' value={userUpdatedData?.email} onChange={handleChange} />
                                    </div>
                                </div>

                            </form>

                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="submit" className="bg-btn" onClick={handleSave}>Save</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default PaypalSetup;
