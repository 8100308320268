import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import { useParams } from 'react-router-dom';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshInvoiceList, updateopenInvoicepopup } from '../../redux/invoice';
import Select from 'react-select'
import AddIcon from '../../assests/images/add.svg';
import JllLogo from '../../assests/images/jll_logo.svg';
import "./createInvoice.css";
import Trash from "../../assests/images/trash.svg";
import { countries } from 'countries-list';

const CreateInvoice = ({ setRefreshData, edituserData }) => {
    const invoiceFlag = useSelector((state) => state.invoice.openInvoicepopup);
    const usersList = useSelector(state => state.allUserData.users) || []
    const [userUpdatedData, setUserUpdatedData] = useState(edituserData)
    const [rolesList, setRolesList] = useState([])
    const [userData, setUserData] = useState({})
    const [uploadedFiles, setUploadedFiles] = useState(null)
    const { id } = useParams()
    const dispatch = useDispatch()
    const [category, setCategory] = useState([])
    let usermData = localStorage.getItem("userData")
    usermData = usermData ? JSON.parse(usermData) : {}
    const [invoiceData, setInvoiceData] = useState({
        email: "",
        invoice_number: `${usermData?.first_name.charAt(0).toUpperCase()}${usermData?.last_name.charAt(0).toUpperCase()}-${Math.round(Math.random() * 999999)}`,
        invoice_date: "",
        // tax: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
        notes: "",
        items: []
    })

    const [selectedCountry, setSelectedCountry] = useState({ countryCode: '', countryName: '', countryPhoneCode: '' });

    const handleCountryChange = (e) => {
        setSelectedCountry({
            countryCode: e.target.value,
            countryName: countries[e.target.value].name,
            countryPhoneCode: countries[e.target.value].phone[0],
        });
    };

    const [items, setItems] = useState([])

    useEffect(() => {
        fetchUserData()
    }, []);
    const [errorMessage, setErrorMessage] = useState('');

    const Options = usersList?.map((val, index) => ({
        value: val?._id, label: `${val?.first_name} ${val?.last_name}`
    }))


    const handleChange = (event) => {
        const { name, value } = event.target;
        setInvoiceData((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const [milestones, setMilestones] = useState([
        {
            "name": "",
            "description": "",
            "quantity": "1",
            "unit_amount": {
                "currency_code": "USD",
                "value": ""
            },
            "unit_of_measure": "QUANTITY"
        }
    ]);

    const addMilestone = () => {
        setMilestones([
            ...milestones,
            {
                "name": "",
                "description": "",
                "quantity": "1",
                "unit_amount": {
                    "currency_code": "USD",
                    "value": ""
                },
                "unit_of_measure": "QUANTITY"
            },
        ]);
    };

    const handleMilestoneChange = (index, name, value) => {
        setMilestones((prevMilestones) => {
            const updatedMilestones = [...prevMilestones];
            const updatedValue = value.target ? value.target.value : value;
            updatedMilestones[index] = {
                ...updatedMilestones[index],
                [name]: updatedValue
            };
            return updatedMilestones;
        });
    };

    const deleteMilestone = (index) => {
        const updatedMilestones = [...milestones];
        updatedMilestones.splice(index, 1);
        setMilestones(updatedMilestones);
    };

    const handleMilestoneAmountChange = (index, name, value) => {
        setMilestones((prevMilestones) => {
            const updatedMilestones = [...prevMilestones];
            const updatedValue = value.target ? value.target.value : value;
            updatedMilestones[index] = {
                ...updatedMilestones[index],
                [name]: updatedValue
            };
            return updatedMilestones;
        });
    };

    const updateMilestone = (index, newName, field) => {
        // Make a copy of the milestones array to avoid mutating the original array
        const updatedMilestones = [...milestones];

        // Update the values of the milestone at the specified index
        if (index >= 0 && index < updatedMilestones.length) {
            if (field === "tax") {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    tax: {
                        ...updatedMilestones[index].tax,
                        name: newName !== undefined ? newName : updatedMilestones[index].tax.name
                    }
                };
            } else {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    name: newName !== undefined ? newName : updatedMilestones[index].name,
                };
            }

            // Set the updated milestones array
            setMilestones(updatedMilestones);
        } else {
            console.error("Invalid index provided for updating milestone.");
        }
    }

    const updateMilestoneAmount = (index, newValue, field) => {
        // Make a copy of the milestones array to avoid mutating the original array
        const updatedMilestones = [...milestones];

        // Update the values of the milestone at the specified index
        if (index >= 0 && index < updatedMilestones.length) {
            if (field === "unit_amount") {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    [field]: {
                        ...updatedMilestones[index][field],
                        value: newValue !== undefined ? newValue : updatedMilestones[index][field].value
                    }
                };
            } else if (field === "tax") {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    tax: {
                        ...updatedMilestones[index].tax,
                        percent: newValue !== undefined ? newValue : updatedMilestones[index].tax.percent
                    }
                };
            } else {
                console.error("Invalid field provided for updating milestone.");
                return;
            }
            // Set the updated milestones array
            setMilestones(updatedMilestones);
            console.log(updatedMilestones);
        } else {
            console.error("Invalid index provided for updating milestone.");
        }
    }


    const customStyles = {
        control: (provided) => ({
            ...provided,
            // width: 720, // Set the width as per  requirement
        }),
    };

    const handleChangeCategory = (value) => {
        setCategory(value);
    }

    const fetchUserData = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/user/details", {})
            setUserData(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleCreateInvoice = async (e) => {
        e.preventDefault();
        if (category?.value === undefined) {
            toastr.info('Select user to send Invoices');
            return;
        }
        // Show loading toaster
        toastr.info('Creating Invoice...', {
            closeButton: false,
            timeOut: 0,
            extendedTimeOut: 0,
        });
        const payload = {
            "invoice_number": invoiceData.invoice_number,
            "invoice_date": invoiceData.invoice_date,
            "paypal_email_address": invoiceData.email,
            "invoice_generated_by": userData?._id,
            "invoice_generated_to": category?.value,
            "address_line_1": invoiceData.address_line_1,
            "address_line_2": invoiceData?.address_line_2 || '',
            "city": invoiceData.city,
            "state": invoiceData.state,
            "country": selectedCountry,
            "zip_code": invoiceData.zip_code,
            "items": milestones,
            "notes": invoiceData?.notes || 'No notes found'
        }
        try {
            const response = await apiServiceHandler("POST", "api/invoice", payload);
            // console.log(response)
            dispatch(updateopenInvoicepopup(false))
            dispatch(updateRefreshInvoiceList(true))
            toastr.success(response?.message)
            setCategory([]);
            setInvoiceData(
                {
                    email: "",
                    invoice_number: `${usermData?.first_name.charAt(0).toUpperCase()}${usermData?.last_name.charAt(0).toUpperCase()}-${Math.round(Math.random() * 999999)}`,
                    invoice_date: "",
                    tax: "",
                    address_line_1: "",
                    address_line_2: "",
                    city: "",
                    state: "",
                    country: "",
                    zip_code: "",
                    notes: "",
                }
            )
            setMilestones([
                {
                    "name": "",
                    "description": "",
                    "quantity": "1",
                    "unit_amount": {
                        "currency_code": "USD",
                        "value": ""
                    },
                    "unit_of_measure": "QUANTITY"
                }
            ])
            setSelectedCountry({ countryCode: '', countryName: '', countryPhoneCode: '' })
        } catch (err) {
            invoiceData.invoice_number = `${usermData?.first_name.charAt(0).toUpperCase()}${usermData?.last_name.charAt(0).toUpperCase()}-${Math.round(Math.random() * 999999)}`
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }
    }

    const CalculateSubtotal = () => {
        let subtotalWithTax = 0;

        milestones.forEach(value => {
            const unitAmount = parseFloat(value.unit_amount.value);
            subtotalWithTax = unitAmount ? unitAmount : null;
        });

        return subtotalWithTax;
    };

    return (
        <div style={{ display: invoiceFlag ? "block" : "none" }} class={`modal fade new-invoice show ${invoiceFlag ? "show backdrop" : " "}`} id="newInvoiceModal" tabindex="-1" aria-labelledby="newInvoiceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content border-0">
                    <div class="modal-header">
                        <h2 class="modal-title">New Invoice {invoiceData.invoice_number ? `(${invoiceData.invoice_number})` : ""}</h2>
                        <button onClick={() => dispatch(updateopenInvoicepopup(false))} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src={CloseIcon} />
                        </button>
                    </div>
                    <div class="modal-body">
                        <form onSubmit={handleCreateInvoice}>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row g-0 align-items-center">
                                        <div class="col-md-6">
                                            <img src={JllLogo} width="100" />
                                        </div>
                                        <div class="col-md-6">
                                            <div class="d-flex align-items-center">
                                                <select class="form-select" aria-label="recurring">
                                                    <option value="Amounts Only">Amounts Only</option>
                                                </select>
                                                <div class="ms-3 fw-bold">USD</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <h3>Customer Details</h3>
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <label>Email*</label>
                                            <input onChange={(e) => handleChange(e)} name='email' value={invoiceData.email} type="email" class="form-control" placeholder='Enter email address' required />
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label>Select user to send Invoice*</label>
                                            <Select
                                                className="btn-color"
                                                options={Options}
                                                name='category'
                                                styles={customStyles}
                                                value={category}
                                                onChange={(e) => handleChangeCategory(e)}
                                                style={{ width: "auto" }}
                                            />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Address Line 1*</label>
                                            <input value={invoiceData.address_line_1} name='address_line_1' onChange={handleChange} type="text" class="form-control" placeholder='Enter address line 1' required />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Address Line 2</label>
                                            <input value={invoiceData.address_line_2} name='address_line_2' onChange={handleChange} type="text" placeholder='Enter address line 2' class="form-control" />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>City*</label>
                                            <input type="text" value={invoiceData.city} onChange={handleChange} name='city' class="form-control" placeholder='Enter city name' required />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>State*</label>
                                            <input type="text" onChange={handleChange} value={invoiceData.state} name='state' class="form-control" placeholder='Enter state name' required />
                                        </div>
                                        <div className="col-md-6 mb-3 mb-md-0">
                                            <label>Country*</label>
                                            <select
                                                value={selectedCountry.countryCode}
                                                onChange={handleCountryChange}
                                                className="form-control"
                                                required
                                            >
                                                <option value="">Select a country</option>
                                                {Object.keys(countries).map((countryCode) => (
                                                    <option key={countryCode} value={countryCode}>
                                                        {countries[countryCode].name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Postal Code*</label>
                                            <input type="text" value={invoiceData.zip_code} name='zip_code' onChange={handleChange} class="form-control" placeholder='Enter valid zipcode' required />
                                        </div>
                                    </div>
                                    <hr />
                                    <h3>Items*</h3>
                                    <div class="items-name">
                                        {milestones.map((milestone, index) => (
                                            <div className="milestone-loop" key={index}>
                                                <div className="row">
                                                    <div className="col-md-8 mb-2">
                                                        {/* <label>Enter ending date</label> */}
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Item name*"
                                                            name="item_name"
                                                            value={milestone.name}
                                                            onChange={(e) => updateMilestone(index, e.target.value, 'unit_amount')}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mb-2">
                                                        {/* <label>Enter ending date</label> */}
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="amount*"
                                                            name="amount"
                                                            value={milestone.unit_amount.value}
                                                            onChange={(e) => updateMilestoneAmount(index, e.target.value, 'unit_amount')}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-md-1 mb-2">
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() => deleteMilestone(index)}
                                                        >
                                                            <img src={Trash} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {/*
                                                <div className='row'>
                                                    <div className='col-md-8 mb-3'>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tax name"
                                                            name="tax_name"
                                                            value={milestone.tax.name}
                                                            onChange={(e) => updateMilestone(index, e.target.value, 'tax')}
                                                            //
                                                            required
                                                        />
                                                    </div> 
                                                    <div className="col-md-4 mb-2">
                                                        <label>Enter ending date</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tax Percent"
                                                            name="tax_percent"
                                                            value={milestone.tax.percent}
                                                            onChange={(e) => updateMilestoneAmount(index, e.target.value, 'tax')}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                */}
                                            </div>
                                        ))}
                                        <div className="add-more-mile">
                                            <button type="button"
                                                onClick={addMilestone}
                                            >
                                                <img src={AddIcon} alt='plus' /> Add Another Item
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="mb-3">
                                        <label>Notes</label>
                                        <textarea value={invoiceData.notes} name='notes' onChange={handleChange} class="form-control" rows="3"></textarea>
                                    </div>
                                    <div class="text-end">
                                        <button type="submit" class="bg-btn">Create</button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <label>New Invoice</label>
                                        <input readonly="true" type="text" onChange={handleChange} value={invoiceData.invoice_number} name='invoice_number' class="form-control" placeholder="invoice number" required />
                                    </div>
                                    <div class="mb-3">
                                        <label>Date*</label>
                                        <input type="date" value={invoiceData.invoice_date} name='invoice_date' onChange={handleChange} class="form-control" required />
                                    </div>
                                    <div class="mb-3">
                                        <label>Recurring</label>
                                        <select class="form-select" aria-label="recurring">
                                            <option value="One Time">One Time</option>
                                        </select>
                                    </div>
                                    {/* <hr /> */}
                                    {/* <div class="row mb-3">
                                        <div class="col-md-7">Subtotal</div>
                                        <div class="col-md-5 text-end">${CalculateSubtotal()}</div>
                                    </div> */}
                                    {/* <div class="mb-3">
                                        <label>Tax</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Item name"
                                            name="item_name"
                                            value={milestone.tax.name}
                                            onChange={(e) => updateMilestone(index, e.target.value)}
                                            //
                                            required
                                        />
                                        <input type="number" min={0} step={1} value={invoiceData.tax} name='percent' onChange={handleChange} class="form-control" />
                                    </div> */}
                                    <hr />
                                    <div class="row">
                                        <div class="col-md-7"><strong>Total Amount</strong></div>
                                        <div class="col-md-5 text-end"><strong>${CalculateSubtotal()}</strong></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateInvoice;
