// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userManagement = createSlice({
  name: 'userManagement',
  initialState: {
    editAndDeleteData: {},
    openDeletePopup: false,
    refreshUserData: false,
    users: [],
    deleteRoleData: {},
    openRoleDeletePopup: false,
    userToAccessData: [],
    userAccessRole: [],
    accessRoleList: [],
    roleList: [],
    paymentDetails: []
  },
  reducers: {
    updateEditAndDeleteData: (state, action) => {
      state.editAndDeleteData = action.payload; // Update the count using the payload
    },
    updateOpenDeletePopup: (state, action) => {
      state.openDeletePopup = action.payload; // Update the count using the payload
    },
    updateRefreshUserDatap: (state, action) => {
      state.refreshUserData = action.payload; // Update the count using the payload
    },
    updateUsers: (state, action) => {
      state.users = action.payload; // Update the count using the payload
    },
    updateAccessRoleList: (state, action) => {
      state.accessRoleList = action.payload; // Update the count using the payload
    },
    updateDeleteRoleData: (state, action) => {
      state.deleteRoleData = action.payload; // Update the count using the payload
    },
    updateDeleteRolePopup: (state, action) => {
      state.openRoleDeletePopup = action.payload; // Update the count using the payload
    },
    updateUserToAccessData: (state, action) => {
      state.userToAccessData = action.payload; // Update the count using the payload
    },
    updateUserToAccessRole: (state, action) => {
      state.userAccessRole = action.payload; // Update the count using the payload
    },
    updateRoleList: (state, action) => {
      state.roleList = action.payload; // Update the count using the payload
    },
    updatePaymentDetails: (state, action) => {
      state.paymentDetails = action.payload; // Update the count using the payload
    },

  },
});

export const { updateEditAndDeleteData, updateUserToAccessRole, updateOpenDeletePopup, updateRefreshUserDatap, updateRoleList, updateUsers, updateAccessRoleList, updateDeleteRoleData, updateDeleteRolePopup, updateUserToAccessData, updatePaymentDetails } = userManagement.actions;
export default userManagement.reducer;
