import React from 'react'
import CloseIcon from '../../assests/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshProjectList } from '../../redux/project';
import { updateProjectsCount } from '../../redux/userlogs';

const DeleteProject = ({ isOpenModal, onCloseModal, clickUpProjectListUpdate }) => {
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('userData'));
    const projectEditData = useSelector(state => state.project.editData) || []
    // const deleteData = useSelector(state => state.allUserData)
    // let userData = deleteData.editAndDeleteData;
    // console.log(projectEditData._id, 'UserData delete is here...')
    const projectListMap = useSelector((state) => state.clickup.clickupJLLProjectsList);


    const handleDelete = async () => {
        const payload = {
            _id: projectEditData._id,
            folder_id:  projectListMap?.[projectEditData?.project_name] || null
        }
        try {
            const response = await apiServiceHandler("POST", "api/project/delete", payload)
            const projectCount = await apiServiceHandler(
                "POST",
                "api/auth/user/project/count",
                { company_id: user.parent_company_id ? user.parent_company_id : user.company_id }
            );
            dispatch(updateProjectsCount(projectCount.data));
            toastr.success(response?.message)
            dispatch(updateRefreshProjectList(true))
            onCloseModal()
            clickUpProjectListUpdate()
        } catch (err) {
            console.log("error", err)
        }
    }
    // const handleClose = () => {
    //     handleDelete();
    // }
    return (
        <div>
            <div
                className={`modal fade delete-user ${isOpenModal ? "show backdrop" : " "}`}
                id="deleteProjectModal"
                tabindex="-1"
                aria-labelledby="deleteProjectModalLabel"
                aria-hidden="true"
                style={{ display: isOpenModal ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h2 className="modal-title">Delete User</h2>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseModal}>
                                <img src={CloseIcon} />
                            </button>
                        </div>

                        <div className="modal-body text-center">
                            <h3>Are you sure?</h3>
                            <p className="mb-0">Are you sure you want to delete this project?</p>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="button" data-bs-dismiss="modal" className="border-btn" onClick={onCloseModal}>Cancel</button>
                            <button onClick={handleDelete} type="submit" className="bg-btn">Delete</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default DeleteProject
