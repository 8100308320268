import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const AddNewUserRole = ({ isOpen, onClose, setRefreshRoleList }) => {
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('userData'));
    const accessToData = useSelector(state => state.allUserData?.userToAccessData)
    const currentRole = useSelector(state => state.allUserData?.userAccessRole)
    const [dropDownToggle, setDropdownToggle] = useState(false)
    const [allAccessOption, setAllAccessOption] = useState([])
    const [allAccess, setAllAccess] = useState([])
    useEffect(() => {

        let list = []
        Object.values(accessToData).map((value) =>
            list.push({
                value: value, label: value.split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
            }))
        setAllAccess(list)


    }, [accessToData])

    const getAccessList = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/get_access_list", {})
            // console.log(response)
            let data = response?.accessList || []
            let list = []
            Object.keys(data).map((value, i) =>
                list.push({
                    value: value, label: value.trim().split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                }))
            setAllAccessOption(list)
            // dispatch(updateAccessRoleList(roleAccessList))
        } catch (err) {
            console.log(err)
        }
    }

    const handleEditRoles = async () => {
        const payload = {
            company_id: user.company_id ? user.company_id : user.parent_compnay_id,
            role: currentRole,
            accessToApis: {}
        }
        allAccess.forEach((val) => {
            payload.accessToApis[val.value] = true
        })

        try {
            const response = await apiServiceHandler("PUT", "api/auth/company_details", payload)
            setRefreshRoleList(true)
            toastr.success(response?.message)
            onClose()
        } catch (err) {
            toastr.error("error in updating role")
            console.log(err)
        }
    }

    useEffect(() => {
        getAccessList()
    }, [])

    const handleRoleDropdown = (value) => {
        setRefreshRoleList(true)
        setAllAccess(value)
    }

    return (
        <div className={`modal fade user-role edit-task-viewmore ${isOpen ? "show backdrop" : " "}`} style={{ display: isOpen ? "block" : "none" }} id="userRoleModal" tabindex="-1" aria-labelledby="userRoleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title">User Role</h2>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                            <img src={CloseIcon} />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="role-data">
                            <div className="dropdown atc-dropdown">
                                <Select
                                    name='access_role'
                                    options={allAccessOption}
                                    isMulti
                                    onChange={(e) => handleRoleDropdown(e)}
                                    value={allAccess}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="submit" onClick={handleEditRoles} className="bg-btn">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewUserRole
