import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshReportsList, updateReportsList } from '../../redux/reportsandAnalytics';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import DatePicker from "react-datepicker";
import { eachDayOfInterval, format, subDays, subMonths } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import apiServiceHandler from '../../service/apiService';
import Select from 'react-select'

const ReportsPage = ({ flag, activeTab }) => {

    const [showReportsList, setShowReportsList] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);


    const dispatch = useDispatch()
    const usersList = useSelector(state => state.allUserData.users)
    const projects = useSelector(state => state.project?.showProjectList) || []
    const reportsList = useSelector(state => state.reportsAnalytics?.reportsList) || []

    const [flagUsers, setFlagUsers] = useState(false)

    const userIds = usersList.map((item) => {
        return item._id

    })


    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState([currentDate]);

    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(currentDate);
    const formattedCurrentDate = currentDate.toLocaleDateString(undefined, {
        weekday: "long",
        month: "long",
        day: "numeric",
    });
    const [formattedDate, setFormattedDate] = useState(formattedCurrentDate);

    const handleDateChange = async (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    
        // Generate the array of dates
        const dateArray = eachDayOfInterval({ start, end });
        setFlagUsers(true)
        setSelectedDate(dateArray);
      };

    function getCurrentFormattedDate() {
        let date = new Date()
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const transformData = (data) => {
        return data.map((item) => ({
            ...item,
            value: item._id,
            label: `${item.first_name} ${item.last_name}`
        }));
    }
    const options = transformData(usersList) || [];

    const transformProjectData = (data) => {
        return data.map((item) => ({
            ...item,
            value: item._id,
            label: `${item.project_name}`
        }));
    }
    const projectOptions = transformProjectData(projects) || [];

    const handleFilterUsers = (selectedValue) => {

        setSelectedUsers(selectedValue);
        setFlagUsers(true)
    }
    const handleFilterProjects = (selectedValue) => {
        setSelectedProjects(selectedValue);
        setFlagUsers(true)
    }
   
    useEffect(() => {
        if (flagUsers) {
            handleReportsData()
            setFlagUsers(false)
        }
    }, [flagUsers])

    const handleWeeklyClick = () => {
        const newStartDate = subDays(new Date(), 6);
        const newEndDate = new Date();
        const dateArray = eachDayOfInterval({ start: newStartDate, end: newEndDate });
    
    
        setSelectedDate(dateArray);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      };
      
      const handleMonthlyClick = () => {
        const newStartDate = subMonths(new Date(), 1);
        const newEndDate = new Date();
        const dateArray = eachDayOfInterval({ start: newStartDate, end: newEndDate });
    
    
        setSelectedDate(dateArray);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      };
  
    const fetchUserName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';
        const user = usersList.find((user) => user._id === userId);
        if (user) {
            const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
            const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
            return `${firstName} ${lastName}`;
        }


        return 'Unknown';
    };

    const handleReportsData = async () => {
        let payload = {
            filterDate: selectedDate.map((date) => {return format(date, 'yyyy-MM-dd')})
            ,
            projectArray: [],
            users: [],
        };

        let usersIdSelect = []
        selectedUsers.map((val) => usersIdSelect.push(val._id))

        payload.users = usersIdSelect.length > 0 ? usersIdSelect : userIds

        let projectusersList = []
        selectedProjects.map((val) => projectusersList.push(val))
        payload.projectArray = projectusersList.length > 0 ? projectusersList : projects

        // let selectedNewDate = ""
        // payload.filterDate = selectedDate ? selectedDate : currentDate


        try {
            const response = await apiServiceHandler("POST", "api/project/get_time_tracking", payload)
            dispatch(updateRefreshReportsList())
            setShowReportsList(response)
            dispatch(updateReportsList(response))
            // toastr.success("Successfully updated the reports table")
        } catch (error) {
            // toastr.error(error?.response?.data?.message)
            console.log(error)
        }
    }

    useEffect(() => {
        if (activeTab === "reports") {
            handleReportsData()
        }
    }, [activeTab, selectedDate])

    const timeSet = (val) => {
        const [hours, minutes] = val.split(',').map(Number);
        const hoursText = `${hours} Hr` ; 
        const minutesText = `${minutes} mins`; 
        const result = `${hoursText} ${minutesText}`.trim();

        return result;
    }



    return (
        <div className="chat-data-tab">
            <div className="filter-data d-md-flex align-items-center justify-content-between">
                <div className="date-select me-2 mb-3 col-md-8">
                    <label className='add-project-label d-block mb-1'>Select Date</label>
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat="MMMM d, yyyy"
                        className="date-picker"
                        isClearable
                    />
                    <button className= "custom-button custom-mobile-view" onClick={handleWeeklyClick}>Last 7 days</button>
                    <button className= "custom-button"  onClick={handleMonthlyClick}>Last Month</button>
                </div>
                <div className="d-flex align-items-center column-fd col-md-4 justify-content-end mobile-filter">
                    <div className="users-select me-2 ">
                        <label className='add-project-label mb-1'>Filter Users</label>
                        <Select
                            className="btn-color"
                            options={options}
                            name='users'
                            isMulti
                            value={selectedUsers}
                            onChange={handleFilterUsers}
                        />

                    </div>
                    <div className="project-select me-2">
                        <label className='add-project-label mb-1'>Filter Projects</label>
                        <Select
                            className="btn-color"
                            options={projectOptions}
                            name='projectArray'
                            isMulti
                            value={selectedProjects}
                            onChange={handleFilterProjects}
                        />

                    </div>
                </div>
            </div>
            <div className="tickets-tab-label d-none d-lg-block mb-3 mt-3">
                <div className="row g-0">
                    <div className="col-lg-1">
                        <h3>#</h3>
                    </div>
                    <div className="col-lg-2 text-lg-center">
                        <h3>Project</h3>
                    </div>
                    <div className="col-lg-3 text-lg-center">
                        <h3>Task Name</h3>
                    </div>
                  
                    <div className="col-lg-3 text-lg-center">
                        <h3>Users</h3>
                    </div>
                    <div className="col-lg-3 text-lg-end">
                        <h3>Time Tracked</h3>
                    </div>
                    {/* <div className="col-lg-2 text-end">
                        <h3>Time Period</h3>
                    </div> */}
                </div>
            </div>
            <div className={!flag ? "reports-data" : "reports-data-viewmore"}>

                {reportsList && reportsList.map((value, index) => (
                    <div key={index} className="row g-0 reports-row-data">
                        <div className="col-lg-1">
                            <p className="mb-0">{index + 1}</p>
                        </div>
                        <div className="col-lg-2 text-lg-center">
                            <p className="mb-0">{value?.projectName}</p>
                        </div>
                        <div className="col-lg-3 text-lg-center">
                            <p className="mb-0">{value?.taskName}</p>
                        </div>
                        <div className="col-lg-3 text-lg-center">
                            <p className="mb-0">{fetchUserName(value?.taskUser)}</p>
                        </div>
                        <div className="col-lg-3 text-lg-end">
                            <p className="mb-0">{timeSet(value?.totalTime)}</p>
                        </div>
                        {/* <div className="col-lg-2 text-lg-end">
                            <p className="mb-0 d-inline-block text-start">10:00 PM to 11:00 PM<br />01-01-2024</p>
                        </div> */}
                    </div>
                ))}



            </div>
        </div>
    )
}

export default ReportsPage
