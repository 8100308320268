import React, { useEffect, useState } from "react";
import ticketsEye from "../../assests/images/eye-blue.svg";
import SearchIcon from "../../assests/images/search.svg";
import AddIcon from "../../assests/images/add.svg";
import FilterIcon from "../../assests/images/filter-grey.svg";
import Filter from "../../assests/images/filter.svg";
import NoImage from "../../assests/images/NoImage.jpg";
import NewTickets from "./newTickets";
import { useDispatch, useSelector } from "react-redux";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from "../../service/apiService";
import {
  updateEditTicketsData,
  updateRefreshTicketsList,
} from "../../redux/chatsandtickets";

const TicketsSection = ({
  flag,
  setOpenNewTicketModal,
  setOpenEditTicketModal,
}) => {
  // const [openNewTicketModal, setOpenNewTicketModal] = useState(false)
  // const [ticketsUserList, setTicketsUserList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const projects = useSelector((state) => state.project?.showProjectList) || [];
  const ticketsUserList = useSelector((state) => state.tickets?.TicketsList) || [];
  const refreshTicketsList = useSelector(
    (state) => state.tickets?.refreshTicketsList
  );
  const dispatch = useDispatch();

  let debounceTimer;
  const debounceTimeout = 500;
  // const handleClose = () => {
  //     setOpenNewTicketModal(false)
  // }

  const fetchTicketsListData = async (id) => {
    if (id) {
      let payload = {
        project_id: id,
        // "projectId": projects?.[1]?._id
      };
      // dispatch(updateloaderStatus(true))
      try {
        const response = await apiServiceHandler(
          "POST",
          `api/tickets/get`,
          payload
        );
        console.log(response);
        // setTicketsUserList(response?.data?.tickets);
        // dispatch(updateTicketsList(response?.data) || [])
        // dispatch(updateloaderStatus(false))
      } catch (err) {
        // dispatch(updateloaderStatus(false))
        // toastr.error(err?.response?.data?.message);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (projects?.[0]?._id) {
      fetchTicketsListData(projects[0]?._id);
    }
    // fetchTaskbyId()
  }, [projects[0]?._id]);

  useEffect(() => {
    if (refreshTicketsList) {
      fetchTicketsListData(projects[0]?._id);
      dispatch(updateRefreshTicketsList(false));
    }
  }, [refreshTicketsList]);

  const handleSearch = () => {
    if (ticketsUserList) {
      const filterData = ticketsUserList.filter(
        (value) =>
          value?.ticket_subject?.includes(searchValue.toLowerCase()) ||
          value?.ticket_subject?.includes(searchValue) ||
          value.project_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          searchValue.trim() === ""
      );
      setFilteredData(filterData);
    }
  };

  const handleEditTicket = (value) => {
    setOpenEditTicketModal(true);
    dispatch(updateEditTicketsData(value));
  };

  const handleCreateNewTicket = () => {
    if (projects.length > 0) {
      setOpenNewTicketModal(true)
    } else {
      toastr.error('No Project Found');
    }
  }

  useEffect(() => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleSearch();
    }, debounceTimeout);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue, ticketsUserList]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#f13a3a'; // red
      case 'Completed':
        return 'green'; // green
      case 'Assigned':
        return '#007bff'; // blue
      default:
        return '#6c757d'; // grey
    }
  };


  return (
    <div>
      <div class="tickets-tab-search d-flex align-items-center">
        <form>
          <input
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            name="findtickets"
            placeholder="Find Tickets..."
            class="form-control"
          />
          <button type="submit" className="ticket-search-btn">
            <img src={SearchIcon} />
          </button>
        </form>
        <div className="d-flex align-items-center task-btn">
          {/* <button type="button" className="filter-icon ">
            <img src={Filter} />
          </button> */}
          {!flag && (
            <button
              type="button"
              onClick={() => handleCreateNewTicket()}
              class="add-icon-tickets"
              data-bs-target="newTicketsModal"
            >
              <img src={AddIcon} />
              New Ticket
            </button>
          )}
        </div>
      </div>
      <div class="tickets-tab">
        <div class="tickets-tab-label d-none d-lg-block mb-3">
          <div class="row g-0">
            <div class="col-lg-1">
              <h3>#</h3>
            </div>
            <div class="col-lg-2 text-lg-center">
              <h3>Subject</h3>
            </div>
            <div class="col-lg-2 text-lg-center">
              <h3>Category</h3>
            </div>
            <div class="col-lg-1 text-lg-center">
              <h3>Status</h3>
            </div>
            <div class="col-lg-2 text-lg-center">
              <h3>Project</h3>
            </div>
            <div class="col-lg-2 text-lg-center">
              <h3>Description</h3>
            </div>
            <div class="col-lg-1 text-lg-center">
              <h3>Attachment</h3>
            </div>
            <div class="col-lg-1 text-end">
              <h3>Action</h3>
            </div>
          </div>
        </div>
        <div class="tickets-tab-data">
          {filteredData &&
            filteredData?.map((value, index) => (
              <div key={index} class="row g-0 align-items-center ticket-data-row">
                <div class="col-lg-1">
                  {/* <label class="mb-0">#</label> */}
                  <p class="mb-0">{index + 1}</p>
                </div>
                <div class="col-lg-2 text-lg-center">
                  {/* <label class="mb-0">Subject</label> */}
                  <p class="mb-0">{value?.ticket_subject}</p>
                </div>
                <div class="col-lg-2 text-lg-center">
                  {/* <label class="mb-0">Category</label> */}
                  <p class="mb-0">{value?.category}</p>
                </div>
                <div class="col-lg-1 text-lg-center">
                  {/* <label class="mb-0">Status</label> */}
                  <div class="ticket-status-type" style={{ color: getStatusColor(value?.ticket_status) }}>
                    {value?.ticket_status?.replace(/\b\w/g, char => char.toUpperCase())}
                  </div>
                </div>
                <div class="col-lg-2 text-lg-center">
                  {/* <label class="mb-0">Project</label> */}
                  <p class="mb-0">{value?.project_name}</p>
                </div>
                <div class="col-lg-2 text-lg-center">
                  {/* <label class="mb-0">Description</label> */}
                  <p class="mb-0">{value?.ticket_description}</p>
                </div>
                <div class="col-lg-1 text-lg-center">
                  {/* <label class="mb-0">Attachment</label> */}
                  <p class="mb-0 attachment-ticket">
                    <img src={value?.supported_doc?.length > 0 ? value?.supported_doc : NoImage} class="project-img" />
                  </p>
                </div>
                <div class="col-lg-1 text-end">
                  {/* <label class="mb-0">Action</label> */}
                  <button
                    onClick={() => handleEditTicket(value)}
                    type="button"
                    class="no-default"
                  >
                    <img src={ticketsEye} />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <NewTickets isOpen={openNewTicketModal} onClose={handleClose} /> */}
    </div>
  );
};

export default TicketsSection;
