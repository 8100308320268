// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userData = createSlice({
  name: 'userData',
  initialState: {
    userInfo: {}
  },
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload; // Update the count using the payload
    },
   
   
    
  },
});

export const { updateUserInfo  } = userData.actions;
export default userData.reducer;
