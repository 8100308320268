import React from 'react'
import Logo from '../../assests/images/LogoSouthBisonConnect.svg'
import { useNavigate } from 'react-router'
import './home.css'


const HomePage = () => {
    const navigate = useNavigate()
    return (
        <div className="bg-image">
            <div className="bg-box">

                <div id="enterOTP" className="enter-url shadow">
                    <div className="logo text-center mb-4">
                        <img src={Logo} alt='icon' />
                    </div>
                    <p className="mb-3">Welcome to <strong>South Bison Connect</strong></p>

                    <form>
                        <div className="row">

                            <p className="mb-4">Login with Unique URL</p>
                            <button onClick={() => navigate("/signin-url")} type="button" className="signin-btn">Sign in</button>
                            <button onClick={() => navigate("/signup")} type="button" className="signin-btn">Sign up</button>



                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}

export default HomePage
