// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const reportsAnalytics = createSlice({
  name: 'reportsAnalytics',
  initialState: {
    refreshReportsList: false,
    reportsList: [],
    editData: [],
    
  },
  reducers: {
    updateRefreshReportsList: (state, action) => {
      state.refreshReportsList = action.payload; // Update the count using the payload
    },
    updateReportsList: (state, action) => {
      state.reportsList = action.payload; // Update the count using the payload
    },
    updateReportsData: (state, action) => {
      state.editData = action.payload; // Update the count using the payload
    },
  
  
    
  },
});

export const { updateRefreshReportsList, updateReportsData, updateReportsList } = reportsAnalytics.actions;
export default reportsAnalytics.reducer;
