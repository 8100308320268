import React, { useState } from 'react'
import './main.css';
import LogoIcon from '../../assests/icons/logo.png';
import hamburgerIcon from '../../assests/icons/hamburger.svg';

const HeaderPage = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        if (window.innerHeight <= 991.98) {
            setMenuOpen(!menuOpen);
        }
    };
    return (
        <div>
            <header class="page-header shadow-sm bg-white py-3">
                <div class="container">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-md-3 for-mobi">
                            <a href="/">
                                <img src={LogoIcon} />
                            </a>
                            <button class="nav-toggle d-lg-none" onClick={toggleMenu}><img src={hamburgerIcon} /></button>
                        </div>

                        <div class="col-md-4">
                            <div className={`main-menu ${window.innerHeight <= 991.98 && menuOpen ? 'open' : ''}`}
                            // style={{ display: window.innerHeight <= 991.98 ? (menuOpen ? 'block' : 'none') : 'none' }}
                            >
                                <ul class="list-unstyled m-0 d-lg-flex align-items-center justify-content-center">
                                    {/* <li><a href="/">Home</a></li> */}
                                    {/* <li><a href="/features">Features</a></li> */}
                                    {/* <li><a href="/pricing">Pricing</a></li> */}
                                    {/* <li><a href="/contact-us">Contact Us</a></li> */}
                                </ul>
                                
                                    <div class="top-btn d-lg-none">
                                        <a href="/signin-url" class="border-btn blue-border me-2">
                                            Login
                                        </a>
                                        <a href="/signup" class="filled-btn blue-filled">
                                            Sign Up
                                        </a>
                                    </div>
                            </div>
                        </div>

                        <div class="col-md-5 d-none d-lg-block">
                            <div class="top-btn text-end">
                                {/* <a href="/signin-url" class=" me-2">
                                    Contact Us
                                </a> */}
                                <a href="/signin-url" class="border-btn blue-border me-2">
                                    Login
                                </a>
                                <a href="/signup" class="filled-btn blue-filled">
                                    Sign Up
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </header>
        </div>
    )
}

export default HeaderPage
