// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tickets = createSlice({
  name: 'tickets',
  initialState: {
    refreshTicketsList: false,
    TicketsList: [],
    showTicketsList: [],
    editData: [],
    statusTicketsList: [],
    ticketsCommentList: [],
  },
  reducers: {
    updateTicketsList: (state, action) => {
      state.TicketsList = action.payload; // Update the count using the payload
    },
    updateShowTicketsList: (state, action) => {
      state.showTicketsList = action.payload; // Update the count using the payload
    },
    updateRefreshTicketsList: (state, action) => {
      state.refreshTicketsList = action.payload; // Update the count using the payload
    },
    updateEditTicketsData: (state, action) => {
      state.editData = action.payload; // Update the count using the payload
    },
    getTicketsStatusData: (state, action) => {
      state.statusTicketsList = action.payload; // Update the count using the payload
    },
    getTicketsCommentList: (state, action) => {
      state.ticketsCommentList = action.payload; // Update the count using the payload
    },
  
    
  },
});

export const { updateTicketsList, getTicketsCommentList, updateShowTicketsList, updateRefreshTicketsList, updateEditTicketsData, getTicketsStatusData } = tickets.actions;
export default tickets.reducer;
