import React, { useState } from 'react'
import Logo from '../../assests/images/LogoSouthBisonConnect.svg'
import { useNavigate, useLocation } from 'react-router'
import './payment.css'
import apiServiceHandler from '../../service/apiService'
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import PtableIcon from '../../assests/images/ptable-icon.png';

const PlansScreen = () => {
  const navigate = useNavigate()

  const { state } = useLocation();
  const { firstName, newUser, unique_url, email, paymentDetails, currentUsers, currentProject } = state;

  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const initialUserOptions = [10, 20, 30, 40, 50, 100, 250, 500, 1000];
  const initialProjectOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 50];
  const initialStorageOptions = [5, 10, 15, 20, 25, 50, 100];
  const [userQuantity, setUserQuantity] = useState(initialUserOptions[0]);
  const [projectQuantity, setProjectQuantity] = useState(initialProjectOptions[0]);
  const [storageQuantity, setStorageQuantity] = useState(initialStorageOptions[0]);

  const totalPrice = userQuantity * process.env.REACT_APP_USER_PRICE + projectQuantity * process.env.REACT_APP_PROJECT_PRICE + storageQuantity * process.env.REACT_APP_STORAGE_PRICE

  const handleUserQuantityChange = (event) => {
    setUserQuantity(event.target.value);
  };

  const handleProjectQuantityChange = (event) => {
    setProjectQuantity(event.target.value);
  };

  const handleStorageQuantityChange = (event) => {
    setStorageQuantity(event.target.value);
  };

  const handleFreePlan = async () => {
    try {
      const payload = {
        email,
        unique_url,
        userQuantity,
        projectQuantity,
        storageQuantity,
        totalPrice
      }
      await apiServiceHandler('POST', 'api/payment/paypal/update/payment-details', payload)

      navigate('/payment-success', { state: { plan: 'Free', firstName: firstName, unique_url: unique_url, userQuantity, projectQuantity, storageQuantity } })
    } catch (error) {
      console.log(error, 'Error is here in handleFreePlan')
    }
  }

  const handlePayment = async () => {
    console.log("upgrade1")
    try {
      const payload = {
        unique_url,
        totalPrice,
        userQuantity,
        projectQuantity,
        storageQuantity
      }
      const res = await apiServiceHandler('POST', 'api/payment/paypal/subscription/create/plan', payload);
      navigate('/payment', { state: { plan: 'SouthBisonConnect Subscription', price: totalPrice, planId: res.plan_id, userQuantity, projectQuantity, storageQuantity } })
    } catch (error) {
      console.log(error, 'Error is here in handlePayment')
    }
  }

  const handleUpgradeSubscription = async () => {
    console.log("upgrade")
    if (currentUsers < userQuantity && currentProject < projectQuantity) {
      setButtonDisabled(true);
      setLoading(true)
      try {
        const payload = {
          unique_url,
          totalPrice,
          userQuantity,
          projectQuantity,
          storageQuantity
        }
        // Show loading toaster
        toastr.info('Creating Paypal plan...', {
          closeButton: false,
          timeOut: 0,
          extendedTimeOut: 0,
        });
        const res = await apiServiceHandler('POST', 'api/payment/paypal/subscription/create/plan', payload);

        const upgradePayload = {
          subscriptionID: paymentDetails?.payment_details.id,
          planId: res.plan_id
        }

        const payment = await apiServiceHandler('POST', 'api/payment/paypal/subscription/upgrade', upgradePayload)
        toastr.success('Suceess, Redirecting to Paypal...')
        window.location.replace(payment?.data?.links[0].href)
      } catch (error) {
        setButtonDisabled(false);
        setLoading(false);
        toastr.error(error.message);
        console.error(error, 'Error is here in handleUpgradeSubscription')
      }
    } else {
      setButtonDisabled(false);
      setLoading(false);
      toastr.error("Downgrading the plan is not allowed since the current user, project or storage count exceeds the specified upgrade quantity.")
      // alert('Downgrading the plan is not allowed since the current user, project or storage count exceeds the specified upgrade quantity.')
    }
  }

  return (
    <div className="bg-image">
      <div className="bg-box">
        <div className="logo text-center mb-4">
          <img src={Logo} />
        </div>
        <div className="choose-plan">
          <div className="container">
            <h1>Hello {firstName}, welcome to SouthBisonConnect.</h1>
            <h2>Please select a plan to continue</h2>
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="mb-md-0">
                {/* <div className="choose-plan-box shadow bg-white">
                  <div className="p-3 plan-heading">
                    <h3 className="mb-1">Monthly Plan</h3>
                    <p className="mb-0">
                      Quis autem vel eum iure reprehenderit qui in ea voluptate
                      consequatu
                    </p>
                  </div>
                  <div className="plan-price">Monthly Plan - ${totalPrice}</div>
                  <div className="plan-content p-3">
                    <div className='plan-div'>
                      <h4 className="mb-2">Customize your plan to fit your needs</h4>

                      <div className='plan-content-div'>
                        <select className='user-div' id="quantity" value={userQuantity} onChange={handleUserQuantityChange}>
                          {initialUserOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <label className='content-text' htmlFor="quantity">User</label>
                      </div>
                      <div className='plan-content-div'>
                        <select className='user-div' id="project" value={projectQuantity} onChange={handleProjectQuantityChange}>
                          {initialProjectOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <label className='content-text' htmlFor="project">Project</label>
                      </div>
                      <div className='plan-content-div'>
                        <select className='user-div' id="storage" value={storageQuantity} onChange={handleStorageQuantityChange}>
                          {initialStorageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <label className='content-text' htmlFor="storage">Storage</label>
                      </div>
                      <div className='learn-more'>Learn more...</div>
                    </div>
                    <div className="text-center mt-5">
                      {totalPrice == 25 && newUser ? <button type="button" className="plan-btn" onClick={handleFreePlan}>
                        Try for Free*
                      </button> : (paymentDetails?.payment_details.id ? <button type="button" className={`plan-btn ${loading ? 'loading' : ''}`} disabled={isButtonDisabled} onClick={handleUpgradeSubscription}>
                        Start for {totalPrice}$
                      </button> : <button type="button" className="plan-btn" onClick={handlePayment}>
                        Start for {totalPrice}$
                      </button>)}
                    </div>
                  </div>
                </div> */}
                <div className="container">
                  {/* <div className="subscription-head">
                        <h2>Subscription Plans</h2>
                        <p>Pay for your needs.</p>
                    </div> */}
                  <div className="price-box-css">
                    <img src={PtableIcon} width="100" />
                    {/* <h3>Customized<br />Plan</h3> */}
                    <h3>Monthly Plan</h3>
                    <h4>$ {totalPrice}</h4>
                    {/* <sup>$</sup> */}
                    <div className="customizee-opt">
                      <ul className="list-unstyled m-0 p-0">
                        <li>
                          <div className="customizee-label">Users</div>
                          <div className="customizee-num">
                            <select name="users" value={userQuantity} onChange={handleUserQuantityChange}>
                              {/* <option value="1">1</option> */}
                              {initialUserOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </li>
                        <li>
                          <div className="customizee-label">Projects</div>
                          <div className="customizee-num">
                            <select name="projects" value={projectQuantity} onChange={handleProjectQuantityChange}>
                              {/* <option value="1">1</option> */}
                              {initialProjectOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </li>
                        <li>
                          <div className="customizee-label">Storage</div>
                          <div className="customizee-num">
                            <select name="storage" value={storageQuantity} onChange={handleStorageQuantityChange}>
                              {/* <option value="1 GB">10 GB</option> */}
                              {initialStorageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="text-center mt-2">
                      {totalPrice == 25 && newUser ? <button type="button" className="sff-btn" onClick={handleFreePlan}>
                        Try for Free*
                      </button> : (paymentDetails?.payment_details?.id ? <button type="button" className={`sff-btn ${loading ? 'loading' : ''}`} disabled={isButtonDisabled} onClick={handleUpgradeSubscription}>
                        Start for {totalPrice}$
                      </button> : <button type="button" className="sff-btn" onClick={handlePayment}>
                        Start for {totalPrice}$
                      </button>)}
                    </div>
                    {/* <button className="sff-btn" onClick={() => navigate("/signup")}>Start for free</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlansScreen
