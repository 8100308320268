import React, { useEffect, useRef, useState } from 'react'
import DotIcon from '../../assests/images/vertical-dots.svg';
import { useDispatch } from 'react-redux';
import { updateEditData } from '../../redux/project';


const NavbarActions = (props) => {
    const dispatch = useDispatch()
    const { projectList, setOpenAddProjectModal, setOpenEditProjectModal, setOpenDeleteProjectModal } = props
    const [openItem, setOpenItem] = useState(false);
    const modalRef = useRef(null);
    // const history = useHistory();

    const handleOutsideClick = (event) => {
        if (modalRef.current && !(modalRef.current).contains(event.target)) {
            setOpenItem(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    const handleToggle = () => {
        setOpenItem(!openItem);
    }
    const handleEditClick = () => {
        setOpenEditProjectModal(true)
        dispatch(updateEditData(projectList))
    }

    const handleDeleteClick = () => {
        setOpenDeleteProjectModal(true)
        dispatch(updateEditData(projectList))
    }

    return (
        <div className="">
            <button type="button" className="vertical-dots" onClick={handleToggle}><img src={DotIcon} /></button>
            <div className="menu-action" ref={modalRef} style={{ display: openItem ? 'block' : 'none' }}>
                {/*
                <button type="button"  data-bs-target="newProjectModal" onClick={() => setOpenAddProjectModal(true)}>Open</button>
                */}
                <button type="button" data-bs-target="editProjectModal" onClick={handleEditClick}>Edit</button>
                <button type="button" style={{ color: '#F13A3A' }} data-bs-target="deleteProjectModal" onClick={handleDeleteClick}>Delete</button>
            </div>
        </div>
    )
}

export default NavbarActions