import React, { useEffect, useState } from 'react'
import './taskAssign.css';
import PlusIcon from '../../assests/icons/plusg.svg';
import PencilIcon from '../../assests/images/pencil-white.svg';
import EditIcon from '../../assests/images/edit.svg';
import TickIcon from '../../assests/images/tick-white.svg';
import PencilFrame from '../../assests/images/pencil-frame.svg';
import UndoTick from '../../assests/images/redo1.svg';
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditTaskData, updateRefreshTaskList } from '../../redux/task';
import { updatetaskAssignedPopup, updateupcomingHeadline } from '../../redux/viewmore';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from '../../service/apiService';

const TaskAssginment = ({ setTasksListProps, flag }) => {
    const [taskAssignedtoMe, setTaskAssignedtoMe] = useState([])
    const myTaskList = useSelector(state => state.task.TasksList?.assignedToMe)
    const projects = useSelector(state => state.project?.showProjectList) || []
    const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
    const [openEditTaskModal, setOpenEditTaskModal] = useState(false);
    const usersList = useSelector(state => state.allUserData.users)
    const upcomingPopup = useSelector(state => state.viewmore)
    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    const dispatch = useDispatch()
    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);

        // Get day, month, and year components
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        // Format the date as "dd month name, year"
        return `${day} ${month} ${year}`;
    };
    const calculateDaysRemaining = (date) => {
        const currentDate = new Date();
        const examDate = new Date(date); // Replace with your desired exam date
        const timeDifference = Math.abs(examDate - currentDate);
        const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysRemaining
    };

    const handleNewTaskButton = () => {

        if (projects.length > 0) {
            setOpenNewTaskModal(true)
        } else {
            toastr.error("No Project Found")
        }

    }

    useEffect(() => {
        if (openNewTaskModal) {
            setTasksListProps({
                ModalName: 'AddNewTask',
                isOpen: openNewTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } else if (openEditTaskModal) {
            setTasksListProps({
                ModalName: 'EditTask',
                isOpen: openEditTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0],
                waitingForApproval: false
            })
        } else {
            setTasksListProps(null)
        }
    }, [openNewTaskModal, openEditTaskModal, projects[0]])

    const handleEditTask = (value, e) => {
        e.stopPropagation();
        setOpenEditTaskModal(true)
        dispatch(updateEditTaskData(value))
    }

    const [isUpdating, setIsUpdating] = useState(false);

    const handleUpdateTask = async (e, value, status) => {
        e.stopPropagation();
        e.preventDefault();

        if (!isUpdating) {
            setIsUpdating(true);
            setTaskAssignedtoMe(prevData => {
                return prevData.map(task => {
                    if (task._id === value._id) {
                        return {
                            ...task,
                            status: status,
                        };
                    }
                    return task;
                });
            });

            let payload = new FormData();
            payload.append('id', value._id);
            payload.append('status', status);

            try {
                await apiServiceHandler("POST", "api/task/update", payload);
                dispatch(updateRefreshTaskList(true));
            } catch (error) {
                toastr.error('Error in updating Task Status');
                console.error("Error updating task:", error);
                setTaskAssignedtoMe(prevData => {
                    return prevData.map(task => {
                        if (task._id === value._id) {
                            return {
                                ...task,
                                status: value.status,
                            };
                        }
                        return task;
                    });
                });
            }

            setTimeout(() => {
                setIsUpdating(false);
            }, 3000);
        }
    };

    const handleClose = () => {
        setOpenNewTaskModal(false)
        setOpenEditTaskModal(false)
    }

    useEffect(() => {
        if (myTaskList) {
            // const newTaksList = myTaskList.filter((val) => val.status != 'Approved' && val.status != 'Rejected' && val.task_status?.[0] != "appointment")
            const newTaksList = myTaskList.filter((val) => val.task_status?.[0] != "appointment")
            setTaskAssignedtoMe(newTaksList)
        }
    }, [myTaskList])

    const isDueDatePassed = (dueDate) => {
        if (!dueDate) {
            return false; // No due date, so not passed
        }
        const currentDate = new Date();
        const dueDateObj = new Date(dueDate);
        return dueDateObj < currentDate;
    }
    // function isCompletedAfterDueDate(dueDate, completionDate) {
    //     if (!dueDate) {
    //         return false; // No due date, so not passed
    //     }
    //     if (!completionDate) {
    //         const currentDate = new Date();
    //         const dueDateObj = new Date(dueDate);
    //         return dueDateObj < currentDate;
    //     }

    //     const due = new Date(dueDate);
    //     const completion = new Date(completionDate);
    //     return completion > due;
    // }

    const isCompletedAfterDueDate = (dueDate, completionDate) => {
        if (!dueDate) {
            return false; // No due date, so not passed
        }

        const currentDate = new Date();
        const due = new Date(dueDate);

        // Check if current date is greater than due date and completion date is not available
        if (currentDate > due && !completionDate) {
            return true; // Task is late
        }

        if (!completionDate) {
            // return false; // Completion date not available, task is not 
            const currentDate = new Date();
            const dueDateObj = new Date(dueDate);
            return dueDateObj < currentDate;
        }

        const completion = new Date(completionDate);
        return completion > due;
    }

    const calculateDaysPassed = (dueDate) => {
        const currentDate = new Date();
        const dueDateObj = new Date(dueDate);
        const timeDifference = currentDate.getTime() - dueDateObj.getTime();
        const daysPassed = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysPassed;
    }

    const calculateRemainingDays = (completionDate, dueDate) => {
        if (!completionDate) {
            // If completion date is not available, return remaining days for due date
            const currentDate = new Date();
            const dueDateObj = new Date(dueDate);
            const differenceInTime = dueDateObj.getTime() - currentDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);

            if (differenceInDays < 0) {
                return `${Math.round(Math.abs(differenceInDays))} days past due`;
            } else {
                return `${Math.round(differenceInDays)} days remaining`;
            }
        }

        const dueDateObj = new Date(dueDate);
        const completionDateObj = new Date(completionDate);
        const differenceInTime = completionDateObj.getTime() - dueDateObj.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        if (differenceInDays < 0) {
            return `${Math.round(Math.abs(differenceInDays))} days before due date`;
        } else if (differenceInDays > 0) {
            return `${Math.round(differenceInDays)} days late`;
        } else if (differenceInDays === 0) {
            return 'Completed on time';
        } else {
            return 'Completion date missing';
        }
    };


    const createdBy = (userId) => {
        const user = usersList.find((user) => user._id === userId);
        return user ? `${user.first_name} ${user.last_name} ` : 'Unknown';
    }

    return (
        <div className={window.screen.width < 1280 ? 'assigned-tasks-mobile' : ""}>
            <div className={flag ? "assigned-tasks-viewmore" : "assigned-tasks"}>
                <div className={flag ? "task-heading d-flex align-items-center justify-content-between" : "task-heading d-flex align-items-center justify-content-between viewmore-line"}>
                    {!flag && <h2 className="mb-0">My Tasks ({userData.first_name})</h2>}
                    <div class="d-flex align-items-center">
                        {!upcomingPopup.taskAssignedPopup && <> <button type="button" className="add-icon" onClick={() => handleNewTaskButton()} ><img src={PlusIcon} /></button>
                            {window.screen.width > 760 && <button type="button" onClick={() => {
                                dispatch(updatetaskAssignedPopup(true));
                                dispatch(updateupcomingHeadline(`My Tasks (${userData.first_name})`))
                            }} className="add-icon"><img src={ExpandIcon} /></button>}
                            <button type="button" className="drag-me"><img src={DragIcon} /></button> </>}
                    </div>
                </div>
                <div className="tasks-col">
                    <div className="tasks-label d-none d-lg-block">
                        <div className="row g-0">
                            <div class="col-lg-1">
                                <h3 class="hash-no">#</h3>
                            </div>
                            <div className="col-lg-2 text-lg-center">
                                <h3>Task Name</h3>
                            </div>
                            <div className="col-lg-1 text-lg-center">
                                <h3>Project</h3>
                            </div>
                            <div className="col-lg-2 text-lg-center">
                                <h3>Created By</h3>
                            </div>
                            <div className="col-lg-1 text-lg-center">
                                <h3>Due Date</h3>
                            </div>
                            <div class="col-lg-2 text-lg-center">
                                <h3>Remaining Days</h3>
                            </div>
                            <div class="col-lg-2 text-lg-center">
                                <h3>Status</h3>
                            </div>
                            <div className="col-lg-1 text-lg-center">
                                <h3>Action</h3>
                            </div>
                        </div>
                    </div>
                    <div className={flag ? "tasks-data-viewmore" : "tasks-data"}>
                        {taskAssignedtoMe && taskAssignedtoMe.sort((a, b) => {
                            if (a.status === "Pending" && b.status !== "Pending") return -1;
                            if (b.status === "Pending" && a.status !== "Pending") return 1;
                            if (a.status === "Pending" && b.status === "Pending") {
                                return new Date(a.due_date) - new Date(b.due_date);
                            }
                            if (a.status === "Completed" && b.status !== "Completed") return 1; // "Completed" tasks last
                            if (b.status === "Completed" && a.status !== "Completed") return -1;
                            if (a.status === "Completed" && b.status === "Completed") {
                                // If both tasks are "Completed", sort by completion date or due date if completion date is not available
                                const aDate = a.completion_date ? new Date(a.completion_date) : new Date(a.due_date);
                                const bDate = b.completion_date ? new Date(b.completion_date) : new Date(b.due_date);
                                return aDate - bDate;
                            }
                            return 0;
                        })
                            .map((val, index) => (<div style={{ cursor: "pointer" }} onClick={(e) => handleEditTask(val, e)} className={`row g-0 task-view ${isCompletedAfterDueDate(val?.due_date, val?.completion_date) ? 'task-pending' : ''}`} >
                                <div class="col-lg-1">
                                    <label class="mb-0 hash-no">#</label>
                                    <p class="mb-0 hash-no">{index + 1}</p>
                                </div>
                                <div data-placement="top" title={val?.task_name} className="col-lg-2 text-lg-center">
                                    <label className="mb-0" >Task Name</label>
                                    <p className="mb-0">{val?.task_name}</p>
                                </div>
                                <div data-placement="top" title={val?.project_name} className="col-lg-1 text-lg-center">
                                    <label className="mb-0">Project</label>
                                    <p className="mb-0 limit-name">{val?.project_name}</p>
                                </div>
                                <div className="col-lg-2 text-lg-center">
                                    <label className="mb-0">Category</label>
                                    <p className="mb-0">{val?.category != "clickup" ? createdBy(val?.created_by) : val?.creator}</p>
                                </div>
                                <div className={`col-lg-1 text-lg-center ${isDueDatePassed(val?.due_date) ? 'task-due' : ''}`} >
                                    <label className="mb-0">Due Date</label>
                                    <p className="mb-0">{val?.due_date ? (getFormattedDate(val?.due_date)) : ''}</p>
                                </div>
                                <div class="col-lg-2 text-lg-center">
                                    <label class="mb-0">Remaining Days</label>
                                    <p class="mb-0">{calculateRemainingDays(val?.completion_date, val?.due_date)}</p>
                                </div>
                                <div class="col-lg-2 text-lg-center">
                                    <label class="mb-0">Status</label>
                                    <p class="mb-0">{val?.status?.replace(/\b\w/g, char => char.toUpperCase())}</p>
                                </div>
                                <div className="col-lg-1 text-lg-center no-color task-data" >
                                    <label className="mb-0">Action</label>
                                    {val?.status == "Pending" ? <button className="mark-complete" onClick={(e) => handleUpdateTask(e, val, 'Completed')} disabled={isUpdating}><img src={TickIcon} /></button> : <button className="mark-complete-undo" onClick={(e) => handleUpdateTask(e, val, 'Pending')} disabled={isUpdating}><img src={UndoTick} /></button>}&nbsp;
                                    <button type="button" className="mark-complete-pencil" data-bs-target="editTaskModal" onClick={(e) => handleEditTask(val, e)}><img src={PencilIcon} /></button>
                                </div>
                            </div>))}
                        {/*  */}
                    </div>
                </div>
            </div>
            {window.screen.width > 760 && !flag && <button type="button" onClick={() => {
                dispatch(updatetaskAssignedPopup(true));
                dispatch(updateupcomingHeadline(`My Tasks (${userData.first_name})`))
            }} className="view-more">View More</button>}


        </div>
    )
}

export default TaskAssginment;
