import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import { rtdb } from '../firebase/firebase'
import { onValue, child, get, ref, set, getDatabase } from "firebase/database"
import { useSelector } from 'react-redux';

const NotificationModal = ({ isOpen, onClose }) => {
    const myNotifications = useSelector(state => state.notification.notification)
    const myNotificationsCount = useSelector(state => state.notification.unreadNotificationCount)
    const [openNotificationModal, setOpenNotificationModal] = useState(false)
    const formatDate = (timestampInMilliseconds) => {
        const date = new Date(timestampInMilliseconds);
        const formatter = new Intl.DateTimeFormat('en-US', {
           year: 'numeric',
           month: 'short',
           day: '2-digit',
        });
        return formatter.format(date);
     }
    const sendNotification = async (notification, receiverUniqueUrl, timestampString, notificationRead, from, projectName) => {
        if (notification === "") {
           alert("Enter a valid notification");
           return;
        }
        if (notificationRead == undefined) notificationRead = false
        const timestampDate = new Date();
        var timestampString = timestampString || timestampDate.getTime();
  
        let databasePath = receiverUniqueUrl + "_notification";
  
        const parentRef = ref(rtdb, databasePath);
  
        // Use the `get` method inside a `then` block to ensure it completes before further processing
        get(parentRef)
           .then((snapshot) => {
              if (snapshot.exists()) {
                 // Data exists, so create a new child object
                 const childObjectRef = child(parentRef, `notification_${timestampString}`);
                 return set(childObjectRef, {
                    notification: notification,
                    createdAt: timestampString,
                    uniqueUrl: receiverUniqueUrl,
                    notificationRead: notificationRead,
                    projectName: projectName || "",
                    from: from || ""
                 });
              } else {
                 // Data doesn't exist, so set the parent object and create a child object
                 return set(parentRef, {}).then(() => {
                    const childObjectRef = child(parentRef, `notification_${timestampString}`);
                    return set(childObjectRef, {
                       notification: notification,
                       createdAt: timestampString,
                       uniqueUrl: receiverUniqueUrl,
                       notificationRead: notificationRead,
                       projectName: projectName || "",
                       from: from || ""
                    });
                 });
              }
           })
           .then(() => {
              console.log("Notification sent successfully");
           })
           .catch((error) => {
              console.error(error);
           });
     };
  
     const userMarkNotificationRead = async () => {
        let userData = localStorage.getItem("userData")
        userData = userData ? JSON.parse(userData) : {}
        let uniqueUrl = userData?.unique_url
  
  
        let databasePath = userData?.uniqueUrl + "_" + "notification"
  
        const dbRef = ref(getDatabase());
  
        get(child(dbRef, databasePath)).then((snapshot) => {
           if (snapshot.exists()) {
              let data = snapshot.val()
  
              if (data) {
                 Object.keys(data).forEach(async (x) => {
  
                    console.log(data[x])
  
                    if (data[x]["notificationRead"] != undefined && data[x]["notificationRead"] == false) {
                       await sendNotification(data[x]["notification"], data[x]["uniqueUrl"], data[x]["createdAt"], true,data[x]["from"],data[x]["projectName"])
                    }
  
                 });
              } else {
                 // Handle the case when data is null or undefined
                 // Invoke the callback with an empty array
                 console.log("")
              }
           } else {
              console.log("No data available");
           }
        }).catch((error) => {
           console.error(error);
        });
  
  
     }
  
    //  const handleNotificationClick = () => {
    //     // setShowNotifications(!showNotifications)
    //     userMarkNotificationRead()
    //  }
    useEffect(() => {
        userMarkNotificationRead()
    })
    return (
        <div
            className={`modal fade notification-modal ${isOpen ? "show backdrop" : " "}`}
            id="notificationModal"
            tabindex="-1"
            aria-labelledby="notificationModalLabel"
            aria-hidden="true"
            style={{ display: isOpen ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title">Notifications</h2>
                        <button onClick={onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src={CloseIcon} />
                        </button>

                    </div>
                    <div className="modal-body">
                        <div className="current-notification">
                            <div className="noti-col d-none d-lg-block mb-3">
                                <div className="row g-0">
                                    <div className="col-lg-3">
                                        <h3>Details</h3>
                                    </div>
                                    <div className="col-lg-3 text-lg-center">
                                        <h3>Project</h3>
                                    </div>
                                    <div className="col-lg-3 text-lg-center">
                                        <h3>From</h3>
                                    </div>
                                    <div className="col-lg-3 text-lg-center">
                                        <h3>Other Details</h3>
                                    </div>

                                </div>
                            </div>
                            <div className="current-notification-details">
                            {myNotifications && myNotifications.map((val) => (  <div className="row g-0">
                                    <div className="col-lg-3">
                                        {/* <label className="mb-0">Details</label> */}
                                        <p className="mb-0">{val?.notification}</p>
                                    </div>
                                    <div className="col-lg-3 text-lg-center">
                                        {/* <label className="mb-0">Project</label> */}
                                        <p className="mb-0">{val?.projectName || "-"}</p>
                                    </div>
                                    <div className="col-lg-3 text-lg-center">
                                        {/* <label className="mb-0">From</label> */}
                                        <div className="task-progress-label-two mb-0">{val?.from || "-"}</div>
                                    </div>
                                    <div className="col-lg-3 text-lg-center">
                                        {/* <label className="mb-0">Project</label> */}
                                        <p className="mb-0">{formatDate(val?.createdAt)}</p>
                                    </div>


                                </div>))}
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
        
  )
}

export default NotificationModal
