import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import AddIcon from "../../assests/images/add-icon.svg";
import Trash from "../../assests/images/trash.svg";
import Select, { useStateManager } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import apiServiceHandler from "../../service/apiService";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshProjectList } from "../../redux/project";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updateProjectsCount } from "../../redux/userlogs";

const AddnewProject = ({ isOpen, onClose, setShowNavbar, clickUpProjectListUpdate }) => {
  const user = JSON.parse(localStorage.getItem('userData'));
  const usersList = useSelector((state) => state.allUserData.users);
  const [handBasicData, setHandBasicData] = useState({});
  const [imageLogo, setImageLogo] = useState(null);
  const dispatch = useDispatch();
  const clickupSpaceId = localStorage.getItem('JLL_SpaceId');
  const [milestones, setMilestones] = useState([
    {
      milestone_name: "",
      milestone_end_date: "",
      milestone_description: "",
      milestone_status: false,
      milestone_weightage: 0,
      milestone_scope: false,
      status: "",
      selected_milestone: ""
    },
  ]);

  useEffect(() => {
    toastr.options.preventDuplicates = true;
  }, [])

  const [disableButton, setDisableButton] = useState(false)

  const checkMilestoneWeightage = (total_weightage, updatedValue) => {
    if (total_weightage > 100) {
      toastr.error("Total Weightage Cannot be more than 100")
      setDisableButton(true)
    } else {
      setDisableButton(false)
      return updatedValue
    }
  }

  const handleMilestoneChange = (index, name, value) => {
    setMilestones((prevMilestones) => {
      const updatedMilestones = [...prevMilestones];
      const updatedValue = value.target ? value.target.value : value;

      let total_weightage = 0;
      updatedMilestones.map((milestone, idx) => {
        total_weightage = Number(total_weightage) + (index == idx ? Number(updatedValue) : Number(milestone.milestone_weightage));
      })

      updatedMilestones[index] = {
        ...updatedMilestones[index],
        [name]: name == "milestone_scope" ? value.target.checked : name == "milestone_weightage" ? checkMilestoneWeightage(total_weightage, updatedValue) : updatedValue,
        // milestone_weightage: checkMilestoneWeightage(total_weightage, updatedValue)
      };
      return updatedMilestones;
    });
  };

  const addMilestone = () => {
    setMilestones([
      ...milestones,
      {
        milestone_name: "",
        milestone_end_date: "",
        milestone_description: "",
        milestone_status: false,
        milestone_weightage: 0,
        milestone_scope: false,
        status: "",
        selected_milestone: ""
      },
    ]);
  };

  const deleteMilestone = (index) => {
    const updatedMilestones = [...milestones];
    updatedMilestones.splice(index, 1);
    setMilestones(updatedMilestones);
  };

  const handleInputValue = (event) => {
    const { name, value } = event.target;
    setHandBasicData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const transformData = (data) => {
    return data.map((item) => ({
      ...item,
      value: item._id,
      label: `${item.first_name} ${item.last_name} (${item.role})`,
    }));
  };
  const options = transformData(usersList) || [];

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const separateByRole = (data) => {
    const clientArray = [];
    const otherArray = [];

    data.forEach((item) => {
      if (item.role === "client") {
        clientArray.push(item._id);
        otherArray.push(item._id);
      } else {
        otherArray.push(item._id);
      }
    });

    return {
      client_id: clientArray,
      project_user: otherArray,
    };
  };

  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  async function isMilestoneWeightageZero(milestones) {
    for (let milestone of milestones) {
      if (milestone.milestone_weightage == 0) {
        return false;
      }
    }
    return true;
  }

  const addProject = async (e) => {
    e.preventDefault();
    setDisableButton(true)
    const separatedData = separateByRole(selectedOptions);
    let payload = new FormData();
    separatedData?.client_id &&
      separatedData?.client_id.map((val) => {
        payload.append("client_id[]", val);
      });
    separatedData?.project_user &&
      separatedData?.project_user.map((val) => {
        payload.append("project_user[]", val);
      });
    payload.append("project_name", handBasicData?.project_name);
    payload.append("start_date", handBasicData?.start_date);
    payload.append("end_date", handBasicData?.end_date);
    payload.append("project_url", "");
    payload.append("project_description", "");
    payload.append("milestone", JSON.stringify(milestones));
    payload.append("file", selectedImage);
    payload.append("company_id", user.parent_company_id ? user.parent_company_id : user.company_id)
    payload.append("unique_url", user.unique_url)
    payload.append("space_id", clickupSpaceId)
    // payload.append('fcasjcas', fs.createReadStream('/Users/shriomtyagi/Documents/Wallet-pana 1.png'));

    const isZero = await isMilestoneWeightageZero(milestones)
    if (isZero) {
      try {
        const response = await apiServiceHandler("POST", "api/project", payload);
        const projectCount = await apiServiceHandler(
          "POST",
          "api/auth/user/project/count",
          { company_id: user?.parent_company_id ? user.parent_company_id : user.company_id }
        );
        dispatch(updateProjectsCount(projectCount.data));
        toastr.success(response?.message);
        dispatch(updateRefreshProjectList(true));
        setHandBasicData({})
        setMilestones([{
          milestone_name: "",
          milestone_end_date: "",
          milestone_description: "",
          milestone_status: false,
          milestone_weightage: 0,
          milestone_scope: false,
          status: "",
          selected_milestone: ""
        },])
        setSelectedImage(null);
        setSelectedOptions([])
        setDisableButton(false)
        onClose();
        clickUpProjectListUpdate()
      } catch (err) {
        toastr.error(err?.response?.data?.message);
        console.log(err);
      }
    } else {
      toastr.error("Add Milestone Weightage");
    }
  };

  // useEffect(() => {
  //     console.log(selectedImage);

  // }, [third])

  return (
    <div
      className={`modal fade create-new-project ${isOpen ? "show backdrop" : " "
        }`}
      //  className="modal fade create-new-project"
      id="newProjectModal"
      tabindex={-1}
      aria-labelledby="newProjectModalLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0">
          <div className="modal-header">
            <h2 className="modal-title">Create a New Project</h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <img src={CloseIcon} />
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={addProject}>
              <div className="row">
                <div className="col-md-12 mb-2 write-project-name">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add Project Name*"
                    name="project_name"
                    value={handBasicData?.project_name || ""}
                    onChange={handleInputValue}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label className="add-project-label">
                    Start Date*
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Starting date"
                    name="start_date"
                    value={handBasicData?.start_date || ""}
                    onChange={handleInputValue}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label className="add-project-label">End Date<span style={{ fontSize: '10px', padding: '0px', margin: '0px' }}> (optional)</span></label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="End Date"
                    name="end_date"
                    value={handBasicData?.end_date || ""}
                    onChange={handleInputValue}
                  />
                </div>
                <div className="col-md-12 mb-2">
                  <div className="milestone-data">
                    <div className="add-milestone">
                      <label className="add-project-label" style={{ fontSize: '18px' }}>
                        Enter Milestone for Progress Tracking
                      </label>
                    </div>
                    {milestones?.map((milestone, index) => (
                      <div className="milestone-loop" key={index}>
                        <div className="milestone-head mb-2">
                          <input
                            type="text"
                            className="form-control milestone-input"
                            placeholder={`Milestone ${index + 1}*`}
                            name="milestone_name"
                            value={milestone.milestone_name}
                            onChange={(e) =>
                              handleMilestoneChange(index, e.target.name, e)
                            }
                            required
                          />
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => deleteMilestone(index)}
                          >
                            <img src={Trash} />
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-2">
                            <div className="col-md-12 mb-2">
                              <label>Enter Ending Date*</label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Ending date"
                                name="milestone_end_date"
                                value={milestone.milestone_end_date}
                                onChange={(e) =>
                                  handleMilestoneChange(index, e.target.name, e)
                                }
                              />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="col-md-7 mb-2">
                                <label style={{ padding: '0px', margin: '0px' }}>Milestone Weightage*</label>
                                <span style={{ fontSize: '10px', padding: '0px', margin: '0px' }}>(Number between 1-100)</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="milestone weightage"
                                  name="milestone_weightage"
                                  required
                                  min={1}
                                  max={100}
                                  style={{ WebkitAppearance: "none", MozAppearance: "textfield", appearance: "textfield" }}
                                  title="Weightage must be between 1-100"
                                  // value={milestone.milestone_weightage}
                                  onChange={(e) => {
                                    handleMilestoneChange(
                                      index,
                                      e.target.name,
                                      e
                                    );
                                  }}
                                />
                              </div>
                              <div
                                className="col-md-6 mb-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="milestone_scope"
                                  name="milestone_scope"
                                  value={milestone.milestone_scope}
                                  defaultChecked={milestone.milestone_scope}
                                  onChange={(e) => {
                                    handleMilestoneChange(
                                      index,
                                      e.target.name,
                                      e
                                    );
                                  }}
                                />
                                <label
                                  htmlFor="milestone_scope"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Out of Scope
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <label>Add Description</label>
                            <ReactQuill
                              value={milestone.milestone_description}
                              onChange={(value) =>
                                handleMilestoneChange(
                                  index,
                                  "milestone_description",
                                  value
                                )
                              }
                              placeholder="Add Description"
                            />
                          </div>
                          <hr />
                        </div>
                      </div>
                    ))}
                    <div className="add-more-mile">
                      <button type="button" onClick={addMilestone}>
                        <img src={AddIcon} /> Add Another Milestone
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <label className="add-project-label">Add Project Users*</label>
                  <Select
                    options={options}
                    isMulti
                    onChange={handleChange}
                    value={selectedOptions}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label className="add-project-label">Upload Logo</label>
                  {selectedImage &&
                    <div className="attachment-project">
                      <div className="attachment-project-image">
                        <img src={URL.createObjectURL(selectedImage)} />
                      </div>
                    </div>}
                  <label htmlFor="projectFile" style={{ cursor: 'pointer' }} className="upload-file-project text-center">
                    <div>
                      <input
                        onChange={handleImageChange}
                        className="box-file-hidded"
                        type="file"
                        id="projectFile"
                      // accept="image/*" // Specify that only image files are allowed
                      />
                      <div className="upload-txt-project">
                        Drag file here or{" "}<br />
                        <span style={{ fontSize: '16px', fontFamily: 500 }}>
                          upload from your computer
                        </span>
                      </div>
                      <p className="mb-0">JPG, PNG, PDF up to 5MB</p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="modal-footer justify-content-center mt-2">
                <button type="submit" className="bg-btn" disabled={disableButton} style={{ background: disableButton ? '#BECDFF' : '#57A5FB' }}>
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddnewProject;
