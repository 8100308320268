import React, { useEffect } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateDeleteRolePopup, updateRefreshUserDatap } from '../../redux/userManagement';


const DeleteRole = ({ isOpen, onClose, setRefreshRoleList }) => {
   const dispatch = useDispatch()
   const user = JSON.parse(localStorage.getItem('userData'));
   const deleteData = useSelector(state => state.allUserData)
   const rolesData = useSelector(state => state.allUserData?.accessRoleList)
   let userRole = deleteData?.deleteRoleData

   const handleDelete = async () => {
      const payload = {
         company_id: user.company_id ? user.company_id : user.parent_compnay_id,
         "role": userRole?.role
      }
      try {
         const response = await apiServiceHandler("DELETE", "api/auth/delete_role", payload)
         toastr.success(response?.message)
         dispatch(updateRefreshUserDatap(true))
         handleClose()
      } catch (err) {
         console.log("error", err)
      }
   }

   //   useEffect(() => {
   //    // if(rolesData){
   //    //    // setRefreshRoleList(false)
   //    // }

   //   },[refreshRoleList])

   const handleClose = () => {
      onClose()
      setRefreshRoleList(true)
      dispatch(updateDeleteRolePopup(false))
   }

   return (
      <div className={`modal fade delete-user-role edit-task-viewmore ${isOpen ? "show backdrop" : " "}`}
         id="deleteRoleModal" tabindex="-1" aria-labelledby="deleteRoleModalLabel" aria-hidden="true"
         style={{ display: isOpen ? "block" : "none" }}
      >
         <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content border-0">
               <div className="modal-header">
                  <h2 className="modal-title">Delete Role</h2>
                  <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                     <img src={CloseIcon} />
                  </button>
               </div>
               <div className="modal-body text-center">
                  <h3 className="mb-0">Are you sure you want to delete <span>{userRole?.role}</span> role?</h3>
               </div>
               <div className="modal-footer justify-content-center">
                  <button type="button" data-bs-dismiss="modal" className="border-btn" onClick={handleClose}>Cancel</button>
                  <button type="submit" className="bg-btn" onClick={handleDelete}>Yes, Delete</button>
               </div>
            </div>
         </div>
      </div>
   )
}

export default DeleteRole
