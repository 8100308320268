import React, { useEffect, useLayoutEffect, useState } from "react";
import "./projectbar.css";
import ExpandIcon from "../../assests/images/expand.svg";
import DragIcon from "../../assests/images/drag.svg";
import SearchIcon from "../../assests/images/search-black.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProjectProgressPopup,
  updateprogressPopup,
  updateupcomingHeadline,
} from "../../redux/viewmore";
import apiServiceHandler from "../../service/apiService";
import { updateLastEditedProjectList, updateLastSelectedProjectId, updateRefreshProjectList } from "../../redux/project";
import Parser from "html-react-parser";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const ProjectProgress = ({ flag }) => {
  // const projectsData = useSelector((state) => state.project.projectsData[0]);
  const projectsData = useSelector((state) => state.project.showProjectList) || [];
  const lastestModifiedProject = useSelector((state) => state.project.lastEditedProjectList) || [];
  const projectListMap = useSelector((state) => state.clickup.clickupJLLProjectsList);
  const upcomingPopup = useSelector((state) => state.viewmore);
  const islastEditedProject = useSelector((state) => state.project.lastSelectedProjectId) || [];

  const dispatch = useDispatch();
  const regex = /(<([^>]+)>)/gi;
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSetsearchValue] = useState("");

  let debounceTimer;
  const debounceTimeout = 500; // 500ms delay

  const lastEditedProject = projectsData.length > 0 ? projectsData.find(project => project._id === islastEditedProject) : null;

  // const lastEditedProject = projectsData.find(project => project._id === islastEditedProject);
  // const firstProjectArray1 = lastEditedProject ? [lastEditedProject] : [projectsData[0]];
  const firstProjectArray = [projectsData[0]];
  // const lastestModifiedProjectArray = [lastestModifiedProject]

  // const firstProjectArray2 = lastestModifiedProjectArray.length > 0 ? lastestModifiedProjectArray : firstProjectArray;
  let firstProjectArray2 = [];
  if (lastEditedProject) {
    firstProjectArray2 = [lastEditedProject];
  } else {
    firstProjectArray2 = [projectsData[0]];
  }

  const handleSearch = () => {
    const filterData = firstProjectArray2?.filter(
      (value) =>
        value?.project_name
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        value?.project_name
          ?.toUpperCase()
          .includes(searchValue.toUpperCase()) ||
        searchValue.trim() === ""
    );

    setFilteredData(filterData);
  };

  useEffect(() => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleSearch();
    }, debounceTimeout);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue, projectsData]);

  const onChangeStatus = async (val, milestoneName) => {
    const project = projectsData.find(
      (projectData) => projectData._id == val._id
    );

    const newMilestonses = JSON.parse(project.milestone[0]).map((milestone) => {
      if (milestone.milestone_name == milestoneName) {
        return { ...milestone, milestone_status: !milestone.milestone_status };
      }
      return milestone;
    });

    let arr = [];
    newMilestonses.map((milestone) => {
      if (milestone?.milestone_status) {
        arr.push(milestone);
      }
    });

    let payload = new FormData();
    project?.client_id.map((val) => {
      payload.append("client_id[]", val);
    });
    project?.project_user.map((val) => {
      payload.append("project_user[]", val);
    });
    payload.append("project_name", project?.project_name);
    payload.append("start_date", project?.start_date);
    // payload.append("end_date", project?.end_date);
    payload.append("project_url", "");
    payload.append("milestone", JSON.stringify(newMilestonses));
    // payload.append("logo", project?.logo ? project?.logo : "empty");
    payload.append("status", project?.status);
    payload.append("extended_time", project?.extended_time);
    // payload.append("folder_id", projectListMap?.[projectsData?.project_name] || null);
    payload.append(
      "progress",
      // (arr.length / JSON.parse(project.milestone[0]).length) * 100
      arr.reduce((acc, data) => acc + Number(data.milestone_weightage), 0)
    );
    payload.append("id", val._id);

    // return

    try {
      const response = await apiServiceHandler(
        "POST",
        "api/project/update",
        payload
      );
      toastr.success("Milestone updated")
      dispatch(updateRefreshProjectList(true));
      dispatch(updateLastSelectedProjectId(project._id));
      // dispatch(updateLastEditedProjectList(projectsData.find(projectData => projectData._id === project?._id)))
    } catch (err) {
      console.log(err);
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="project-sprint-section">
      <section className="find-project mb-4">
        <div
        //  className="container-fluid"
        >
          <div
            // className="bg-white shadow project-inner"
            class={flag ? "bg-white shadow project-inner-viewmore" : "bg-white shadow project-inner"}>
            {!flag && <h2>Progress</h2>}
            <div
              className={
                flag
                  ? "search-bar d-flex align-items-center"
                  : "search-bar d-flex align-items-center viewmore-line"
              }
            >
              <form>
                <input
                  onChange={(e) => setSetsearchValue(e.target.value)}
                  type="text"
                  name="findproject"
                  placeholder="Find Project..."
                  className="form-control"
                />
                <button type="submit">
                  <img src={SearchIcon} />
                </button>
              </form>
              <div className="d-flex align-items-center find-project-btn">
                {!flag && (
                  <>
                    {" "}
                    {window.screen.width > 760 && <button
                      type="button"
                      onClick={() => {
                        dispatch(updateProjectProgressPopup(true));
                        dispatch(updateupcomingHeadline("Progress"));
                      }}
                      className="expand-icon"
                    >
                      <img src={ExpandIcon} />
                    </button>}
                    <button type="button" className="drag-me">
                      <img src={DragIcon} />
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="project-col">
              {filteredData &&
                filteredData.map((val) => (
                  <div className="project-loop">
                    <div className="project-heading d-flex align-items-center justify-content-between mb-1">
                      <h3 className="mb-0 limit-name-progress">{val?.project_name}</h3>
                      <div className="done-count">
                        {Number(val?.progress).toFixed(0)} / 100%
                      </div>
                    </div>
                    {/* <div className="total-progress">
                      <span
                        className="current-progress"
                        style={{
                          width: val?.progress + "%",
                        }}
                      ></span>
                    </div> */}
                    <div className="progress-stacked">
                      {val?.milestone[0] && JSON.parse(val?.milestone[0])?.map((milestone, indx) => {
                        if (
                          milestone?.milestone_scope &&
                          milestone?.milestone_status &&
                          milestone?.status == "Delayed By Client"
                        ) {
                          return (
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Danger striped example"
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: milestone?.milestone_weightage + "%",
                              }}
                            >
                              <div
                                class="progress-bar progress-bar-striped bg-danger"
                                style={{
                                  borderRight: "1px solid #fff",
                                }}
                              >
                                {milestone?.milestone_name.length > 10
                                  ? milestone?.milestone_name.substring(0, 10) +
                                  "..."
                                  : milestone?.milestone_name} - {"Delayed"}
                              </div>
                            </div>
                          );
                        } else if (
                          !milestone?.milestone_scope &&
                          milestone?.milestone_status &&
                          milestone?.status == "Delayed By Client"
                        ) {
                          return (
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Danger striped example"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: milestone?.milestone_weightage + "%",
                              }}
                            >

                              <div
                                class="progress-bar progress-bar-striped bg-danger"
                                style={{
                                  borderRight: "1px solid #fff",
                                }}
                              >
                                {milestone?.milestone_name.length > 10
                                  ? milestone?.milestone_name.substring(0, 10) +
                                  "..."
                                  : milestone?.milestone_name} - {"Delayed"}
                              </div>
                            </div>
                          );
                        } else if (
                          !milestone?.milestone_scope &&
                          milestone?.milestone_status &&
                          milestone?.status !== "Delayed By Client"
                        ) {
                          return (
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Segment one"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: milestone?.milestone_weightage + "%",
                              }}
                            >

                              <div
                                className="progress-bar"
                                style={{
                                  borderRight: "1px solid #fff",
                                  background: "#57A5FB",
                                }}
                              >
                                {milestone?.milestone_name.length > 10
                                  ? milestone?.milestone_name.substring(0, 10) +
                                  "..."
                                  : milestone?.milestone_name}
                              </div>
                            </div>
                          );
                        } else if (
                          milestone?.milestone_scope &&
                          milestone?.milestone_status &&
                          milestone?.status !== "Delayed By Client"
                        ) {
                          return (
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Segment two"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: milestone?.milestone_weightage + "%",
                              }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  borderRight: "1px solid #fff",
                                  background: "#2563eb",
                                }}
                              >
                                {milestone?.milestone_name.length > 10
                                  ? milestone?.milestone_name.substring(0, 10) +
                                  "..."
                                  : milestone?.milestone_name}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    {val?.milestone[0] && JSON.parse(val?.milestone[0]).length > 0 ? (<>
                      <div className="view-names d-flex align-items-center justify-content-end mt-3">
                        <button
                          type="button"
                          onClick={() => handleItemClick(val._id)}
                        >
                          {activeIndex == val._id ? "View Less" : "View More"}
                        </button>
                      </div>

                      {activeIndex == val._id
                        && val.milestone &&
                        val.milestone[0] &&
                        JSON.parse(val.milestone[0]).map((data) => (
                          <div className="prodect-detail mx-auto mt-3">


                            <div className="sprint-loop">
                              <div className="task-progress mb-2">
                                <span
                                  className="current-task-progress"
                                  style={
                                    data?.milestone_status &&
                                      data?.milestone_scope
                                      ? {
                                        width: "100%",
                                        background: "#2563eb",
                                      }
                                      : data?.milestone_status &&
                                        !data?.milestone_scope
                                        ? { width: "100%" }
                                        : { width: "0%" }
                                  }
                                ></span>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <ul
                                  className="m-0 list-unstyled project-label d-flex align-items-center"
                                  style={
                                    window.screen.width > 760
                                      ? { width: "100%" }
                                      : { width: "100%", display: "flex" }
                                  }
                                >
                                  <li className="limit-name-progress"
                                    style={
                                      window.screen.width > 760
                                        ? {
                                          width: "8%",
                                          lineHeight: "1.2rem",
                                        }
                                        : {
                                          width: "50%",
                                          lineHeight: "1.1rem",
                                        }
                                    }
                                  >
                                    {data?.milestone_name}
                                  </li>
                                  <li
                                    style={
                                      window.screen.width > 760
                                        ? {
                                          width: "80%",
                                          lineHeight: "1.58rem",
                                          textAlign: "justify",
                                          marginTop: "10px"
                                        }
                                        : { display: "none" }
                                    }
                                  >
                                    {Parser(data?.milestone_description)}
                                  </li>
                                  <li
                                    style={
                                      window.screen.width > 760
                                        ? { width: "12%" }
                                        : { width: "50%" }
                                    }
                                  >
                                    {data?.milestone_end_date}
                                  </li>
                                </ul>
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  value={data?.milestone_status}
                                  checked={data?.milestone_status}
                                  onChange={() => {
                                    onChangeStatus(val, data.milestone_name);
                                  }}
                                />
                              </div>
                            </div>

                            {/* : val.milestone &&
                            val.milestone[0] &&
                            JSON.parse(val.milestone[0])
                              .slice(0, 1)
                              .map((data) => (
                                <div className="sprint-loop">
                                  <div className="task-progress mb-2">
                                    <span
                                      className="current-task-progress"
                                      style={
                                        data?.milestone_status &&
                                        data?.milestone_scope
                                          ? {
                                              width: "100%",
                                              background: "#2563eb",
                                            }
                                          : data?.milestone_status &&
                                            !data?.milestone_scope
                                          ? { width: "100%" }
                                          : { width: "0%" }
                                      }
                                    ></span>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <ul
                                      className="m-0 list-unstyled project-label d-flex align-items-center"
                                      style={
                                        window.screen.width > 760
                                          ? { width: "100%" }
                                          : { width: "100%", display: "flex" }
                                      }
                                    >
                                      <li
                                        style={
                                          window.screen.width > 760
                                            ? {
                                                width: "8%",
                                                lineHeight: "1.2rem",
                                              }
                                            : {
                                                width: "50%",
                                                lineHeight: "1.1rem",
                                              }
                                        }
                                      >
                                        {data?.milestone_name}
                                      </li>
                                      <li
                                        style={
                                          window.screen.width > 760
                                            ? {
                                                width: "80%",
                                                lineHeight: "1.3rem",
                                                textAlign: "justify",
                                              }
                                            : { display: "none" }
                                        }
                                      >
                                        {data?.milestone_description.replace(
                                          regex,
                                          ""
                                        ).length > 180
                                          ? data?.milestone_description
                                              .replace(regex, "")
                                              .substring(0, 180) + "..."
                                          : data?.milestone_description.replace(
                                              regex,
                                              ""
                                            )}
                                      </li>
                                      <li
                                        style={
                                          window.screen.width > 760
                                            ? { width: "12%" }
                                            : { width: "50%" }
                                        }
                                      >
                                        {data?.milestone_end_date}
                                      </li>
                                    </ul>
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                      value={data?.milestone_status}
                                      checked={data?.milestone_status}
                                      onChange={() => {
                                        onChangeStatus(
                                          val,
                                          data.milestone_name
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              ))} */}

                            {/* <div className="view-names d-flex align-items-center justify-content-end mt-3">
                          <button
                            type="button"
                            onClick={() => handleItemClick(val._id)}
                          >
                            {activeIndex == val._id ? "View Less" : "View More"}
                          </button>
                        </div> */}
                          </div>))}
                    </>) : (
                      <p style={{ marginTop: "10px", marginLeft: "10px" }}>
                        No Milestones in <b>{val?.project_name}</b>...
                      </p>
                    )}
                  </div>
                ))}
            </div>
          </div>
          {window.screen.width > 760 && !flag && (
            <button
              type="button"
              onClick={() => {
                dispatch(updateProjectProgressPopup(true));
                dispatch(updateupcomingHeadline("Progress"));
              }}
              class="view-more"
            >
              View More
            </button>
          )}{" "}
        </div>
      </section>
    </div>
  );
};

export default ProjectProgress;
