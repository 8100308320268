// store.js
import { configureStore } from '@reduxjs/toolkit';
import userManagement from '../redux/userManagement/index';
import project from '../redux/project/index';
import task from '../redux/task/index';
import notification from '../redux/notifications/index';
import viewmore from '../redux/viewmore/index';
import loader from '../redux/loader/index';
import documents from '../redux/documents';
import tickets from '../redux/chatsandtickets';
import Invoice from "../redux/invoice/index";
import reportsAnalytics from '../redux/reportsandAnalytics';
import userData from '../redux/userData/index';
import userlogs from '../redux/userlogs/index';
import clickup from '../redux/clickup/index';


const store = configureStore({
  reducer: {
    allUserData: userManagement,
    project: project,
    task: task,
    notification: notification,
    viewmore: viewmore,
    loader: loader,
    documents: documents,
    tickets: tickets,
    invoice: Invoice,
    reportsAnalytics,
    userData: userData,
    userlogs: userlogs,
    clickup: clickup
  },
});

export default store;
