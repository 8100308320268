import React, { useEffect, useRef, useState } from 'react'
import EditIcon from '../../assests/images/edit.svg';
import apiServiceHandler from '../../service/apiService';
import SuspendSubscriptionModal from './suspendSubscriptionModal';
import ActivateSubscriptionModal from './activateSubscriptionModal';
import CancelSubscriptionModal from './cancelSubscriptionModal';
import UpgradeSubscriptionModal from './upgradeSubscriptionAction'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SubscriptionActions = (props) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { paymentDetails, uniqueUrl, fetchPaymentDetails } = props
  const [optionsVisible, setOptionsVisible] = useState(false);
  const userLists = useSelector(state => state.allUserData.users)
  const projectList = useSelector(state => state.project.projectsData)

  const [showSuspend, setShowSuspend] = useState(false);
  const [showActivate, setShowActivate] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const handleClose = () => {
    setShowSuspend(false)
    setShowActivate(false)
    setShowCancel(false)
    setShowUpgrade(false)
  };
  const handleShowSuspend = () => {
    setShowSuspend(true)
  };
  const handleShowActivate = () => {
    setShowActivate(true)
  };
  const handleShowCancel = () => {
    setShowCancel(true)
  };
  const handleShowUpgrade = () => {
    setShowUpgrade(true)
  };
  const handleUpdatePlan = () => {
    navigate('/choose-plans', { state: { firstName: userData?.first_name, unique_url: userData?.unique_url, email: userData?.email, currentUsers: userLists.length, currentProject: projectList.length } })
  }

  const updatePaymentDetailsDB = async (payload) => {
    try {
      const updatedDetails = await apiServiceHandler('POST', 'api/payment/paypal/update/payment-details', payload)

      return updatedDetails;
    } catch (error) {
      console.log(error, 'Error is here in subscriptionAction updatePaymentDetailsDB')
    }
  }

  const modalRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (modalRef.current && !(modalRef.current).contains(event.target)) {
      setOptionsVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div class="col-lg-1 text-lg-center user-action-col">
      {/* <label class="mb-0">Action</label> */}
      <div class="dropdown">
        <button type="button" class="user-action dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><img src={EditIcon} /></button>
        <div class="user-action-data dropdown-menu">
          {paymentDetails?.payment_details?.status !== 'CANCELLED' && paymentDetails?.payment_details?.status !== 'Free_Trial' && <button type="button" onClick={handleShowCancel}>Cancel</button>}
          {paymentDetails?.payment_details?.status === 'ACTIVE' && <button type="button" onClick={handleShowSuspend}>Suspend</button>}
          {paymentDetails?.payment_details?.status === 'SUSPENDED' && <button type="button" onClick={handleShowActivate}>Activate</button>}
          {paymentDetails?.payment_details?.status !== 'CANCELLED' && paymentDetails?.payment_details?.status !== 'Free_Trial' && <button type="button" onClick={handleShowUpgrade}>Upgrade Plan</button>}
          {(paymentDetails?.payment_details?.status == 'CANCELLED' || paymentDetails?.payment_details?.status == 'Free_Trial') && <button type="button" onClick={handleUpdatePlan}>Update Plan</button>}
        </div>
        <SuspendSubscriptionModal show={showSuspend} handleClose={handleClose} paymentDetails={paymentDetails} uniqueUrl={uniqueUrl} updatePaymentDetailsDB={updatePaymentDetailsDB} fetchPaymentDetails={fetchPaymentDetails} />
        <ActivateSubscriptionModal show={showActivate} handleClose={handleClose} paymentDetails={paymentDetails} uniqueUrl={uniqueUrl} updatePaymentDetailsDB={updatePaymentDetailsDB} fetchPaymentDetails={fetchPaymentDetails} />
        <CancelSubscriptionModal show={showCancel} handleClose={handleClose} paymentDetails={paymentDetails} uniqueUrl={uniqueUrl} updatePaymentDetailsDB={updatePaymentDetailsDB} fetchPaymentDetails={fetchPaymentDetails} />
        <UpgradeSubscriptionModal show={showUpgrade} handleClose={handleClose} paymentDetails={paymentDetails} uniqueUrl={uniqueUrl} updatePaymentDetailsDB={updatePaymentDetailsDB} fetchPaymentDetails={fetchPaymentDetails} />
      </div>
    </div>
  )
}
export default SubscriptionActions;






