import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import { useNavigate, useParams } from 'react-router-dom';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshUserDatap } from '../../redux/userManagement';
import { useDispatch } from 'react-redux';
import ProfileImg from '../../assests/images/avatar.svg';

const EditProfile = ({ setRefreshData, edituserData, isOpen, onClose }) => {
    const [userUpdatedData, setUserUpdatedData] = useState(edituserData)
    const [rolesList, setRolesList] = useState([])
    const [selectRole, setSelectRole] = useState("")
    const [uploadedFiles, setUploadedFiles] = useState(null)
    const { id } = useParams()
    const user = JSON.parse(localStorage.getItem('userData'));
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        setUserUpdatedData(edituserData)
    }, [edituserData]);
    const [errorMessage, setErrorMessage] = useState('');

    let usermData = localStorage.getItem("userData")
    usermData = usermData ? JSON.parse(usermData) : {}

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "status") {
            setUserUpdatedData((prevUser) => ({
                ...prevUser,
                [name]: value === 'Active' ? 1 : 0,
            }));
            return
        }
        setUserUpdatedData((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleCompanyChange = (event) => {
        const { name, value } = event.target;
        let existingcompanyName = usermData?.company_name

        setUserUpdatedData((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        if (value !== existingcompanyName) {
            setErrorMessage(`External to ${existingcompanyName}`);
        } else {
            setErrorMessage('');
        }
    }
    const handleEditUser = async (e) => {
        e.preventDefault();
        if (userUpdatedData?.login_code) {
            if (!(userUpdatedData?.login_code?.length === 0 || userUpdatedData?.login_code?.length === 4)) {
                toastr.info("Login Pin should of 4 digits");
                return;
            }
        }
        const payload = {
            "login_code": userUpdatedData?.login_code,
            "uniqueUrl": userUpdatedData?.unique_url,
            "firstName": userUpdatedData?.first_name,
            "lastName": userUpdatedData?.last_name,
            "role": userUpdatedData?.role,
            "email": userUpdatedData?.email,
            "state": userUpdatedData?.state,
            "status": userUpdatedData?.status,
            "addressLine1": userUpdatedData?.address_line_1,
            "addressLine2": userUpdatedData?.address_line_2,
            "city": userUpdatedData?.city,
            "zipCode": userUpdatedData?.zipcode,
            "country": userUpdatedData?.country,
            "phone": userUpdatedData?.phone,

        }
        try {
            if (edituserData.role != userUpdatedData.role) {
                payload.user.layout = [];
            }
            const response = await apiServiceHandler("PUT", "api/auth/user/details", payload)
            toastr.success(response?.message)
            // dispatch(updateRefreshUserDatap(true))
            onClose()
            setRefreshData(true)
            if (userUpdatedData?.login_code) {
                if (userUpdatedData?.login_code?.length === 4) {
                    navigate(`/${id}`)
                }
            }
        } catch (err) {
            toastr.error("error updating user")
        }
    }

    const fetchRoleList = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/get_roles_list", {})
            setRolesList(response?.role_list)
        } catch (e) {
            console.log(e)
        }
    }

    const uploadCompanyLogo = async (file) => {
        let payload = new FormData();
        payload.append("isCompanyLogo", true)
        payload.append("files", file)
        payload.append("company_id", user.company_id ? user.company_id : user.parent_company_id)

        try {
            const response = await apiServiceHandler("POST", "api/auth/add/company/logo", payload)
            dispatch(updateRefreshUserDatap(true));
            setRefreshData(true)
            toastr.success(response?.message)
        } catch (e) {
            console.log(e)
            toastr.error("Error in Uploading Company Logo file")
        }
    }

    const uploadProfileLogo = async (file) => {
        let payload = new FormData();
        payload.append("company_id", user.company_id ? user.company_id : user.parent_company_id)
        payload.append("files", file)
        try {
            const response = await apiServiceHandler("POST", "api/auth/add/logo", payload)
            dispatch(updateRefreshUserDatap(true));
            setRefreshData(true)
            toastr.success(response?.message)
        } catch (e) {
            console.log(e)
            toastr.error("Error in Uploading Profile Logo file")
        }
    }

    useEffect(() => {
        fetchRoleList()
    }, [])

    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        // Trigger the click event of the file input when the image is clicked
        fileInputRef.current.click();
    };

    const handleFileChange = (event, isCompanyLogo) => {
        if (isCompanyLogo) {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                // You can perform further actions with the selected file here
                uploadCompanyLogo(selectedFile)
            }
            console.log('Uploading Company Logo');
            return
        }
        // Handle the selected file
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // You can perform further actions with the selected file here
            uploadProfileLogo(selectedFile)
            console.log('Uploading Profile Logo');
        }
    };



    return (
        <div>
            <div
                className={`modal fade edit-profile edit-task-viewmore ${isOpen ? "show backdrop" : " "}`}
                id="editProfileModal"
                tabindex="-1"
                aria-labelledby="editProfileModalLabel"
                aria-hidden="true"
                style={{ display: isOpen ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h2 className="modal-title">Edit Profile</h2>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <img src={CloseIcon} />
                            </button>
                        </div>

                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div onClick={handleImageClick} class="col-md-4 mb-2" >
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={(e) => handleFileChange(e, false)}
                                        />
                                        <img style={{ cursor: "pointer", borderRadius: "50%", height: "100px", width: "100px" }} src={userUpdatedData?.logo ? userUpdatedData?.logo : ProfileImg} />
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <label>First name</label>
                                        <input type="text" className="form-control" placeholder="First name*" name='first_name' value={userUpdatedData?.first_name} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <label>Last name</label>
                                        <input type="text" className="form-control" name='last_name' placeholder="Last name*" value={userUpdatedData?.last_name} onChange={handleChange} />
                                    </div>
                                    {user && user.access_to_apis.access_to_change_company_logo && <div className="col-md-6 mb-2">
                                        <label>Company Logo</label>
                                        <input type="file" accept="image/*" className="form-control" name='comapnayLogo' onChange={(e) => handleFileChange(e, true)} />
                                    </div>}
                                    <div className="col-md-6 mb-2">
                                        <label>Email</label>
                                        <input type="email" className="form-control" name='email' placeholder="Email*" value={userUpdatedData?.email} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Change Login Pin</label>
                                        <input type="text" className="form-control" name='login_code' placeholder="change pin*" value={userUpdatedData?.login_code} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Role</label>
                                        <select disabled={true} onChange={(e) => handleChange(e)} value={userUpdatedData?.role} name='role' placeholder='select role' className="form-select" aria-label="status">
                                            <option>Select role</option>
                                            {rolesList && rolesList.map(role => <option value={role}>{role}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Contact Number <span>(optional)</span></label>
                                        <input type="text" className="form-control" name='phone' placeholder="Contact Number*" value={userUpdatedData?.phone} onChange={handleChange} />
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                        <label>Login PIN</label>
                                        <input type="text" className="form-control" name='login_code' placeholder="Set Login PIN*" value="Set Login PIN" />
                                    </div> */}
                                    <div className="col-6 mb-2">
                                        <label>URL</label>
                                        <input disabled type="text" className="form-control" name='unique_url' value={userUpdatedData?.unique_url} placeholder="southbisonconnect.com*" onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control" name='company_name' value={userUpdatedData?.company_name} placeholder="Company Name*" onChange={handleCompanyChange} />
                                        {errorMessage && <p style={{ color: 'red', textAlign: 'left', margin: '2px', fontSize: '15px' }}>{errorMessage}</p>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Status</label>
                                        <select disabled={true} name='status' onChange={handleChange} value={userUpdatedData?.status === 1 ? "Active" : "Inactive"} className="form-select" aria-label="status">
                                            <option>Select status</option>
                                            <option value="Active" defaultValue={"Active"}>Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Address Line 1</label>
                                        <input type="text" className="form-control" placeholder="Address Line 1*" name='address_line_1' value={userUpdatedData?.address_line_1} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Address Line 2</label>
                                        <input type="text" className="form-control" placeholder="Address Line 2*" name='address_line_2' value={userUpdatedData?.address_line_2} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>City</label>
                                        <input type="text" className="form-control" placeholder="City" name='city' value={userUpdatedData?.city} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>State</label>
                                        <input type="text" className="form-control" placeholder="State*" name='state' value={userUpdatedData?.state} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2 mb-md-0">
                                        <label>Country</label>
                                        <input type="text" className="form-control" placeholder="Country*" name='country' value={userUpdatedData?.country} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Postal Code</label>
                                        <input type="text" className="form-control" placeholder="Postal Code*" name='zipcode' value={userUpdatedData?.zipcode} onChange={handleChange} />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="submit" className="bg-btn" onClick={handleEditUser}>Update</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditProfile;
