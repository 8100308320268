import React, { useEffect, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { updateRefreshFolderData, updateRefreshFolders } from "../../redux/documents";
import apiServiceHandler from "../../service/apiService";

const DeleteFolder = ({ isOpen, onClose, indexData, crumbs }) => {
  const dispatch = useDispatch();

  const deleteFolder = async () => {
    try {
      let response = [];

      response = await apiServiceHandler(
        "POST",
        "api/file/management/delete/folder",
        { folderId: indexData }
      );

      if(crumbs?.length == 0) {
        dispatch(updateRefreshFolders(true));  
      }else {
        dispatch(updateRefreshFolders(true));
        dispatch(updateRefreshFolderData(true));
      }
      onClose();
      toastr.success(response?.message);
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <div>
      <div
        className={`modal fade add-user edit-task-viewmore ${isOpen ? "show backdrop" : " "}`}
        id="addUserModal"
        tabindex={-1}
        aria-labelledby="addUserModalLabel"
        aria-hidden={true}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h2 className="modal-title">Delete Folder/File</h2>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <img src={CloseIcon} />
              </button>
            </div>

            <div className="modal-body text-center">
              <h3>Are you sure?</h3>
              <p className="mb-0">
                Are you sure you want to delete this folder/file?
              </p>
            </div>

            <div className="modal-footer justify-content-between">
              <button type="submit" className="bg-btn bg-btn-mobile" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="bg-btn bg-btn-mobile" onClick={deleteFolder}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFolder;
