import React, { useEffect, useRef, useState } from 'react'
import EditIcon from '../../assests/images/pencil-white.svg';
import { useDispatch } from 'react-redux';
import { updatopenInvoiceApprovrFlag } from '../../redux/invoice';

const Actions = (props) => {
    const dispatch = useDispatch()
    const { userData, setEdituserData, setIsOpenEditUser } = props
    const [optionsVisible, setOptionsVisible] = useState(false);
    const toggleOptions = () => {
        setOptionsVisible((prevIsVisible) => !prevIsVisible);
        //   setOptionsVisible((prevOption) => (prevOption === option ? null : option));
    };
    const modalRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (modalRef.current && !(modalRef.current).contains(event.target)) {
            setOptionsVisible(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleForwarding = () => {
        alert('Forwarding')
    }

    const handleApproval = () => {
        // alert('Approval')
        dispatch(updatopenInvoiceApprovrFlag(true))
    }

    return (
        <div class="">
            {/* <label class="mb-0">Action</label> */}
            <div class="dropdown">
                <button type="button" class="user-action dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><img src={EditIcon} /></button>
                <div class="user-action-data dropdown-menu">
                    <button type="button" data-bs-target="#editProfileModal" onClick={() => handleApproval(userData)}>Approval Request</button>
                    {/* <button type="button" data-bs-target="#deleteUserModal" onClick={() => handleForwarding()}>Forwarding</button> */}
                </div>
            </div>
        </div>
    )
}
export default Actions;






