// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const project = createSlice({
  name: 'project',
  initialState: {
    refreshProjectList: false,
    projectList: [],
    showProjectList: [],
    editData: [],
    projectsData: [],
    selectedProjectWeightage: 0,
    lastSelectedProjectId: null,
    lastEditedProjectList: []
  },
  reducers: {
    updateProjectList: (state, action) => {
      state.projectList = action.payload; // Update the count using the payload
    },
    updateShowProjectList: (state, action) => {
      state.showProjectList = action.payload; // Update the count using the payload
    },
    updateRefreshProjectList: (state, action) => {
      state.refreshProjectList = action.payload; // Update the count using the payload
    },
    updateEditData: (state, action) => {
      state.editData = action.payload; // Update the count using the payload
    },
    updateProjectsData: (state, action) => {
      state.projectsData = action.payload
    },
    updateSelectedProjectWeightage: (state, action) => {
      state.selectedProject = action.payload
    },
    updateLastSelectedProjectId: (state, action) => {
      state.lastSelectedProjectId = action.payload
    },
    updateLastEditedProjectList: (state, action) => {
      state.lastEditedProjectList = action.payload
    },
    
  },
});

export const { updateProjectList, updateRefreshProjectList, updateShowProjectList, updateEditData, updateProjectsData, updateSelectedProjectWeightage, updateLastSelectedProjectId, updateLastEditedProjectList } = project.actions;
export default project.reducer;
