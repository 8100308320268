import React, { useEffect, useState } from 'react'
import './tasks.css';
import PlusIcon from '../../assests/icons/plusg.svg';
import Filter from '../../assests/images/filter.svg';
import SearchIcon from '../../assests/images/search.svg';
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import EditIcon from '../../assests/images/edit.svg';
import StartIcon from '../../assests/images/start.svg';
import StopRed from '../../assests/images/stop-red.svg';
import { useDispatch, useSelector } from 'react-redux';
import AddNewTask from '../tasks/addNewTask';
import { updateEditTaskData, updateRefreshTaskList } from '../../redux/task';
import EditTask from '../tasks/editTask';
import { updatetaskNotToClientPopup, updateupcomingHeadline } from '../../redux/viewmore';
import { convertArrayToMinutes, formatDateToISOString, getTimeDifference, getCurrentFormattedDate } from '../../pages/tasks/timeTracking';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";



const Tasks = ({ setTasksListProps, flag }) => {
    const notClientTaskList = useSelector(state => state.task.TasksList?.notShowToclient)
    const usersList = useSelector(state => state.allUserData.users)
    const projects = useSelector(state => state.project?.showProjectList) || []
    const [openAddNewModal, setOpenAddNewModal] = useState(false)
    const [openEditTaskModal, setOpenEditTaskModal] = useState(false)
    const [openTimeTrackingModal, setOpenTimeTrackingModal] = useState(false);
    const upcomingPopup = useSelector(state => state.viewmore)
    const dispatch = useDispatch()
    // const myTimeTrackingTaskData = useSelector(state => state.task.editData)
    const [clickedTaskId, setClickedTaskId] = useState('')
    const [clickStart, setClickStart] = useState(false);
    const [clickStop, setClickStop] = useState(false);
    const [searchValue, setSetsearchValue] = useState("")
    const [filteredData, setFilteredData] = useState([])
    let debounceTimer;
    const debounceTimeout = 500; // 500ms delay
    const userAssigned = (existingUser) => {

        return existingUser.map((userId) => {
            const user = usersList.find((user) => user._id === userId);
            return user ? `${user.first_name} ${user.last_name}, ` : 'Unknown';
        });
    }
    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);

        // Get day, month, and year components
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        // Format the date as "dd month name, year"
        return `${day} ${month} ${year}`;
    };
    const handleClose = () => {
        setOpenAddNewModal(false)
        setOpenEditTaskModal(false)
        setOpenTimeTrackingModal(false)
    }
    const handleEditTask = (value) => {
        setOpenEditTaskModal(true)
        dispatch(updateEditTaskData(value))
    }
    const handleTimeTracking = (value) => {
        setOpenTimeTrackingModal(true)
        dispatch(updateEditTaskData(value))
    }

    useEffect(() => {
        if (openAddNewModal) {
            setTasksListProps({
                ModalName: 'AddNewTask',
                isOpen: openAddNewModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } else if (openEditTaskModal) {
            setTasksListProps({
                ModalName: 'EditTask',
                isOpen: openEditTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } else if (openTimeTrackingModal) {
            setTasksListProps({
                ModalName: 'TimeTracking',
                isOpen: openTimeTrackingModal,
                onClose: handleClose
            })
        }
        else {
            setTasksListProps(null)
        }
    }, [openAddNewModal, openEditTaskModal, openTimeTrackingModal, projects[0]])

    const handleClickStart = (val) => {
        let payload = {
            "id": val._id,
            "live_time_tracking_flag": true,
            "live_time_tracking_start_time": new Date()
        };
        updateTask(payload, false, val)
    }
    const handleClickStop = (val) => {
        let payload = {
            "id": val._id,
            "live_time_tracking_flag": false,
        };
        updateTask(payload, true, val)
    }
    // Task Update API
    const updateTask = async (payload, flag, val) => {
        try {
            await apiServiceHandler("POST", "api/task/update", payload)
            dispatch(updateRefreshTaskList(true))
            if (flag) addStartStopTime(val)
        } catch (err) {
            console.log(err)
        }
    }
    const addStartStopTime = async (val) => {
        let currentTime = new Date()
        let parsedTimeStart = formatDateToISOString(currentTime)
        let time = getTimeDifference(val.live_time_tracking_start_time, parsedTimeStart)
        const descTimeStamph = `${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}:${new Date().getSeconds().toString().padStart(2, '0')}`;
        let payload = {
            "timeTracked": time,
            "taskId": val._id,
            "description": `Time Tracked ( ${descTimeStamph} )`,
            "date": getCurrentFormattedDate()
        };
        try {
            await apiServiceHandler("POST", "api/tasks/update/time/tracking", payload)
            dispatch(updateRefreshTaskList(true))
        } catch (err) {
            console.log(err)
        }
    }
    const handleNewTaskButton = () => {

        if (projects.length > 0) {
            setOpenAddNewModal(true)
        } else {
            toastr.error("No Project Found")
        }

    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (notClientTaskList) {
            const filterData = notClientTaskList.filter(value => value?.task_name?.includes(searchValue) || value.task_name.toLowerCase().includes(searchValue.toLowerCase()) || searchValue.trim() === "")
            setFilteredData(filterData)
        }
    }
    useEffect(() => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleSearch({ preventDefault: () => {} })
        }, debounceTimeout);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue, notClientTaskList]);



    return (
        <div className='current-working-task-section'>
            <section className="current-working-task mb-4">
                <div
                // className="container-fluid"
                >
                    <div className={flag ? "shadow working-inner-viewmore" : "shadow working-inner"}>
                        <div className="working-task">
                            <div className={flag ? "current-search-bar" : "current-search-bar viewline"}>
                                {!flag && <h2>Current Working Task<span className='m-1 align-items-center'>  (Not visible to the client*)</span></h2>}
                                <div className="search-group d-flex align-items-center">
                                    <form onSubmit={handleSearch}>
                                        <input onChange={(e) => setSetsearchValue(e.target.value)} type="text" name="findtask" placeholder="Find Task..." className="form-control" />
                                        <button type="submit"><img src={SearchIcon} /></button>
                                    </form>
                                    <div className="d-flex align-items-center task-btn">
                                        {!upcomingPopup.taskNotToClientPopup && <> <button type="button" className="filter-icon"><img src={Filter} /></button>
                                            <button type="button" className="add-icon" onClick={() => handleNewTaskButton()} ><img src={PlusIcon} /></button>
                                            <button type="button" onClick={() => {
                                                dispatch(updatetaskNotToClientPopup(true));
                                                dispatch(updateupcomingHeadline("Current Tasks (* Not visible to Client)"))
                                            }} className="add-icon"><img src={ExpandIcon} /></button>
                                            <button type="button" className="drag-me"><img src={DragIcon} /></button></>}
                                    </div>
                                </div>
                            </div>
                            <div className="current-tasks-col">
                                <div className="current-tasks-label d-none d-lg-block mb-3">
                                    <div className="row g-0">
                                        <div className="col-lg-2">
                                            <h3>Task Name</h3>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            <h3>Due Date</h3>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            <h3>Category</h3>
                                        </div>
                                        <div className="col-lg-1 text-lg-center">
                                            <h3>Project</h3>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            <h3>Assignee</h3>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            <h3>Time Tracking</h3>
                                        </div>
                                        <div className="col-lg-1 text-lg-center">
                                            <h3>Action</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className={flag ? "current-tasks-data-viewmore" : "current-tasks-data"}>
                                    {filteredData && filteredData?.map((val) => (<div className="row task-row g-0">
                                        <div className="col-lg-2">
                                            {/* <label className="mb-0">Task Name</label> */}
                                            <p className="mb-0">{val?.task_name}</p>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            {/* <label className="mb-0">Due Date</label> */}
                                            <p className="mb-0">{getFormattedDate(val?.due_date)}</p>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            {/* <label className="mb-0">Task Progress</label> */}
                                            <div className="task-progress-label mb-0" >
                                                Clickup Tasks
                                                {/* {val?.task_status[0].split('_')
                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                .join(' ')} */}
                                                </div>
                                        </div>
                                        <div className="col-lg-1 text-lg-center">
                                            {/* <label className="mb-0">Project</label> */}
                                            <p className="mb-0">{val?.project_name}</p>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            {/* <label className="mb-0">Assignee</label> */}
                                            <div className="assignee-label">
                                                {userAssigned(val?.assigned_to)}

                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            {/* <label className="mb-0">Time Tracking</label> */}
                                            <div className="mb-0 current-task-time-track">
                                                <button type="button" className="no-default m-1">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={StartIcon} width={18} onClick={() => handleClickStart(val)} style={{ display: val?.live_time_tracking_flag ? "none" : "block" }} />
                                                        <img src={StopRed} width={18} onClick={() => handleClickStop(val)}
                                                            style={{ display: val?.live_time_tracking_flag ? "block" : "none" }} />
                                                        <span onClick={() => handleTimeTracking(val)}>&nbsp;{convertArrayToMinutes(val?.time_tracking)}</span>
                                                    </div>
                                                </button>

                                            </div>
                                        </div>
                                        <div className="col-lg-1 text-lg-center">
                                            {/* <label className="mb-0">Action</label> */}
                                            <button type="button" className="edit-task-action" onClick={() => handleEditTask(val)}><img src={EditIcon} /></button>
                                        </div>
                                    </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                    {!upcomingPopup.taskNotToClientPopup && <button type="button" onClick={() => {
                        dispatch(updatetaskNotToClientPopup(true));
                        dispatch(updateupcomingHeadline("Current Tasks (* Not visible to Client)"))
                    }} class="view-more">View More</button>}
                </div>
            </section>
        </div>
    )
}

export default Tasks;
