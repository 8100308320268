import React, { useEffect, useRef, useState } from "react";
import "./navbar.css";
import CloseIcon from "../../assests/images/close-menu.svg";
import SearchIcon from "../../assests/images/search-black.svg";
import DotIcon from "../../assests/images/vertical-dots.svg";
import Avatar from '../../assests/images/avatar.svg';
import Logo from "../../assests/images/logo.png";
import Cody from "../../assests/images/cody-fisher.png";
import PlusIcon from "../../assests/icons/plusg.svg";
import AddnewProject from "./AddnewProject";
import EditProjectModal from "./editProjectModal";
import apiServiceHandler from "../../service/apiService";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import NavbarActions from "./NavbarActionOption";
import { useDispatch, useSelector } from "react-redux";
import {
    updateLastEditedProjectList,
    updateLastSelectedProjectId,
    updateProjectList,
    updateProjectsData,
    updateRefreshProjectList,
    updateSelectedProjectId,
    updateShowProjectList,
} from "../../redux/project";
import DeleteProject from "./deleteProject";
import { updateClickupJLLProjectsList } from "../../redux/clickup";
import { useParams } from 'react-router-dom';

const Navbar = ({ setShowNavbar }) => {
    const { id } = useParams()
    const [openAddProjectModal, setOpenAddProjectModal] = useState(false);
    const [openEditProjectModal, setOpenEditProjectModal] = useState(false);
    const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [activeUser, setActveUser] = useState(null);
    const navbarRef = useRef(null);
    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [firstTimeAddData, setFirstTimeAddData] = useState(true);
    const dispatch = useDispatch();
    const project = useSelector((state) => state.project) || [];
    const refreshProjectList = useSelector((state) => state.project.refreshProjectList);
    const islastEditedProject = useSelector((state) => state.project.lastSelectedProjectId) || [];
    const usersList = useSelector((state) => state.allUserData.users) || [];
    let debounceTimer;
    const debounceTimeout = 500;
    const paymentDetails = useSelector(state => state.allUserData.paymentDetails.details)
    const [projectListLength, setProjectListLength] = useState(0)

    const [openClients, setOpenClients] = useState({});

    let usermData = localStorage.getItem("userData");
    usermData = usermData ? JSON.parse(usermData) : {};
    let currentUserRole = usermData.role;

    let JLL_SpaceId = localStorage.getItem("JLL_SpaceId");

    const ProjectNamesList = useSelector(state => state.project?.projectList)
    const projectNamesSet = new Set();

    for (const clientId in ProjectNamesList) {
        const clientData = ProjectNamesList[clientId];

        clientData.projects.forEach(project => {
            projectNamesSet.add(project.project_name);
        });
    }


    const projectNamesArray = [...projectNamesSet];

    localStorage.setItem('JLL_ProjectList', JSON.stringify(projectNamesArray))

    const selectedProject = useSelector(state => state.project.showProjectList)
    useEffect(() => {
        // usersList
        setProjectList(project?.projectList);
    }, [usersList, project]);

    const handleActiveUser = (itemId) => {
        setOpenClients((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId], // Toggle the open status
        }));
    };
    const handleClose = () => {
        setOpenAddProjectModal(false);
        setOpenEditProjectModal(false);
        setOpenDeleteProjectModal(false);
    };

    const clickUpProjectListUpdate = async () => {
        const payload2 = {
            uniqueUrl: id
        }
        try {
            const resClickup = await apiServiceHandler('POST', "api/auth/login_clickup", payload2);

            const data = resClickup.clickUpData;
            // console.log("response1", data)
            localStorage.setItem('JLL_SpaceId', data[1])
            // localStorage.setItem('Clickup_SpaceIds', JSON.stringify(data[3]))
            localStorage.setItem('Clickup_Users', JSON.stringify(data[4]))
            let payload = {
                "spaceId": data[1]
            }
            try {
                const response = await apiServiceHandler("POST", "api/project/get_clickup_projects", payload);
                // console.log(response, "fetchClickProjects")
                dispatch(updateClickupJLLProjectsList(response?.data))

            } catch (err) {
                console.log(err);
            }


        } catch (e) {
            console.log(e);
            // toastr.error(e?.response?.data?.message);
        }
    };

    const projectEditData = useSelector((state) => state.project.editData);

    const handleProjectList = async () => {
        try {
            const accessToAllProject = usermData.access_to_apis.access_to_all_project ? (usermData.parent_company_id ? usermData.parent_company_id : usermData.company_id) : ''
            const response = await apiServiceHandler(
                "POST",
                "api/project/client/list",
                { company_id: accessToAllProject }
            );
            const projectCount = await apiServiceHandler(
                "POST",
                "api/auth/user/project/count",
                { company_id: usermData?.parent_company_id ? usermData.parent_company_id : usermData.company_id }
            );
            setProjectListLength(projectCount.data)
            dispatch(updateProjectList(Object.values(response?.data) || []));
            const data = Object.values(response?.data)[0] || [];
            handOpenProject(data?.projects || []);
            const projectsData = Object.values(response?.data);
            let myProjects = [];
            projectsData.map((project) =>
                project.projects.map((proj) => myProjects.push(proj))
            );
            const jsonObject = myProjects.map(JSON.stringify);
            const uniqueSet = new Set(jsonObject);
            const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
            dispatch(updateProjectsData(uniqueArray));

            const lastEditedProjectExists = uniqueArray.some(projectData => projectData._id === islastEditedProject);

            if (lastEditedProjectExists) {
                handOpenProject([uniqueArray.find(projectData => projectData._id === islastEditedProject)]);
                dispatch(updateLastEditedProjectList(uniqueArray.find(projectData => projectData._id === islastEditedProject)))
            } else {
                handOpenProject(uniqueArray || []);
            }
            const project = uniqueArray.find(
                (projectData) => projectData._id == projectEditData._id
            );
            let arr = [];
            JSON.parse(project.milestone[0]).map((milestone) => {
                if (milestone.milestone_status) {
                    arr.push(milestone);
                }
            });
            let newProgress =
                JSON.parse(project.milestone[0]).length > 0
                    ? arr.reduce((acc, data) => acc + Number(data.milestone_weightage), 0)
                    : 0;
            const newUniqueArray = uniqueArray.map((projectData) => {
                if (projectData._id == projectEditData._id) {
                    return { ...projectData, progress: newProgress };
                }
                return projectData;
            });
            dispatch(updateProjectsData(newUniqueArray));
            // if (firstTimeAddData) {
            // handOpenProject(data?.projects || []);
            setFirstTimeAddData(false);
            // }
        } catch (err) {
            // toastr.error(err?.response?.data?.message)
            console.log(err);
        }
    };

    const handleOutsideClick = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setShowNavbar(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        handleProjectList();
    }, []);

    useEffect(() => {
        if (project.refreshProjectList) {
            setFirstTimeAddData(true);
            handleProjectList();
            dispatch(updateRefreshProjectList(false));
        }
    }, [project.refreshProjectList]);
    useEffect(() => {
        if (refreshProjectList) {
            setFirstTimeAddData(true);
            handleProjectList();
            dispatch(updateRefreshProjectList(false));
        }
    }, [refreshProjectList]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (project) {
            const filterData = project?.projectList?.filter(
                (value) =>
                    value?.client_name?.includes(searchValue) ||
                    value?.client_name?.includes(searchValue.toLowerCase()) ||
                    searchValue.trim() === ""
            );
            setFilteredData(filterData);
        }
    };

    useEffect(() => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleSearch({ preventDefault: () => { } });
        }, debounceTimeout);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue, project?.projectList]);

    const handOpenProject = (val) => {
        const projectsArray = Array.isArray(val) ? val : [];
        dispatch(updateShowProjectList(projectsArray));
        dispatch(updateProjectsData(projectsArray));
        // dispatch(updateShowProjectList(val || []));
        // dispatch(updateProjectsData(val || []));
        // handleLastEditedProject(projectsArray)
        setShowNavbar(false);
    };

    const handleLastEditedProject = (val) => {
        console.log(val, "val is here...")
        // dispatch(updateLastEditedProjectList(val[0] || []));
        dispatch(updateLastSelectedProjectId(val[0]._id));
    }


    const handleNewProject = () => {
        toastr.error("You don't have subscription to add Project");
    };

    const isSelectedProject = (projectId) => {
        return projectId === selectedProject[0]?._id;
        // return selectedProject.some(project => project._id === projectId);

    }

    const [hasClient, setHasClient] = useState(false);

    useEffect(() => {
        const checkForClient = () => {
            const hasClientRole = usersList.some((user) => user.role === 'client');
            setHasClient(hasClientRole);
        };

        checkForClient();
    }, [usersList]);

    const fetchClickupProjects = async () => {
        let payload = {
            "spaceId": JLL_SpaceId
        }
        try {
            const response = await apiServiceHandler("POST", "api/project/get_clickup_projects", payload);
            dispatch(updateClickupJLLProjectsList(response?.data))

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchClickupProjects();
    }, [JLL_SpaceId])

    return (
        <div ref={navbarRef} id="menu" className="">
            <div className="close-menu">
                <button
                    type="button"
                    className="menu-close"
                    onClick={() => setShowNavbar(false)}
                >
                    <img src={CloseIcon} />
                </button>
                <div className="site-logo">
                    <a href="#!">
                        <img src={Logo} />
                    </a>
                </div>
            </div>
            <div className="nav-search mt-4">
                <form onSubmit={handleSearch}>
                    <input
                        onChange={(e) => setSearchValue(e.target.value)}
                        type="text"
                        name="searchproject"
                        placeholder="Search for projects"
                        className="form-control"
                    />
                    <button>
                        <img src={SearchIcon} alt="Search" />
                    </button>
                </form>
            </div>
            <div className="nav-link mt-4">
                <ul className="m-0 list-unstyled">
                    {currentUserRole != "client" ? (
                        <li>
                            <div
                                onClick={() => {
                                    if (hasClient) {
                                        paymentDetails?.project_quantity > projectListLength
                                            ? setOpenAddProjectModal(true)
                                            : toastr.error("Maximum project limit reached! Update your Plan in Subscription & Billing");
                                    } else {
                                        toastr.error("No client found. Please add a client first.");
                                    }
                                }}
                                // onClick={() => paymentDetails?.project_quantity > projectListLength ? setOpenAddProjectModal(true) : toastr.error("Maximum project limit reached! Update your Plan in Subscription & Billing")}
                                className="child-box"
                            >
                                <a>Add Project</a>
                                <button type="button" className="add-pro">
                                    <img src={PlusIcon} />
                                </button>
                            </div>
                        </li>
                    ) : (
                        <li>
                            <div
                                onClick={() => handleNewProject()}
                                style={{ opacity: "0.75" }}
                                className="child-box"
                            >
                                <a>Add Project</a>
                                <button type="button" className="add-pro">
                                    <img src={PlusIcon} />
                                </button>
                            </div>
                        </li>
                    )}
                    {filteredData &&
                        filteredData?.map((val) => (
                            <li
                                className={`has-sub ${openClients[val.client_id] ? "open" : ""
                                    }`}
                            // className="has-sub"
                            >
                                <div
                                    onClick={() => handleActiveUser(val?.client_id)}
                                    className="nav-parent"
                                >
                                    <img src={val?.client_data.logo ? val?.client_data.logo : Avatar} />
                                    <a href="javascript:void(0)">{val?.client_name}</a>
                                </div>
                                {openClients[val.client_id] && (
                                    <ul className="list-unstyled nav-child">
                                        {val?.projects &&
                                            val.projects.map((data) => (
                                                <li key={data._id} className={isSelectedProject(data?._id) ? "padding-css selected-project" : "padding-css"} >
                                                    <div className="child-box">
                                                        <a
                                                            onClick={() => { handOpenProject([data]); handleLastEditedProject([data]) }}
                                                            href="#!"

                                                        >
                                                            {data?.project_name}
                                                        </a>
                                                        <NavbarActions
                                                            setOpenAddProjectModal={setOpenAddProjectModal}
                                                            setOpenEditProjectModal={setOpenEditProjectModal}
                                                            setOpenDeleteProjectModal={
                                                                setOpenDeleteProjectModal
                                                            }
                                                            projectList={data}
                                                        // onEdit={() => handleLastEditedProject(data)}
                                                        />
                                                    </div>
                                                </li>
                                            ))}
                                        <li>
                                            <div
                                                onClick={() => paymentDetails?.project_quantity > projectListLength ? setOpenAddProjectModal(true) : toastr.error("Maximum project limit reached! Update your Plan in Subscription & Billing")}
                                                className="child-box"
                                            >
                                                <a href="#!">Add Project</a>
                                                <button type="button" className="add-pro">
                                                    <img src={PlusIcon} />
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        ))}
                </ul>
            </div>
            <AddnewProject
                setShowNavbar={setShowNavbar}
                clickUpProjectListUpdate={clickUpProjectListUpdate}
                isOpen={openAddProjectModal}
                onClose={handleClose}
            />
            <EditProjectModal isOpen={openEditProjectModal} onClose={handleClose} clickUpProjectListUpdate={clickUpProjectListUpdate} />
            <DeleteProject
                isOpenModal={openDeleteProjectModal}
                onCloseModal={handleClose}
                clickUpProjectListUpdate={clickUpProjectListUpdate}
            />
        </div>
    );
};

export default Navbar;
