import React, { useEffect, useState } from 'react'
import './chats.css'
import './tickets.css'
import ChatSection from './chatsSection'
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updatechatsPopup, updateupcomingHeadline } from '../../redux/viewmore';
import TicketsSection from './ticketsSection';

const Chats = ({flag, setChatsandTicketsListProps}) => {
    const [activeTab, setActiveTab] = useState('chats');
    const upcomingPopup = useSelector(state => state.viewmore)
    const usersList = useSelector(state => state.allUserData.users)
    const projects = useSelector(state => state.project?.showProjectList) || []
    const dispatch = useDispatch()

    const [openNewTicketModal, setOpenNewTicketModal] = useState(false)
    const [openEditTicketModal, setOpenEditTicketModal] = useState(false)
    const handleClose = () => {
        setOpenNewTicketModal(false)
        setOpenEditTicketModal(false)

    }   
    
    useEffect(() => {
        if (openNewTicketModal) {
            setChatsandTicketsListProps({
                ModalName: 'AddNewTickets',
                isOpen: openNewTicketModal,
                onClose: handleClose
            })
        
        } else if (openEditTicketModal) {
            setChatsandTicketsListProps({
                ModalName: 'EditTickets',
                isOpen: openEditTicketModal,
                onClose: handleClose
            })
        
        } else {
            setChatsandTicketsListProps(null)
        }

    }, [openNewTicketModal, openEditTicketModal])

    return (
        // <div>
        <section className="chat-and-tickets mb-4">
            <div
            // className="container-fluid"
            >
                <div className={flag ? "chat-tickets-inner-viewmore bg-white shadow" : "bg-white shadow chat-tickets-inner"}>
                    <div className="chat-tab d-flex align-items-center justify-content-between"> 
                        <ul className="nav" id="pills-tab" role="tablist">
                            <li className={`nav-item ${activeTab === 'chats' ? 'active' : ''}`} role="presentation">
                                 <button className={`nav-link ${activeTab === 'chats' ? 'active' : ''}`} onClick={() => {setActiveTab('chats'); dispatch(updateupcomingHeadline("Chats"))}} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Chats
                                </button>
                            </li>
                            <li className={`nav-item ${activeTab === 'tickets' ? 'active' : ''}`} role="presentation">
                                   <button className="nav-link" onClick={() => {setActiveTab('tickets'); dispatch(updateupcomingHeadline("Tickets"))}} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Tickets
                                    </button>
                                </li>
                        </ul>


                        <div className="d-flex align-items-center chat-btn">
                           {!flag && <> {window.screen.width > 760 && <button type="button" onClick={() => {
                                dispatch(updatechatsPopup(true));
                                dispatch(updateupcomingHeadline("Chats"));
                            }} className="add-icon" ><img src={ExpandIcon} /></button>}

                            <button type="button" className="no-default drag-me"><img src={DragIcon} /></button></>}
                        </div>

                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div className={`tab-pane fade ${activeTab === 'chats' ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                            <ChatSection flag={flag} />
                        </div>
                        <div className={`tab-pane fade ${activeTab === 'tickets' ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                            <TicketsSection flag={flag} setOpenNewTicketModal={setOpenNewTicketModal} setOpenEditTicketModal={setOpenEditTicketModal} />
                        </div>
                    </div>
                </div>
                {window.screen.width > 760 && !flag && <button type="button" onClick={() => {
                    dispatch(updatechatsPopup(true));
                    dispatch(updateupcomingHeadline("Chats"));
                }} class="view-more">View More</button>}
            </div>
        </section >
        // </div>
    )
}

export default Chats
