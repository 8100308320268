import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import Trash from "../../assests/images/trash.svg";
import AddIcon from "../../assests/images/add.svg";
import Select from "react-select";
import apiServiceHandler from "../../service/apiService";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import project, {
  updateEditData,
  updateLastSelectedProjectId,
  updateRefreshProjectList,
} from "../../redux/project";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditProjectModal = ({ isOpen, onClose, clickUpProjectListUpdate }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState([]);
  const user = JSON.parse(localStorage.getItem('userData'));
  const projectEditData = useSelector((state) => state.project.editData) || [];
  const usersList = useSelector((state) => state.allUserData.users);
  const projectListMap = useSelector((state) => state.clickup.clickupJLLProjectsList);
  const [handBasicData, setHandBasicData] = useState({});
  const [extendedTime, setExtendedTime] = useState("");
  const [timeType, setTimeType] = useState("Hours");
  const [extendedDescription, setExtendedDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const projectsData = useSelector((state) => state.project.projectsData);
  const [milestones, setMilestones] = useState([
    {
      milestone_name: "",
      milestone_end_date: "",
      milestone_description: "",
      milestone_status: false,
      milestone_weightage: 0,
      milestone_scope: false,
      status: "",
      selected_milestone: ""
    },
  ]);


  useEffect(() => {
    toastr.options.preventDuplicates = true;
  }, [])

  const [disableButton, setDisableButton] = useState(false)

  const checkMilestoneWeightage = (total_weightage, updatedValue) => {
    if (total_weightage > 100) {
      toastr.error("Total Weightage Cannot be more than 100")
      setDisableButton(true)
    } else {
      setDisableButton(false)
      return updatedValue
    }
  }

  const handleMilestoneChange = (index, name, value) => {

    setMilestones((prevMilestones) => {
      const updatedMilestones = [...prevMilestones];

      const updatedValue = value.target ? value.target.value : value;

      let total_weightage = 0;
      updatedMilestones.map((milestone, idx) => {
        total_weightage = Number(total_weightage) + (index == idx ? Number(updatedValue) : Number(milestone.milestone_weightage));
      })

      updatedMilestones[index] = {
        ...updatedMilestones[index],
        [name]: name == "milestone_scope" ? value.target.checked : name == "milestone_weightage" ? checkMilestoneWeightage(total_weightage, updatedValue) : updatedValue,
        // milestone_weightage: checkMilestoneWeightage(total_weightage, updatedValue)
      };
      return updatedMilestones;
    });
  };

  const addMilestone = () => {
    setMilestones([
      ...milestones,
      {
        milestone_name: "",
        milestone_end_date: "",
        milestone_description: "",
        milestone_status: false,
        milestone_weightage: 0,
        milestone_scope: false,
        status: "",
        selected_milestone: ""
      },
    ]);
  };

  const deleteMilestone = (index) => {
    const updatedMilestones = [...milestones];
    updatedMilestones.splice(index, 1);
    setMilestones(updatedMilestones);
  };

  let data;

  useEffect(() => {
    if (projectEditData?.milestone) {
      data = projectEditData.milestone[0];
      data = data ? JSON.parse(data) : [];
      setMilestones(data);
    }
    if (projectEditData?.status) {
      setStatus({
        label: projectEditData?.status,
        value: projectEditData?.status,
      });
    }
    if (projectEditData?.extended_time) {
      let data = projectEditData?.extended_time[0];
      data = data ? JSON.parse(data)[0] : [];
      setTimeType(data?.timeType || "");
      setExtendedDescription(data?.extendedDescription);
      setExtendedTime(data?.extendedTime || "");
    }
    if (projectEditData) {
      // Create a copy of projectEditData
      const allData = { ...projectEditData };
      let formattedDate1 = "";
      if (projectEditData.start_date) {
        const start_date = new Date(projectEditData.start_date);
        formattedDate1 = start_date
          ? start_date?.toISOString().split("T")[0]
          : "";
      }
      let formattedDate2 = "";
      if (projectEditData.end_date) {
        const end_date = new Date(projectEditData.end_date);
        formattedDate2 = end_date ? end_date?.toISOString().split("T")[0] : "";
      }

      allData.start_date = formattedDate1;
      allData.end_date = formattedDate2;

      // Set the modified copy in your state
      setHandBasicData(allData);
    }
  }, [projectEditData]);

  const handleInputValue = (event) => {
    const { name, value } = event.target;
    setHandBasicData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const transformData = (data) => {
    return data.map((item) => ({
      ...item,
      value: item._id,
      label: `${item.first_name} ${item.last_name} (${item.role})`,
    }));
  };
  const options = transformData(usersList) || [];

  useEffect(() => {
    let data = [];
    let existingUser = projectEditData?.project_user || [];
    let existingData = [];
    options &&
      options.map((val) => {
        if (existingUser.includes(val._id)) {
          existingData.push(val);
        }
      });
    setSelectedOptions(existingData);
  }, [projectEditData]);

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const separateByRole = (data) => {
    const clientArray = [];
    const otherArray = [];

    data.forEach((item) => {
      if (item.role === "client") {
        clientArray.push(item._id);
        otherArray.push(item._id);
      } else {
        otherArray.push(item._id);
      }
    });

    return {
      client_id: clientArray,
      project_user: otherArray,
    };
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setSelectedImage(file);
  };

  //status
  const [allOptions, setAllOptions] = useState([]);
  const fetchStatus = async () => {
    try {
      const response = await apiServiceHandler("GET", "api/project/status", {});
      let data = response?.data || [];
      let list = [];
      Object.values(data[0]).map((value, i) =>
        list.push({ value: value, label: value })
      );
      setAllOptions(list);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeStatus = (selectedValues) => {
    setStatus(selectedValues);
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  async function isMilestoneWeightageZero(milestones) {
    for (let milestone of milestones) {
      if (milestone.milestone_weightage == 0) {
        return false;
      }
    }
    return true;
  }

  const editProject = async (e) => {
    e.preventDefault();
    let extended_time = [
      {
        extendedTime: extendedTime,
        timeType: timeType,
        extendedDescription: extendedDescription,
      },
    ];

    let arr = [];
    milestones?.map((milestone) => {
      if (milestone.milestone_status) {
        arr.push(milestone);
      }
    });

    const separatedData = separateByRole(selectedOptions);
    let payload = new FormData();
    separatedData?.client_id &&
      separatedData?.client_id.map((val) => {
        payload.append("client_id[]", val);
      });
    separatedData?.project_user &&
      separatedData?.project_user.map((val) => {
        payload.append("project_user[]", val);
      });
    payload.append("project_name", handBasicData?.project_name);
    payload.append("start_date", handBasicData?.start_date);
    payload.append("folder_id", projectListMap?.[projectEditData?.project_name] || null);
    payload.append("end_date", handBasicData?.end_date);
    payload.append("project_url", "");
    payload.append("milestone", JSON.stringify(milestones));
    if (user.access_to_apis.access_to_change_project_logo) {
      selectedImage && payload.append("logo", selectedImage);
    }
    payload.append("status", status?.value);
    payload.append("extended_time", JSON.stringify(extended_time));
    payload.append("id", projectEditData._id);
    payload.append(
      "progress",
      arr.reduce((acc, data) => acc + Number(data.milestone_weightage), 0)
    );

    const isZero = await isMilestoneWeightageZero(milestones)
    if (isZero) {
      try {
        const response = await apiServiceHandler(
          "POST",
          "api/project/update",
          payload
        );
        toastr.success(response?.message);
        dispatch(updateRefreshProjectList(true));
        onClose();
        clickUpProjectListUpdate()
        dispatch(updateLastSelectedProjectId(projectEditData._id));

      } catch (err) {
        toastr.error(err?.response?.data?.message);
        console.log(err);
      }
    } else {
      toastr.error("Add Milestone Weightage");
    }
  };

  return (
    <div
      className={`modal fade edit-project-poup ${isOpen ? "show backdrop" : " "
        }`}
      id="editProjectModal"
      tabindex="-1"
      aria-labelledby="editProjectModalLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0">
          <div className="modal-header new-task-header">
            <h2 className="modal-title">Edit Project</h2>
            <div className="close-right">
              <div className="status-div">
                <div className="status-label">Status:</div>
                <Select
                  options={allOptions}
                  // isMulti
                  onChange={handleChangeStatus}
                  value={status}
                />
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <img src={CloseIcon} />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <form onSubmit={editProject}>
              <div className="row">
                <div className="col-md-12 mb-2 write-project-name">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add Project Name*"
                    name="project_name"
                    value={handBasicData?.project_name || ""}
                    onChange={handleInputValue}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label>Start Date*</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Starting date"
                    name="start_date"
                    value={handBasicData?.start_date || ""}
                    onChange={handleInputValue}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label>End Date<span style={{ fontSize: '10px', padding: '0px', margin: '0px' }}> (optional)</span></label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="End Date"
                    name="end_date"
                    value={handBasicData?.end_date || ""}
                    onChange={handleInputValue}

                  />
                </div>
                <div className="col-md-12 mb-2">
                  <div className="milestone-data">
                    <div className="add-milestone">
                      <label>Enter Milestone for Progress Tracking</label>
                    </div>
                    {milestones.map((milestone, index) => (
                      <div className="milestone-loop" key={index}>
                        <div className="milestone-head mb-2">
                          <input
                            type="text"
                            className="form-control milestone-input"
                            placeholder={`Milestone ${index + 1}`}
                            name="milestone_name"
                            value={milestone.milestone_name}
                            onChange={(e) =>
                              handleMilestoneChange(index, e.target.name, e)
                            }
                            required
                          />
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => deleteMilestone(index)}
                          >
                            <img src={Trash} />
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-2">
                            <div className="col-md-12 mb-2">
                              <label>Enter Ending Date*</label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Ending date"
                                name="milestone_end_date"
                                value={milestone.milestone_end_date}
                                onChange={(e) =>
                                  handleMilestoneChange(index, e.target.name, e)
                                }
                                required
                              />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div className="col-md-7 mb-2">
                                <label style={{ padding: '0px', margin: '0px' }}>Milestone Weightage*</label>
                                <span style={{ fontSize: '10px', padding: '0px', margin: '0px' }}>(Number between 1-100)</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="milestone weightage"
                                  name="milestone_weightage"
                                  min={1}
                                  max={100}
                                  value={milestone?.milestone_weightage}
                                  onChange={(e) =>
                                    handleMilestoneChange(
                                      index,
                                      e.target.name,
                                      e
                                    )
                                  }
                                  required
                                />
                              </div>
                              <div
                                className="col-md-6 mb-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="milestone_scope"
                                  name="milestone_scope"
                                  value={milestone?.milestone_scope}
                                  checked={
                                    milestone?.milestone_scope
                                  }
                                  autoComplete="off"
                                  onChange={(e) =>
                                    handleMilestoneChange(
                                      index,
                                      e.target.name,
                                      e
                                    )
                                  }
                                />
                                <label
                                  for="milestone_scope"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Out of Scope
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-2">
                            <label>Add Description</label>
                            <ReactQuill
                              value={milestone.milestone_description}
                              onChange={(value) =>
                                handleMilestoneChange(
                                  index,
                                  "milestone_description",
                                  value
                                )
                              }
                              placeholder="Add Description"
                            />
                          </div>
                          <hr />
                        </div>
                      </div>
                    ))}
                    <div className="add-more-mile">
                      <button type="button" onClick={addMilestone}>
                        <img src={AddIcon} /> Add Another Milestone
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <label className="add-project-label">Add Project Users*</label>
                  <Select
                    options={options}
                    isMulti
                    onChange={handleChange}
                    value={selectedOptions}
                    required
                  />
                </div>
                {user && user.access_to_apis.access_to_change_project_logo &&
                  <div className="col-md-12">
                    <label>Upload Logo</label>
                    {selectedImage &&
                      <div className="attachment-project">
                        <div className="attachment-project-image">
                          <img src={URL.createObjectURL(selectedImage)} />
                        </div>
                      </div>}
                    <label for="editProjectFile" style={{ cursor: 'pointer' }} className="upload-file-project text-center">
                      <div>

                        {/* <input type='file' htmlFor="projectFile" onChange={(e) => handleImageChange(e)} /> */}
                        <input
                          onChange={handleImageChange}
                          className="box-file-hidded"
                          type="file"
                          id="editProjectFile"
                          accept="image/*" // Specify that only image files are allowed
                        />

                        <div className="upload-txt-project">
                          Drag file here or{" "}<br />
                          <span style={{ fontSize: '16px', fontFamily: 500 }} >
                            upload from your computer
                          </span>
                        </div>
                        <p className="mb-0">JPG, PNG, PDF up to 5MB</p>
                        {selectedImage && selectedImage[0] && (
                          <div>
                            <p>Selected File: {selectedImage[0].name}</p>
                            <img
                              src={URL.createObjectURL(selectedImage[0])}
                              alt="Selected File"
                            />
                          </div>
                        )}
                      </div>
                    </label>
                  </div>}
              </div>
              <div className="modal-footer justify-content-center mt-3">
                <button type="submit" className="bg-btn" disabled={disableButton} style={{ background: disableButton ? '#BECDFF' : '#57A5FB' }}>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProjectModal;
