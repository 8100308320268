import React, { useEffect, useState } from 'react'
import './clickup.css';
import PlusIcon from '../../assests/icons/plusg.svg';
import Select from "react-select";
import EditIcon from '../../assests/images/edit.svg';
import Filter from '../../assests/images/filter.svg';
// import TickIcon from '../../assests/images/GreenTick.svg';
import SearchIcon from '../../assests/images/search.svg';
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditTaskData, updateRefreshTaskList } from '../../redux/task';
import { updateClickupTasksListPopup, updatetaskAssignedPopup, updateupcomingHeadline } from '../../redux/viewmore';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from '../../service/apiService';

const ClickupTasks = ({ setClickupTasksListProps, flag }) => {
    const [clickupTasksList, setClickupTasksList] = useState([])
    const [searchValue, setSetsearchValue] = useState("")
    const myTaskList = useSelector(state => state.task.TasksList?.assignedToMe)
    const projects = useSelector(state => state.project?.showProjectList) || []
    const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
    const [openEditTaskModal, setOpenEditTaskModal] = useState(false);
    const [filteredData, setFilteredData] = useState([])

    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    const dispatch = useDispatch()

    let ClickupSpaceIds = localStorage.getItem("Clickup_SpaceIds")
    ClickupSpaceIds = ClickupSpaceIds ? JSON.parse(ClickupSpaceIds) : {}

    const [selectedSpaceValue, setSelectedSpaceValue] = useState("")

    const handleChangeStatus = (selectedValues) => {
        setSelectedSpaceValue(selectedValues);

    };

    const transformData = (data) => {
        return Object.keys(data).map((item) => ({
            ...item,
            value: item,
            label: item
        }));
    }
    const userOptions = transformData(ClickupSpaceIds) || [];

    const getFormattedDate = (timestamp) => {
        // Use Math.floor and typecast to number to ensure integer timestamp
        const timestampNumber = Math.floor(Number(timestamp));

        // Create a Date object from the timestamp
        const date = new Date(timestampNumber);

        // Use Date object's built-in formatting methods and template literals for readability
        return `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })} ${date.getFullYear()}`;
    }

    const getClickupTasks = async () => {

        let payload = {

            "spaceId": ClickupSpaceIds[selectedSpaceValue["label"]],
            "uniqueUrl": userData?.unique_url

        }
        // dispatch(updateloaderStatus(true))
        try {
            const response = await apiServiceHandler("POST", `api/tasks/get_task_list_clickup`, payload);
            console.log(response)
            setClickupTasksList(response?.tasks)


        } catch (err) {

            // toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }

    useEffect(() => {
        if (selectedSpaceValue) {
            getClickupTasks();
        }

    }, [selectedSpaceValue])

    const handleNewTaskButton = () => {

        if (projects.length > 0) {
            setOpenNewTaskModal(true)
        } else {
            toastr.error("No Project Found")
        }

    }

    useEffect(() => {
        if (openNewTaskModal) {
            setClickupTasksListProps({
                ModalName: 'AddNewTask',
                isOpen: openNewTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } else if (openEditTaskModal) {
            setClickupTasksListProps({
                ModalName: 'EditTask',
                isOpen: openEditTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } else {
            setClickupTasksListProps(null)
        }
    }, [openNewTaskModal, openEditTaskModal, projects[0]])

    const handleEditTask = (value) => {
        setOpenEditTaskModal(true)
        dispatch(updateEditTaskData(value))
    }
    const handleUpdateTask = async (value, status) => {
        let payload = new FormData();
        payload.append('id', value?._id);
        payload.append('status', status);
        await apiServiceHandler("POST", "api/task/update", payload)
        dispatch(updateRefreshTaskList(true))
    }

    const handleClose = () => {
        setOpenNewTaskModal(false)
        setOpenEditTaskModal(false)
    }

    // useEffect(() => {
    //     if (taskListClickup) {
    //         // const newTaksList = taskListClickup.filter((val) => val.status != 'Approved' && val.status != 'Rejected' && val.task_status?.[0] != "appointment")
    //         // setFilteredData(newTaksList)
    //     }
    // }, [taskListClickup])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: window.screen.width < 760 ? 220 : 320,
            marginLeft: "12px"
        }),
    };

    return (
        <div className='current-working-task-section'>
            <section className="current-working-task mb-4">
                <div
                // className="container-fluid"
                >
                    <div className={flag ? "shadow working-inner-viewmore" : "shadow working-inner"}>
                        <div className="working-task">
                            <div className={flag ? "current-search-bar" : "current-search-bar viewline"}>
                                {!flag && <h2>Clickup Task</h2>}
                                <div className="search-group d-flex align-items-center">
                                    <form >
                                        <input onChange={(e) => setSetsearchValue(e.target.value)} type="text" name="findtask" placeholder="Find Task..." className="form-control" />
                                        <button type="submit"><img src={SearchIcon} /></button>
                                    </form>
                                    <Select
                                        className="btn-color ml-3"
                                        options={userOptions}
                                        name='users'
                                        styles={customStyles}
                                        value={selectedSpaceValue}
                                        onChange={handleChangeStatus}
                                    />
                                    <div className="d-flex align-items-center task-btn">
                                        {!flag && <>
                                            <button type="button" onClick={() => {
                                                dispatch(updateClickupTasksListPopup(true));
                                                dispatch(updateupcomingHeadline("Clickup Tasks"))
                                            }} className="add-icon"><img src={ExpandIcon} /></button>
                                            <button type="button" className="drag-me"><img src={DragIcon} /></button></>}
                                    </div>
                                </div>
                            </div>
                            <div className="current-tasks-col-two">
                                <div className="current-tasks-label-two d-none d-lg-block mb-3">
                                    <div className="row g-0">
                                        <div class="col-lg-1">
                                            <h3 class="hash-no">#</h3>
                                        </div>
                                        <div className="col-lg-3">
                                            <h3>Task Name</h3>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            <h3>Date Created</h3>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            <h3>Status</h3>
                                        </div>
                                        <div className="col-lg-3 text-lg-center">
                                            <h3>Project</h3>
                                        </div>


                                        <div className="col-lg-1 text-lg-center">
                                            <h3>Action</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className={flag ? "current-tasks-data-two-viewmore" : "current-tasks-data-two clickuptask-data"}>
                                    {clickupTasksList && clickupTasksList.map((val, index) => (
                                        <div className="row task-row g-0">
                                            <div class="col-lg-1">
                                                {/* <label class="mb-0 hash-no">#</label> */}
                                                <p class="mb-0 hash-no">{index + 1}</p>
                                            </div>
                                            <div className="col-lg-3">
                                                {/* <label className="mb-0">Task Name</label> */}
                                                <p className="mb-0">{val?.name}</p>
                                            </div>
                                            <div className="col-lg-2 text-lg-center">
                                                {/* <label className="mb-0">Due Date</label> */}
                                                <p className="mb-0">{getFormattedDate(val?.date_created)}</p>
                                            </div>
                                            <div className="col-lg-2 text-lg-center">
                                                {/* <label className="mb-0">Task Progress</label> */}
                                                <div className="task-progress-label mb-0">
                                                    {val?.status.status}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 text-lg-center">
                                                {/* <label className="mb-0">Project</label> */}
                                                <p className="mb-0">{val?.project.name}</p>
                                            </div>


                                            <div className="col-lg-1 text-lg-center">
                                                {/* <label className="mb-0">Action</label> */}
                                                <button type="button" className="edit-task-action"
                                                // onClick={() => handleEditTask(val)}
                                                ><img src={EditIcon} /></button>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                    {!flag && <button type="button" onClick={() => {
                        dispatch(updateClickupTasksListPopup(true));
                        dispatch(updateupcomingHeadline("Clickup Tasks"))
                    }} class="view-more">View More</button>}
                </div>
            </section>
        </div>




    )
}

export default ClickupTasks;
