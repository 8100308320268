import React from 'react'
import Logo from '../../assests/images/LogoSouthBisonConnect.svg'
import { useLocation } from 'react-router'
import './payment.css'
import PayPalSubscriptionButton from '../../payment/PayPalSubscriptionButton'

const Payment = () => {

  const { state } = useLocation();
  const { plan, price, planId, userQuantity, projectQuantity, storageQuantity } = state

  return (
    <div className="bg-image">
      <div className="bg-box">
        <div className="logo text-center mb-4">
          <img src={Logo} />
        </div>
        <div className="subscription-area p-3">
          <h2 className="mb-3 text-center">Subscription Billing</h2>
          <div className="plan-details row mb-3">
            <div className="col-8">
              <h3 className="mb-2">Plan Name</h3>
            </div>
            <div className="col-4 text-end">
              <h3 className="mb-2">Total</h3>
            </div>
            <div className="col-8">
              <h4 className="mb-0">{plan}</h4>
            </div>
            <div className="col-4 text-end">
              <h4 className="mb-0">${price}</h4>
            </div>
          </div>
          <div className="plan-desc mb-3">
            <h3>Description</h3>
            <ul>
              <li>Access for {userQuantity} user</li>
              <li>Management of {projectQuantity} project</li>
              <li>Storage capacity of {storageQuantity} GB</li>
              <li>Complimentary project reports</li>
            </ul>
          </div>
          <hr />
          <div className="plan-total mt-1 text-end">
            <strong>Total:</strong> ${price}.00
          </div>
          <div className="payment-btn text-center mt-3">
            <PayPalSubscriptionButton name={{
              plan, price, planId, userQuantity, projectQuantity, storageQuantity
            }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
