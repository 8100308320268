import React, { useEffect, useState } from 'react';
import "./invoice.css";
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshInvoiceList, updateSelectedInvoiceForActions, updateopenInvoicepopup, updatopenPayPalsetup } from '../../redux/invoice';
import apiServiceHandler from '../../service/apiService';
import PlusIcon from '../../assests/icons/plusg.svg';
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import Tick from '../../assests/images/tick.svg';
import TickIcon from "../../assests/images/tick-white.svg";
import CloseWIcon from "../../assests/images/close-white.svg";
import Setting from '../../assests/images/Setting.svg';
import { updateInvoiceListPopup, updateupcomingHeadline } from '../../redux/viewmore';
import Actions from './actionOptions';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { isEmpty } from '../../common/helper';
import EditIcon from '../../assests/images/edit.svg';

const InvoiceList = ({ setInvoiceListProps, flag }) => {
    const dispatch = useDispatch();
    const [sendAndRecievedList, setSendAndRecievedList] = useState([])
    const allUsers = useSelector(state => state.allUserData.users)
    const refreshList = useSelector(state => state.invoice.refreshInvoiceList)
    const userInfo = useSelector(state => state.userData.userInfo)
    const usersList = useSelector((state) => state.allUserData.users);
    const paypalSetupData = useSelector((state) => state.invoice.paypalSetupData);
    // const userIdToEmailMap = allUsers.map((user) => ({
    //     [user._id]: user.email
    // }));
    // const userIdToEmailMap = allUsers.reduce((acc, user) => {
    //     acc[user._id] = user.email;
    //     console.log(user, 'user is here')
    //     return acc;
    // }, {});
    const userIdToUsername = allUsers.reduce((acc, user) => {
        acc[user._id] = `${user.first_name} ${user.last_name}`;
        return acc;
    }, {});

    const UserNameById = (userId) => {
        let name = ''
        usersList.forEach(element => {
            if (element._id === userId) {
                name = element.first_name
            }
        });
        return name;
    }


    const handleOpenInvoice = () => {
        if (isEmpty(paypalSetupData)) {
            toastr.info('Please do paypal credentials setup')
            dispatch(updatopenPayPalsetup(true))
            return
        }
        dispatch(updateopenInvoicepopup(true))
    }

    const Lists = async () => {
        const payload = {
            _id: ""
        }
        try {
            const allList = await Promise.all([apiServiceHandler("GET", "api/invoice/sent", {}), apiServiceHandler("GET", "api/invoice/recieved", {})])
            const combinedData = [...allList[0]?.data, ...allList[1]?.data];
            const uniqueInvoices = combinedData.reduce((acc, current) => {
                const existing = acc.find(invoice => invoice._id === current._id);
                return existing ? acc : [...acc, current];
            }, []);
            setSendAndRecievedList(uniqueInvoices);
        } catch (err) {
            console.log("error", err)
        }
    }
    useEffect(() => {
        Lists()
    }, [])

    useEffect(() => {
        if (refreshList) {
            Lists()
            dispatch(updateRefreshInvoiceList(false))
        }
    }, [refreshList])

    const handleSave = async (val, status) => {

        const payload = {
            invoice_approval_status: status,
            invoice_approval_to: userInfo?._id,
            invoice_number: val?.invoice_number
        }

        try {
            let response = await apiServiceHandler("PUT", "api/invoice/approval", payload)
            toastr.success(response?.message)
            // dispatch(updatopenInvoiceApprovrFlag(false))
            dispatch(updateRefreshInvoiceList(true))
        } catch (error) {
            toastr.error(error?.message)
            console.log(error)
        }
    }

    const returnUserNameById = (id) => {
        return userIdToUsername[id] || ""
    }
    // const returnUserNameById = (id) => {
    //     return userIdToEmailMap[id] || ""
    // }

    const handleActions = (val) => {
        dispatch(updateSelectedInvoiceForActions(val))
    }

    const convertDDMMYYYY = (dateString) => {
        if (dateString === "Invalid Date") return ''
        const date = new Date(dateString);

        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = date.getFullYear();

        const formattedDate = dd + '-' + mm + '-' + yyyy;
        return formattedDate
    }

    return (

        <section class="invoice-section mb-4 w-100">
            <div
            // class="container-fluid"
            >
                <div class={flag ? "invoice-area-viewmore" : "invoice-area"}>
                    <div class="invoice-heading d-flex align-items-center justify-content-between">
                        <h2 class="mb-0 text-white">Invoice</h2>
                        <div class="d-flex align-items-center invoice-btn">
                            {!flag && <><button onClick={() => dispatch(updatopenPayPalsetup(true))} type="button" class="no-default" ><img src={Setting} /></button>
                                <button onClick={() => handleOpenInvoice()} type="button" class="no-default" data-bs-target="#newInvoiceModal"><img src={PlusIcon} /></button></>}
                            {!flag && <>{window.screen.width > 760 && <button type="button" onClick={() => {
                                dispatch(updateInvoiceListPopup(true));
                                dispatch(updateupcomingHeadline("Invoice"))
                            }} class="no-default"><img src={ExpandIcon} /></button>}
                                <button type="button" class="no-default drag-me"><img src={DragIcon} /></button></>}
                        </div>
                    </div>
                    <div class="invoice-col">
                        <div class="invoice-label d-none d-lg-block">
                            <div class="row g-0">
                                <div class="col-lg-1">
                                    <h3>Invoice No.</h3>
                                </div>
                                <div class="col-lg-2 text-lg-center">
                                    <h3>Name</h3>
                                </div>
                                <div class="col-lg-2 text-lg-center">
                                    <h3>Email</h3>
                                </div>
                                {/* <div class="col-lg-2 text-lg-center">
                                    <h3>Project</h3>
                                </div> */}
                                <div class="col-lg-1 text-lg-center">
                                    <h3>Amount</h3>
                                </div>
                                <div class="col-lg-1 text-lg-center">
                                    <h3>Due Date</h3>
                                </div>
                                <div class="col-lg-1 text-lg-center">
                                    <h3>Paid On</h3>
                                </div>
                                <div class="col-lg-1 text-lg-center">
                                    <h3>Invoice Status</h3>
                                </div>
                                <div class="col-lg-2 text-lg-center">
                                    <h3>Approval Status</h3>
                                </div>
                                <div class="col-lg-1 text-lg-center">
                                    <h3>Action</h3>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-data">
                            {sendAndRecievedList && sendAndRecievedList.map((val, index) => (
                                < div class="row row-mobile g-0" >
                                    <div class="col-lg-1">
                                        {/* <label class="mb-0">Invoice No.</label> */}
                                        {/* <p class="mb-0">23354</p> */}
                                        <p class="mb-0">{val?.invoice_number}</p>
                                    </div>
                                    <div class="col-lg-2 text-lg-center">
                                        {/* <label class="mb-0">Client</label> */}
                                        {/* <p class="mb-0">Mathew</p> */}
                                        <p class="mb-0">{returnUserNameById(val?.invoice_generated_to)}</p>
                                    </div>
                                    <div class="col-lg-2 text-lg-center">
                                        {/* <label class="mb-0">Client</label> */}
                                        {/* <p class="mb-0">Mathew</p> */}
                                        {/* <p class="mb-0">{returnUserNameById(val?.invoice_generated_to)}</p> */}
                                        <p class="mb-0">{val?.paypal_email_address}</p>
                                    </div>
                                    {/* <div class="col-lg-2 text-lg-center">
                                        <label class="mb-0">Project</label>
                                        <p class="mb-0">FFL</p>
                                    </div> */}
                                    <div class="col-lg-1 text-lg-center">
                                        {/* <label class="mb-0">Amount</label> */}
                                        <p class="mb-0">${val?.amount}</p>
                                    </div>
                                    < div class="col-lg-1 text-lg-center" >
                                        {/* <label class="mb-0">Due Date</label> */}
                                        <p p class="mb-0" > {convertDDMMYYYY(val?.due_date)}</p>
                                    </div>
                                    <div class="col-lg-1 text-lg-center">
                                        {/* <label class="mb-0">Paid On</label> */}
                                        <p class="mb-0">{val?.paid_on ? convertDDMMYYYY(val?.paid_on) : ''}</p>
                                    </div>
                                    <div class="col-lg-1 text-lg-center">
                                        {/* <label class="mb-0">Status</label> */}
                                        <div class="invoice-status">
                                            {window.screen.width > 760 ? val?.status !== "pending" && <img src={Tick} /> : val?.status !== "pending" && <img src={EditIcon} />}{val?.status?.replace(/\b\w/g, char => char.toUpperCase())}
                                        </div>
                                    </div>
                                    <div class="col-lg-2 text-lg-center">
                                        {/* <label class="mb-0">Status</label> */}
                                        {val?.invoice_approval_status ? '' : <p>NA</p>}
                                        {val?.invoice_approval_to === userInfo?._id && val?.invoice_approval_status === 'pending' &&
                                            <>
                                                {/* <p style={{ cursor: 'pointer' }}>
                                                    <span onClick={() => handleSave(val, 'Approved')}>Approve</span>{' / '}
                                                    <span onClick={() => handleSave(val, 'Rejected')}>Reject</span>
                                                </p> */}
                                                <div className="apt-confirm-invoice mb-2">
                                                    <button type="button" className="apt-yes" onClick={() => handleSave(val, 'Approved')} >
                                                        <img src={TickIcon} /> Approve
                                                    </button>
                                                    <button type="button" className="apt-no" onClick={() => handleSave(val, 'Rejected')}>
                                                        <img src={CloseWIcon} /> Reject
                                                    </button>
                                                </div>
                                            </>
                                        }
                                        {val?.invoice_approval_to === userInfo?._id && val?.invoice_approval_status !== 'pending' &&
                                            <>
                                                <p>{val?.invoice_approval_status} by you</p>
                                            </>
                                        }
                                        {val?.invoice_approval_to && val?.invoice_approval_to !== userInfo?._id && val?.invoice_approval_status === 'pending' &&
                                            <>
                                                <p>Approval awaiting from {UserNameById(val?.invoice_approval_to)}</p>
                                            </>
                                        }
                                        {val?.invoice_approval_to && val?.invoice_approval_to !== userInfo?._id && val?.invoice_approval_status !== 'pending' &&
                                            <>
                                                <p>{val?.invoice_approval_status} by {UserNameById(val?.invoice_approval_to)}</p>
                                            </>
                                        }
                                    </div>
                                    <div onClick={() => handleActions(val)} class="col-lg-1 text-lg-center">
                                        <Actions />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div >
                {
                    window.screen.width > 760 && !flag && <button type="button" onClick={() => {
                        dispatch(updateInvoiceListPopup(true));
                        dispatch(updateupcomingHeadline("Invoice"))
                    }} class="view-more">View More</button>
                }            </div >
        </section >

        // <div>
        //     <section class="invoice-section mb-4">
        //         <div class="container-fluid">
        //             <div class="invoice-area">
        //                 <div class="invoice-heading d-flex align-items-center justify-content-between">
        //                     <h2 class="text-white mb-0">Invoice</h2>
        //                     <div className="d-flex align-items-center task-btn">
        //                         <button onClick={() => dispatch(updatopenPayPalsetup(true))} type="button" class="invoice-add-icon"><img src={PlusIcon} /></button>
        //                         <button onClick={() => handleOpenInvoice()} type="button" class="invoice-add-icon"><img src={PlusIcon} /></button>
        //                         <button type="button" class="invoice-add-icon"><img src={DragIcon} /></button>
        //                     </div>
        //                 </div>
        //                 <div class="invoice-col">
        //                     <div class="invoice-label d-none d-lg-block">
        //                         <div class="row">
        //                             <div class="col-lg-2">
        //                                 <h3>Invoice No.</h3>
        //                             </div>
        //                             <div class="col-lg-2 text-lg-center">
        //                                 <h3>Client</h3>
        //                             </div>
        //                             <div class="col-lg-2 text-lg-center">
        //                                 <h3>Project</h3>
        //                             </div>
        //                             <div class="col-lg-1 text-lg-center">
        //                                 <h3>Paid On</h3>
        //                             </div>
        //                             <div class="col-lg-2 text-lg-center">
        //                                 <h3>Due Date</h3>
        //                             </div>
        //                             <div class="col-lg-2 text-lg-center">
        //                                 <h3>Amount</h3>
        //                             </div>
        //                             <div class="col-lg-1 text-lg-center">
        //                                 <h3>Status</h3>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div class="invoice-data">
        //                         {sendAndRecievedList && sendAndRecievedList.map((val, index) => (
        //                             <div class="row">



        //                                 <div class="col-lg-2">
        //                                     <label class="mb-0">Invoice No.</label>
        //                                     <p class="mb-0">{val?.invoice_number}</p>
        //                                 </div>
        //                                 <div class="col-lg-2 text-lg-center">
        //                                     <label class="mb-0">Client</label>
        //                                     <p class="mb-0">Thomas Lee</p>
        //                                 </div>
        //                                 <div class="col-lg-2 text-lg-center">
        //                                     <label class="mb-0">Project</label>
        //                                     <p class="mb-0">FFL</p>
        //                                 </div>
        //                                 <div class="col-lg-1 text-lg-center">
        //                                     <label class="mb-0">Paid On</label>
        //                                     <p class="mb-0">Jan 21, 2022</p>
        //                                 </div>
        //                                 <div class="col-lg-2 text-lg-center">
        //                                     <label class="mb-0">Due Date</label>
        //                                     <p class="mb-0">Jan 30, 2022</p>
        //                                 </div>
        //                                 <div class="col-lg-2 text-lg-center">
        //                                     <label class="mb-0">Amount</label>
        //                                     <p class="mb-0">$1500.00</p>
        //                                 </div>
        //                                 <div class="col-lg-1 text-lg-center">
        //                                     <label class="mb-0">Status</label>
        //                                     <div class="invoice-status"><img src={Tick} />{val?.status}</div>
        //                                 </div>
        //                             </div>
        //                         ))}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        // </div>
    )
}
export default InvoiceList;