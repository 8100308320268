import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import Project1 from '../../assests/images/project-1.png';
import { useDispatch, useSelector } from 'react-redux';
import apiServiceHandler from '../../service/apiService';
import { updateEditTaskData, updateRefreshTaskList } from '../../redux/task';
import NoImage from '../../assests/images/NoImage.jpg';

const ApprovalTaskDetails = ({isOpen, onClose, flag, onEditTask }) => {
    const myTaskData = useSelector(state => state.task.editData)
    const [taskDetails, setTaskDetails] = useState()
    const [openEditTaskDetails, setOpenEditTaskDetails] = useState(false)
    const [approveStatus, setApproveStatus] = useState([])
    const dispatch = useDispatch()
    const projects = useSelector(state => state.project?.showProjectList) || []

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };
   
    const calculateDaysRemaining = (date) => {
        const currentDate = new Date();
        const examDate = new Date(date); // Replace with your desired exam date
        const timeDifference = Math.abs(examDate - currentDate);
        const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        return daysRemaining
    };
    const updateTaskDetails = async (e, value) => {
        e.preventDefault()
        
        let payload = new FormData();
        payload.append('id', myTaskData?._id);
        payload.append('status', value !== undefined ? value :  approveStatus?.value);
        try {
            const response = await apiServiceHandler("POST", "api/task/update", payload)
            console.log(response)
            dispatch(updateRefreshTaskList(true))
            onClose()
        } catch (err) {
            // toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }

    const statusCompletion = (e) => {
        setApproveStatus({ value: 'Approved', label: 'Approved' })
        updateTaskDetails(e, "Approved")
    }
    const handleReject = (e) => {
        setApproveStatus({ value: 'Rejected', label: 'Rejected' })
        updateTaskDetails(e, "Rejected")
    }

    const handleEditTask = (value) => {
        // setOpenEditTaskDetails(true)
        onEditTask()
        dispatch(updateEditTaskData(value))
    }

    const handleClose = () => {
        setOpenEditTaskDetails(false)
    }

    //  useEffect(() => {
    //     if (openEditTaskDetails) {
    //         setTasksListProps({
    //             ModalName: 'EditTask',
    //             isOpen: openEditTaskDetails,
    //             onClose: handleClose,
    //             selectedProject: projects[0]?.project_name,
    //             clientName: projects[0]?.client_names[0]
    //         })
    //     } 
    //     else {
    //         setTasksListProps(null)
    //     }
    // }, [ openEditTaskDetails, projects[0]])

    return (
        <div 
            className={`modal fade approval-modal details-viewmore ${isOpen ? "show backdrop" : " "}`}
            id="approvalModal" tabindex="-1"
            data-bs-backdrop="static" 
            aria-labelledby="approvalModalLabel" 
            style={{ display: isOpen ? "block" : "none" }}
            aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" data-bs-backdrop="static">
                <div className="modal-content border-0">
                    <div className="modal-header mobile-view">
                        <h2 className="modal-title">Create Packages</h2>
                        <div className="approval-modal-btn d-flex align-items-center">
                        {myTaskData.status !== "Rejected" &&  <button type="button" className="mark-reject me-3" onClick={handleReject}>Reject</button>}
                           {myTaskData.status !== "Approved" &&  <button type="button" className="mark-approve me-3" onClick={statusCompletion}>Approve</button>}
                           <button type="button" className="show-details me-3" onClick={() => handleEditTask(myTaskData)}>Details</button>
                            <button type="button" onClick={onClose} className="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close">
                                <img src={CloseIcon}/>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body bg-white">
                        <div className="approval-slides">
                            <div id="carouselApproval" className="carousel slide">
                                {/* <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselApproval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselApproval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselApproval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div> */}
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={myTaskData?.supported_docs.length > 0 ? myTaskData?.supported_docs : NoImage} className="d-block w-100" alt="Preview" />
                                    </div>
                                </div>
                            </div>

                            <div className="approval-popup-info">
                                <ul className="list-unstyled m-0">
                                    <li><span>Task:</span>{myTaskData?.task_name}</li>
                                    <li><span>Project:</span>{myTaskData?.project_name}</li>
                                    <li><span>Due Date:</span>{getFormattedDate(myTaskData?.due_date)}</li>
                                    <li><span>Remaining Days:</span>{calculateDaysRemaining(myTaskData?.due_date)} Days</li>
                                    <li><span>Status:</span>{myTaskData?.status}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalTaskDetails
