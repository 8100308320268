import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import apiServiceHandler from '../service/apiService';
import { useNavigate } from 'react-router-dom';

const PayPalSubscriptionButton = (props) => {
  const { plan, price, planId, userQuantity, projectQuantity, storageQuantity } = props.name

  const navigate = useNavigate()
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const signupData = JSON.parse(localStorage.getItem('signupData'))

  function generateRandomCustomId() {
    const min = 10000000;
    const max = 99999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const paypalOptions = {
    'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
    vault: true,
    intent: 'subscription',
  };

  const createSubscription = async (data, actions) => {
    try {
      return await actions.subscription.create({
        plan_id: planId,
        custom_id: generateRandomCustomId()
      });
    } catch (error) {
      console.error('Error creating subscription:', error);
      throw error;
    }
  };

  const onApprove = async (data, actions) => {
    try {
      const payload = {
        subscription_id: data.subscriptionID,
        email: userDetails ? userDetails?.email : signupData?.email,
        unique_url: userDetails ? userDetails?.unique_url : signupData?.unique_url,
        plan: plan,
        user_quantity: userQuantity,
        project_quantity: projectQuantity,
        storage_quantity: storageQuantity,
        total_price: price
      }
      const response = await apiServiceHandler('POST', 'api/payment/paypal/frontend/success', payload);
      navigate('/payment-success', { state: { subscriptionID: response.data.id, billing_date: response.data.billing_info.next_billing_time, userQuantity, projectQuantity, storageQuantity } })
    } catch (error) {
      console.error('Error on subscription approval:', error);
    }
  };

  const onCancel = async (data) => {
    try {
      const payload = {
        cancel_order_id: data.orderID,
        email: userDetails ? userDetails?.email : signupData?.email,
        unique_url: userDetails ? userDetails?.unique_url : signupData?.unique_url,
        plan: plan,
        user_quantity: userQuantity,
        project_quantity: projectQuantity,
        storage_quantity: storageQuantity,
        total_price: price
      }
      const response = await apiServiceHandler('POST', 'api/payment/paypal/frontend/cancel', payload);
      navigate('/payment-cancel', { state: { orderId: data } })
    } catch (error) {
      console.error('Error on subscription Cancel:', error);
    }
  };

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <PayPalButtons
        style={{
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe',
        }}
        createSubscription={createSubscription}
        onApprove={onApprove}
        onCancel={onCancel}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalSubscriptionButton;
