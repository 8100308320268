import React, { useEffect, useRef, useState } from 'react'
import "./header.css"
import hamburger from "../../assests/images/hamburger.svg"
import logo from "../../assests/images/logo.png"
import logoSmall from "../../assests/images/LogoSmall.svg"
import Bell from "../../assests/images/bell.svg"
// import ProfileImg from "../../assests/images/profile-pic.png"
import ProfileImg from '../../assests/images/avatar.svg';
import Navbar from '../navbar'
import { useDispatch, useSelector } from 'react-redux'
import { rtdb } from '../firebase/firebase'
import { onValue, child, get, ref, set, getDatabase } from "firebase/database"
import NotificationModal from './notificationModal'
import { useNavigate, useParams } from 'react-router-dom'
import ProfileEdit from "./editProfile";
import apiServiceHandler from '../../service/apiService'
import CreateInvoice from "./createInvoice"
import CreateInvoiceFromTimeLog from "./createInvoiceUsingTimeLog"
import PaypalSetup from './PaypalSetup'
import InvoiceApproval from './invoiceApprovalpopUp'
import { updateUserInfo } from '../../redux/userData'

const Header = () => {
   const [showNotifications, setShowNotifications] = useState(false)
   const [showNavbar, setShowNavbar] = useState(false)
   const { id } = useParams()
   const navigate = useNavigate()
   const myNotifications = useSelector(state => state.notification.notification)
   const myNotificationsCount = useSelector(state => state.notification.unreadNotificationCount)
   const [openNotificationModal, setOpenNotificationModal] = useState(false)
   const [isOpen, setIsOpen] = useState(false)
   const [edituserData, setEdituserData] = useState({})
   const [refreshData, setRefreshData] = useState(false)
   const dispatch = useDispatch()
   const userInfo = useSelector(state => state.userData.userInfo)
   const selectedProject = useSelector(state => state.project.showProjectList)
   const toggleNavbar = () => {
      setShowNavbar(!showNavbar);
   };
   const formatDate = (timestampInMilliseconds) => {
      const date = new Date(timestampInMilliseconds);
      const formatter = new Intl.DateTimeFormat('en-US', {
         year: 'numeric',
         month: 'short',
         day: '2-digit',
      });
      return formatter.format(date);
   }

   const sendNotification = async (notification, receiverUniqueUrl, timestampString, notificationRead, from, projectName) => {
      if (notification === "") {
         alert("Enter a valid notification");
         return;
      }
      if (notificationRead == undefined) notificationRead = false
      const timestampDate = new Date();
      var timestampString = timestampString || timestampDate.getTime();

      let databasePath = receiverUniqueUrl + "_notification";

      const parentRef = ref(rtdb, databasePath);

      // Use the `get` method inside a `then` block to ensure it completes before further processing
      get(parentRef)
         .then((snapshot) => {
            if (snapshot.exists()) {
               // Data exists, so create a new child object
               const childObjectRef = child(parentRef, `notification_${timestampString}`);
               return set(childObjectRef, {
                  notification: notification,
                  createdAt: timestampString,
                  uniqueUrl: receiverUniqueUrl,
                  notificationRead: notificationRead,
                  projectName: projectName || "",
                  from: from || ""
               });
            } else {
               // Data doesn't exist, so set the parent object and create a child object
               return set(parentRef, {}).then(() => {
                  const childObjectRef = child(parentRef, `notification_${timestampString}`);
                  return set(childObjectRef, {
                     notification: notification,
                     createdAt: timestampString,
                     uniqueUrl: receiverUniqueUrl,
                     notificationRead: notificationRead,
                     projectName: projectName || "",
                     from: from || ""
                  });
               });
            }
         })
         .then(() => {
            console.log("Notification sent successfully");
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const userMarkNotificationRead = async () => {
      let userData = localStorage.getItem("userData")
      userData = userData ? JSON.parse(userData) : {}
      let uniqueUrl = userData?.unique_url

      let databasePath = uniqueUrl + "_" + "notification"

      const dbRef = ref(getDatabase());

      get(child(dbRef, databasePath)).then((snapshot) => {
         if (snapshot.exists()) {
            let data = snapshot.val()

            if (data) {
               Object.keys(data).forEach(async (x) => {

                  // console.log(data[x])

                  if (data[x]["notificationRead"] != undefined && data[x]["notificationRead"] == false) {

                     await sendNotification(data[x]["notification"], data[x]["uniqueUrl"], data[x]["createdAt"], true, data[x]["from"], data[x]["projectName"])

                  }

               });
            } else {
               // Handle the case when data is null or undefined
               // Invoke the callback with an empty array
               console.log("")
            }
         } else {
            console.log("No data available");
         }
      }).catch((error) => {
         console.error(error);
      });


   }

   const handleNotificationClick = () => {
      setShowNotifications(!showNotifications)
      userMarkNotificationRead()
   }
   const handleClose = () => {
      setOpenNotificationModal(false)
   }
   const notificationRef = useRef(null);

   useEffect(() => {
      fetchUserData()

      function handleClickOutside(event) {
         if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            setShowNotifications(false);
         }
      }

      // Add a click event listener to the document
      document.addEventListener('click', handleClickOutside);

      return () => {
         // Clean up the event listener when the component unmounts
         document.removeEventListener('click', handleClickOutside);
      };
   }, []);

   const onClose = () => {
      setIsOpen(false);
   }

   const handleProfileUpdate = () => {
      setIsOpen(true)
   }

   const fetchUserData = async () => {
      try {
         const response = await apiServiceHandler("GET", "api/auth/user/details", {})
         setEdituserData(response?.data)
         dispatch(updateUserInfo(response?.data))
      } catch (e) {
         console.log(e)
      }
   }

   useEffect(() => {
      if (refreshData) {
         fetchUserData()
      }
      setRefreshData(false);
   }, [refreshData])


   return (
      <div className="look-navbar bg-white shadow-sm pt-2 pb-2 mb-4">
         <div className="container-fluid">
            <div className="row header-row align-items-center justify-content-between">
               <div className="col-auto">
                  <div className="top-left d-flex align-items-center">
                     <button type="button" className="hamburger" onClick={toggleNavbar} ><img src={hamburger} alt='icon' /></button>
                     <div className="look-logo ms-3 ms-md-4" style={{ display: "grid" }}>
                        <img style={window.screen.width > 768 ? { maxWidth: "150px", maxHeight: "60px", } : { maxWidth: "100px", maxHeight: "40px" }} src={userInfo?.company_logo ? userInfo?.company_logo : logo} alt={userInfo?.company_name
                        } />
                        {/* <img style={{ width: "200px", height: "20px" }} src={userInfo?.company_logo ? userInfo?.company_logo : logo} alt='logo' /> */}
                        <div style={window.screen.width > 768 ? { color: 'rgba(0, 0, 0, 0.30)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' } : { fontSize: 7 }}>Powered by <img src={logoSmall} alt='logo' /></div>

                     </div>
                     <div className="look-logo ms-3 ms-md-4">
                        {selectedProject[0]?.logo ? (
                           <img style={window.screen.width > 768 ? { maxWidth: "150px", maxHeight: "80px" } : { maxWidth: "80px", maxHeight: "40px" }} src={selectedProject[0]?.logo} alt={selectedProject[0]?.project_name}
                              onError={() => {
                                 const temp = { ...selectedProject[0] };
                                 if (temp) {
                                    temp.logo = null;
                                 }
                              }}
                           />
                        ) : (
                           <span style={{ color: '#333', fontSize: '16px', fontWeight: 'bold' }}>{selectedProject[0]?.project_name}</span>
                        )}
                        {/* <img style={{ width: "150px", height: "60px" }} src={selectedProject[0]?.logo ? selectedProject[0]?.logo : logo} alt='logo' /> */}
                     </div>



                     <div className={`side-menu shadow ${showNavbar ? 'active' : ''}`}>  <Navbar setShowNavbar={setShowNavbar} /></div>

                  </div>
               </div>
               <div className="col-auto">
                  <div className="top-right d-flex align-items-center">
                     {/* 
                     <div className="total-time-track">
                        <div className="head-time">Time Tracking</div>
                        <div className="head-time-track">
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.125" y="0.5" width="17.3747" height="17" rx="8.5" fill="#008844" />
                              <path d="M11.2694 8.59628L7.832 6.56414C7.55272 6.39911 7.125 6.55925 7.125 6.96744V11.0308C7.125 11.3969 7.52244 11.6176 7.832 11.4341L11.2694 9.40289C11.576 9.22223 11.577 8.77694 11.2694 8.59628Z" fill="white" />
                           </svg>
                           7:07:12
                        </div>
                     </div>
                     */}
                     <div className="task-notification" ref={notificationRef}>
                        <div className="noti-col">
                           <div onClick={() => handleNotificationClick()} className="bell-icon"><img src={Bell} alt='icon' /><span>{myNotificationsCount}</span></div>
                           {/* <div className="noti-text"> Unread Notification {myNotificationsCount}
                           <span>For Wordpress Developer</span>
                           </div> */}
                        </div>
                        {showNotifications &&
                           <div className="show-task" >
                              <ul className="m-0 list-unstyled">
                                 {myNotifications && myNotifications.slice(0, 5).map((val, index) => (<li onClick={() => setOpenNotificationModal(true)}><a href="#!">{val?.notification}<span>{formatDate(val?.createdAt)}</span></a></li>

                                 ))}
                                 {myNotifications.length > 5 && (<button type="button" className="view-more-not mt-3" onClick={() => setOpenNotificationModal(true)}>View more</button>)}</ul>


                           </div>}
                     </div>
                     <div className="profile-pic dropdown">
                        <button className="no-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><img src={edituserData?.logo ? edituserData.logo : ProfileImg} /></button>
                        <ul className="dropdown-menu">
                           <li onClick={() => handleProfileUpdate()}><a href="#">My Profile</a></li>
                           <li><a href="#">Subscription</a></li>
                           <li><a href="/clickup-signup">Clickup Integration</a></li>
                           {/* <li><a href="/jira-signup">Jira Integration</a></li> */}
                           <li><a href="#" onClick={() => {
                              navigate(`/${id}`)
                           }}>Log Out</a></li>
                        </ul>
                     </div>
                     {/* <div className="profile-pic">
                <img src={ProfileImg} alt='icon'/>
             </div> */}
                  </div>
               </div>
            </div>
         </div>
         <NotificationModal isOpen={openNotificationModal} onClose={handleClose} />
         <ProfileEdit setRefreshData={setRefreshData} edituserData={edituserData} isOpen={isOpen} onClose={onClose} />
         <CreateInvoice />
         <CreateInvoiceFromTimeLog />
         <PaypalSetup />
         <InvoiceApproval />
      </div>
   )
}
export default Header
