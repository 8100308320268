import React, { useEffect, useState } from 'react'
import SearchIcon from '../../assests/images/search.svg';
import ViewChats from './viewChats';
import { rtdb } from '../firebase/firebase';
import { onValue, child, get, ref, set, getDatabase } from "firebase/database"
import { useDispatch, useSelector } from 'react-redux';



const ChatSection = ({ flag }) => {
    const [openChatBox, setOpenChatBox] = useState(false)
    const [chatValue, setChatValue] = useState(null);
    const [chatUserList, setChatUserList] = useState([])
    const [lastMsgMap, setLastMsgMap] = useState([])
    const usersList = useSelector(state => state.allUserData.users)
    const [searchValue, setSetsearchValue] = useState("")
    const [filteredData, setFilteredData] = useState([])
    let debounceTimer;
    const debounceTimeout = 500; // 500ms delay
    const [chatUsersUniqueurl, setChatUsersUniqueurl] = useState({})

    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    let senderUniqueUrl = userData?.unique_url



    const userUnreadMessageMap = async () => {

        let userData = localStorage.getItem("userData")
        userData = userData ? JSON.parse(userData) : {}
        let senderUniqueUrl = userData?.unique_url

        const starCountRef = ref(rtdb, "/");
        onValue(starCountRef, async (snapshot) => {
            const data = snapshot.val();
            if (data) {
                let map = {}
                let lastMsgMap = {}
                Object.keys(data).forEach(x => {
                    if (x.includes(senderUniqueUrl)) {
                        Object.keys(data[x]).forEach((y) => {

                            if (data[x][y]["senderUniqueUrl"] != senderUniqueUrl){
                                map[data[x][y]["senderUniqueUrl"] + "_created_at"] = map[data[x][y]["senderUniqueUrl"] + "_created_at"] ? (data[x][y]["createdAt"] < map[data[x][y]["senderUniqueUrl"] + "_created_at"] ? map[data[x][y]["senderUniqueUrl"] + "_created_at"] : data[x][y]["createdAt"]) : data[x][y]["createdAt"];
                            
                                lastMsgMap[data[x][y]["senderUniqueUrl"]] = data[x][y]["message"];}
                            if (data[x][y]["receiverUniqueUrl"] != senderUniqueUrl){
                                map[data[x][y]["receiverUniqueUrl"] + "_created_at"] = map[data[x][y]["receiverUniqueUrl"] + "_created_at"] ? (data[x][y]["createdAt"] < map[data[x][y]["receiverUniqueUrl"] + "_created_at"] ? map[data[x][y]["receiverUniqueUrl"] + "_created_at"] : data[x][y]["createdAt"]) : data[x][y]["createdAt"];
                            
                                lastMsgMap[data[x][y]["receiverUniqueUrl"]] = data[x][y]["message"];}
                            if (data[x][y]["messageRead"] != undefined && data[x][y]["messageRead"] == false) {
                                if (data[x][y]["senderUniqueUrl"] != senderUniqueUrl) {
                                    if (!map[data[x][y]["senderUniqueUrl"]]) {
                                        map[data[x][y]["senderUniqueUrl"]] = 1;
                                    } else {
                                        map[data[x][y]["senderUniqueUrl"]]++;
                                    }
                                }
                            } else if (data[x][y]["messageRead"] != undefined && data[x][y]["messageRead"] == true) {
                                if (data[x][y]["senderUniqueUrl"] != senderUniqueUrl) {
                                    if (!map[data[x][y]["senderUniqueUrl"]]) {
                                        map[data[x][y]["senderUniqueUrl"]] = 0;
                                    }
                                }
                            }
                        });
                    }
                });
                const result = {};
                await usersList.forEach(key => {
                    if (key.unique_url == senderUniqueUrl) return
                    result[key["unique_url"]] = map[key["unique_url"] + "_created_at"] || 0;
                });
                
                let keyValueArray = Object.entries(result);
                keyValueArray.sort((a, b) => b[1] - a[1]);
                
                keyValueArray.forEach(key => {

                    if (key[0] == senderUniqueUrl) return
                    if (map[key[0]])
                        key[1] = map[key[0]];
                    else
                        key[1] = 0
                });

                const sortedResult = Object.fromEntries(keyValueArray);
                
                setLastMsgMap(lastMsgMap)
                setChatUserList(sortedResult)

            } else {
                // Handle the case when data is null or undefined
                // Invoke the callback with an empty array
                console.log("")
            }
        })
    }
    const sendMessage = async (message, senderUniqueUrl, receiverUniqueUrl, timestampString, messageRead) => {
        if (message === "") {
            alert("Enter a valid message");
            return;
        }
        if (messageRead == undefined || messageRead == null) messageRead = false
        const timestampDate = new Date();
        var timestampString = timestampString || timestampDate.getTime();

        let databasePath = senderUniqueUrl < receiverUniqueUrl ? senderUniqueUrl + "_" + receiverUniqueUrl : receiverUniqueUrl + "_" + senderUniqueUrl;

        const parentRef = ref(rtdb, databasePath);

        // Use the `get` method inside a `then` block to ensure it completes before further processing
        get(parentRef)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    // Data exists, so create a new child object
                    const childObjectRef = child(parentRef, `chats_${timestampString}`);
                    return set(childObjectRef, {
                        message: message,
                        createdAt: timestampString,
                        senderUniqueUrl: senderUniqueUrl,
                        receiverUniqueUrl: receiverUniqueUrl,
                        messageRead: messageRead
                    });
                } else {
                    // Data doesn't exist, so set the parent object and create a child object
                    return set(parentRef, {}).then(() => {
                        const childObjectRef = child(parentRef, `chats_${timestampString}`);
                        return set(childObjectRef, {
                            message: message,
                            createdAt: timestampString,
                            senderUniqueUrl: senderUniqueUrl,
                            receiverUniqueUrl: receiverUniqueUrl,
                            messageRead: messageRead
                        });
                    });
                }
            })
            .then(() => {
                console.log("Message sent successfully");
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const userMarkMsgRead = async (receiverUniqueUrl) => {
        let userData = localStorage.getItem("userData")
        userData = userData ? JSON.parse(userData) : {}
        let senderUniqueUrl = userData?.unique_url

        let databasePath = senderUniqueUrl < receiverUniqueUrl ? senderUniqueUrl + "_" + receiverUniqueUrl : receiverUniqueUrl + "_" + senderUniqueUrl
        const dbRef = ref(getDatabase());

        get(child(dbRef, databasePath)).then((snapshot) => {

            if (snapshot.exists()) {
                let data = snapshot.val()

                if (data) {
                    Object.keys(data).forEach(async (x) => {


                        if (data[x]["receiverUniqueUrl"] == senderUniqueUrl) {
                            if (data[x]["messageRead"] != undefined && data[x]["messageRead"] == false) {

                                await sendMessage(data[x]["message"], data[x]["senderUniqueUrl"], data[x]["receiverUniqueUrl"], data[x]["createdAt"], true)
                            }
                        }
                    });
                } else {
                    // Handle the case when data is null or undefined
                    // Invoke the callback with an empty array
                    console.log("")
                }
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });


    }

    useEffect(() => {
        usersList.forEach(key => {
            if (key.unique_url == senderUniqueUrl) return
            chatUsersUniqueurl[key.unique_url] = key.first_name + " " + key.last_name;
            setChatUsersUniqueurl(chatUsersUniqueurl)
        })
        userUnreadMessageMap()

    }, [usersList])

    const handleUserChatClick = (value) => {
        setOpenChatBox(true)
        setChatValue(value)
    }

    const handleClose = () => {
        setOpenChatBox(false)
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (Object.keys(chatUserList).length > 0) {
            const filterData = Object.keys(chatUserList)?.filter(value => chatUsersUniqueurl[value]?.includes(searchValue) || searchValue.trim() === "")
            setFilteredData(filterData)
        }

    }
    useEffect(() => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleSearch({ preventDefault: () => {} })
        }, debounceTimeout);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue, chatUserList]);

    return (
        <div>
            <div
                // className="chat-search d-flex align-items-center view-line"
                className={flag ? "chat-search d-flex align-items-center" : "chat-search d-flex align-items-center view-line"}
            >
                <form onSubmit={handleSearch}
                // style={{ display: openChatBox ? "none" : "" }}
                >
                    <input onChange={(e) => setSetsearchValue(e.target.value)} type="text" name="findchat" placeholder="Find chat..." className="form-control" />
                    <button type="submit"><img src={SearchIcon} /></button>
                </form>

            </div>
            <div className="chat-list" style={{ display: openChatBox ? "none" : "" }}>
                <ul className="list-unstyled m-0">
                    {filteredData?.map((value) => (<li style={{ cursor: 'pointer' }} onClick={() => { handleUserChatClick(value); userMarkMsgRead(value) }}>
                        <div className="chat-left">
                            <div className="short-name" style={{ background: '#FF9A00' }}>{chatUsersUniqueurl[value][0].toUpperCase()}</div>
                            <div className="full-name">
                                <h3>{chatUsersUniqueurl[value]}</h3>
                                {/* <div className="last-seen">Last seen today at 10:00 pm</div> */}
                            </div>
                        </div>
                         <div style={{ display: 'flex' }}>
                           <span className="chat-right-msg">{lastMsgMap[value]}</span> 
                           {chatUserList[value] != 0 &&  <div className="chat-right" style={{ background: '#FF9A00' }}>{chatUserList[value]}</div>}
                        </div>
                    </li>
                    ))}
                </ul>
            </div>
            {openChatBox && chatValue && <ViewChats chatUsersUniqueurl={chatUsersUniqueurl} chatValue={chatValue} isOpen={openChatBox} onClose={handleClose} />}


        </div>
    )
}

export default ChatSection
