import React, { useRef, useState } from 'react'
import CreateTaskIcon from '../../assests/icons/create-task.png';
import UpdateTaskIcon from '../../assests/icons/updates-task.png';
import AddUserIcon from '../../assests/icons/add-user.png';
import ReviewIcon from '../../assests/icons/review-1.png';
import HeaderPage from './header';
import FooterPage from './footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const PricingPage = () => {
    const [activeItem, setActiveItem] = useState(0);
    const sliderRef = useRef();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "20px",
        autoplay: true, 
        autoplaySpeed: 3000,
        arrows: false,
        focusOnSelect: true,
        // dotsClass: "slick-dots", // Custom class for dots
        // customPaging: () => <span className="dot" />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '0'
                }
            }
        ]
    };

    const settingsitem = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: index => setActiveItem(index) 
    };

    const handleItemClick = (index) => {
        setActiveItem(index); 
        sliderRef.current.slickGoTo(index); // Go to the corresponding slide in the Slider component
    };

    return (
        <div>
            <HeaderPage />

            <section class="subscription-plans py-5">
                <div class="container">
                    <h2 class="text-center">Subscription Plans</h2>
                    <p class="text-center mb-4">Our pricing scales with you and your needs. Try it for free. No credit card required.</p>
                </div>
            </section>
            <section class="why-join py-5 bg-white">
                <div class="container">
                    <h2 class="text-center">Why join South Bison Connect?</h2>
                    <p class="text-center mb-4">Just 3 simple steps to start using South Bison Connect</p>

                    <div class="click-section">
                        <div class="row align-items-center">
                            <div className="col-md-7 mb-4 mb-md-0">
                                <Slider {...settingsitem} ref={sliderRef}>
                                    <div>
                                        <img src={CreateTaskIcon} alt="Create Task" />
                                    </div>
                                    <div>
                                        <img src={UpdateTaskIcon} alt="Update Task" />
                                    </div>
                                    <div>
                                        <img src={AddUserIcon} alt="Add User" />
                                    </div>
                                </Slider>
                            </div>
                            <div class="col-md-5">
                                <ul class="list-unstyled m-0 p-0 click-slide">
                                    <li className={activeItem === 0 ? 'active' : ''} onClick={() => handleItemClick(0)}>
                                        <a class="" href="#create">
                                            <h3>Create your tasks</h3>
                                            <p>There are many variations of passages of lorem ipsum dolor amet available.</p>
                                        </a>
                                    </li>
                                    <li className={activeItem === 1 ? 'active' : ''} onClick={() => handleItemClick(1)}>
                                        <a href="#updates">
                                            <h3>Updates on tasks</h3>
                                            <p>There are many variations of passages of lorem ipsum dolor amet available.</p>
                                        </a>
                                    </li>
                                    <li className={activeItem === 2 ? 'active' : ''} onClick={() => handleItemClick(2)}>
                                        <a href="#add">
                                            <h3>Add new users</h3>
                                            <p>There are many variations of passages of lorem ipsum dolor amet available.</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div class="text-center mt-4"><a href="#!" class="filled-btn blue-filled">Book Free Demo</a></div>
                </div>
            </section>
            <section class="trust-section py-5 bg-white">
                <div class="container">
                    <h2 class="text-center">Trusted by over 10,000 companies</h2>
                </div>
                {/* <div class="trust-loop owl-carousel owl-theme"> */}
                <div style={{ margin: "0 auto", maxWidth: "calc(100% - 40px)" }}>
                <Slider {...settings} className="trust-loop">
                    <div class="item">
                        <div class="trust-text bg-white">
                            <p>"South Bison Connect app platform is incredibly simple to use."</p>
                            <div class="trust-info d-flex align-items-center">
                                <div class="trust-img me-3">
                                    <img src={ReviewIcon} />
                                </div>
                                <div class="trust-wala">
                                    <h4 class="mb-1">Christian Brown</h4>
                                    <h5 class="mb-0">Entrepreneur, LA</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="trust-text bg-white">
                            <p>"South Bison Connect app platform is incredibly simple to use."</p>
                            <div class="trust-info d-flex align-items-center">
                                <div class="trust-img me-3">
                                    <img src={ReviewIcon} />
                                </div>
                                <div class="trust-wala">
                                    <h4 class="mb-1">Christian Brown</h4>
                                    <h5 class="mb-0">Entrepreneur, LA</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="trust-text bg-white">
                            <p>"South Bison Connect app platform is incredibly simple to use."</p>
                            <div class="trust-info d-flex align-items-center">
                                <div class="trust-img me-3">
                                    <img src={ReviewIcon} />
                                </div>
                                <div class="trust-wala">
                                    <h4 class="mb-1">Christian Brown</h4>
                                    <h5 class="mb-0">Entrepreneur, LA</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="trust-text bg-white">
                            <p>"South Bison Connect app platform is incredibly simple to use."</p>
                            <div class="trust-info d-flex align-items-center">
                                <div class="trust-img me-3">
                                    <img src={ReviewIcon} />
                                </div>
                                <div class="trust-wala">
                                    <h4 class="mb-1">Christian Brown</h4>
                                    <h5 class="mb-0">Entrepreneur, LA</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="trust-text bg-white">
                            <p>"South Bison Connect app platform is incredibly simple to use."</p>
                            <div class="trust-info d-flex align-items-center">
                                <div class="trust-img me-3">
                                    <img src={ReviewIcon} />
                                </div>
                                <div class="trust-wala">
                                    <h4 class="mb-1">Christian Brown</h4>
                                    <h5 class="mb-0">Entrepreneur, LA</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
                </Slider>
                </div>
            </section>

            <FooterPage />

        </div>
    )
}

export default PricingPage
