import React, { useEffect, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import {
  updateRefreshFolderData,
  updateRefreshFolders,
} from "../../redux/documents";
import apiServiceHandler from "../../service/apiService";

const RenameFolder = ({ isOpen, onClose, indexData, folders, crumbs }) => {
  const [name, setName] = useState("");

  const dispatch = useDispatch();

  // let folders = useSelector(state => state.broker.documentList)

  useEffect(() => {
    let folder = folders?.find((folder) => folder._id === indexData);
    setName(folder?.folder_name);
  }, [isOpen]);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const updateFolder = async () => {
    let payload = { folderId: indexData, folder_name: name };

    try {
      let response = [];

      response = await apiServiceHandler(
        "POST",
        "api/file/management/update/folder",
        payload
      );

      if(crumbs?.length == 0) {
        dispatch(updateRefreshFolders(true));  
      }else {
        dispatch(updateRefreshFolders(true));
        dispatch(updateRefreshFolderData(true));
      }

      onClose();

      toastr.success(response?.message);
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <div>
      <div
        className={`modal fade add-user edit-task-viewmore ${
          isOpen ? "show backdrop" : " "
        }`}
        id="addUserModal"
        tabindex={-1}
        aria-labelledby="addUserModalLabel"
        aria-hidden={true}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h2 className="modal-title">Rename Folder</h2>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <img src={CloseIcon} />
              </button>
            </div>

            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="folder_name"
                      value={name}
                      onChange={handleChange}
                      placeholder="Folder name*"
                      required
                      autoFocus
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer justify-content-between">
              <button type="submit" className="bg-btn" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="bg-btn" onClick={updateFolder}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameFolder;
