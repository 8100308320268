import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import { useParams } from 'react-router-dom';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux';
import { updateOpenInvoiceFromTimeLogpopup, updateRefreshInvoiceList } from '../../redux/invoice';
import Select from 'react-select'
import AddIcon from '../../assests/images/add.svg';
import JllLogo from '../../assests/images/jll_logo.svg';
import "./createInvoice.css";
import Trash from "../../assests/images/trash.svg";

const CreateInvoice = ({ setRefreshData, edituserData }) => {
    const invoiceFlag = useSelector((state) => state.invoice.openInvoiceFromTimeLogpopup);
    const selectedDateRange = useSelector((state) => state.invoice.selectedDateRange);
    //v\selectedDateRange
    const usersList = useSelector(state => state.allUserData.users) || []
    const [userUpdatedData, setUserUpdatedData] = useState(edituserData)
    const [rolesList, setRolesList] = useState([])
    const [userData, setUserData] = useState({})
    const [uploadedFiles, setUploadedFiles] = useState(null)
    const { id } = useParams()
    const data = useSelector(state => state.invoice.totalTimeLogData)
    const dispatch = useDispatch()
    const [category, setCategory] = useState([])
    const [invoiceData, setInvoiceData] = useState({
        email: "",
        invoice_number: "",
        invoice_date: "",
        // tax: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
        notes: "",
        amount_per_hour: "",
        additional_recipients: "",
        items: []

    })

    const [items, setItems] = useState([])
    useEffect(() => {
        fetchUserData()
    }, []);
    const [errorMessage, setErrorMessage] = useState('');

    let usermData = localStorage.getItem("userData")
    usermData = usermData ? JSON.parse(usermData) : {}

    const Options = usersList?.map((val, index) => ({
        value: val?._id, label: `${val?.first_name} ${val?.last_name} (${val?.email})`
    }))


    const handleChange = (event) => {
        const { name, value } = event.target;

        setInvoiceData((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };







    const [milestones, setMilestones] = useState([
        {
            "name": "",
            "description": "",
            "time": '',
            "quantity": "1",
            "tax": {
                "name": "",
                "percent": ""
            },
            "unit_amount": {
                "currency_code": "USD",
                "value": ""
            },
            "unit_of_measure": "QUANTITY"
        }
    ]);

    const addMilestone = () => {
        setMilestones([
            ...milestones,
            {
                "name": "",
                "description": "",
                "time": '',
                "quantity": "1",
                "tax": {
                    "name": "",
                    "percent": ""
                },
                "unit_amount": {
                    "currency_code": "USD",
                    "value": ""
                },
                "unit_of_measure": "QUANTITY"
            },
        ]);
    };

    const handleMilestoneChange = (index, name, value) => {
        setMilestones((prevMilestones) => {
            const updatedMilestones = [...prevMilestones];
            const updatedValue = value.target ? value.target.value : value;
            updatedMilestones[index] = {
                ...updatedMilestones[index],
                [name]: updatedValue
            };
            return updatedMilestones;
        });
    };

    const deleteMilestone = (index) => {
        const updatedMilestones = [...milestones];
        updatedMilestones.splice(index, 1);
        setMilestones(updatedMilestones);
    };

    const handleMilestoneAmountChange = (index, name, value) => {
        setMilestones((prevMilestones) => {
            const updatedMilestones = [...prevMilestones];
            const updatedValue = value.target ? value.target.value : value;
            updatedMilestones[index] = {
                ...updatedMilestones[index],
                [name]: updatedValue
            };
            return updatedMilestones;
        });
    };

    const updateMilestoneTime = (index, newName) => {
        // Make a copy of the milestones array to avoid mutating the original array
        const updatedMilestones = [...milestones];
        const amount = calculateTotalMoneyWithMin(newName, invoiceData.amount_per_hour)

        // Update the values of the milestone at the specified index
        if (index >= 0 && index < updatedMilestones.length) {
            updatedMilestones[index] = {
                ...updatedMilestones[index],
                time: newName !== undefined ? newName : updatedMilestones[index].time,
                unit_amount: {
                    ...updatedMilestones[index].unit_amount,
                    value: amount !== undefined ? amount : updatedMilestones[index].unit_amount.value
                }
                // unit_amount: {
                //     ...updatedMilestones[index].unit_amount,
                //     value: newValue !== undefined ? newValue : updatedMilestones[index].unit_amount.value
                // }
                // Add more properties if needed
            };

            // Set the updated milestones array
            setMilestones(updatedMilestones);
            // const amount = calculateTotalMoneyWithMin(newName, invoiceData.amount_per_hour)
            // console.log('amount: ' + amount)
            // updateMilestoneAmount(index, amount)
        } else {
            console.error("Invalid index provided for updating milestone.");
        }
    }

    // const updateMilestoneAmount = (index, newValue) => {
    //     // Make a copy of the milestones array to avoid mutating the original array
    //     const updatedMilestones = [...milestones];

    //     // Update the values of the milestone at the specified index
    //     if (index >= 0 && index < updatedMilestones.length) {
    //         updatedMilestones[index] = {
    //             ...updatedMilestones[index],
    //             // name: newName !== undefined ? newName : updatedMilestones[index].name,
    //             unit_amount: {
    //                 ...updatedMilestones[index].unit_amount,
    //                 value: newValue !== undefined ? newValue : updatedMilestones[index].unit_amount.value
    //             }
    //             // Add more properties if needed
    //         };

    //         // Set the updated milestones array
    //         setMilestones(updatedMilestones);
    //     } else {
    //         console.error("Invalid index provided for updating milestone.");
    //     }
    // }

    const updateMilestoneAmount = (index, newValue, field) => {
        // Make a copy of the milestones array to avoid mutating the original array
        const updatedMilestones = [...milestones];
    
        // Update the values of the milestone at the specified index
        if (index >= 0 && index < updatedMilestones.length) {
            if (field === "unit_amount") {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    [field]: {
                        ...updatedMilestones[index][field],
                        value: newValue !== undefined ? newValue : updatedMilestones[index][field].value
                    }
                };
            } else if (field === "tax") {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    tax: {
                        ...updatedMilestones[index].tax,
                        percent: newValue !== undefined ? newValue : updatedMilestones[index].tax.percent
                    }
                };
            } else {
                console.error("Invalid field provided for updating milestone.");
                return;
            }
    
            // Set the updated milestones array
            setMilestones(updatedMilestones);
            console.log(updatedMilestones);
        } else {
            console.error("Invalid index provided for updating milestone.");
        }
    }

    const updateMilestone = (index, newName, field) => {
        // Make a copy of the milestones array to avoid mutating the original array
        const updatedMilestones = [...milestones];
    
        // Update the values of the milestone at the specified index
        if (index >= 0 && index < updatedMilestones.length) {
            if (field === "tax") {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    tax: {
                        ...updatedMilestones[index].tax,
                        name: newName !== undefined ? newName : updatedMilestones[index].tax.name
                    }
                };
            } else {
                updatedMilestones[index] = {
                    ...updatedMilestones[index],
                    name: newName !== undefined ? newName : updatedMilestones[index].name,
                };
            }
    
            // Set the updated milestones array
            setMilestones(updatedMilestones);
        } else {
            console.error("Invalid index provided for updating milestone.");
        }
    }

    // const updateMilestone = (index, newName) => {
    //     // Make a copy of the milestones array to avoid mutating the original array
    //     const updatedMilestones = [...milestones];

    //     // Update the values of the milestone at the specified index
    //     if (index >= 0 && index < updatedMilestones.length) {
    //         updatedMilestones[index] = {
    //             ...updatedMilestones[index],
    //             name: newName !== undefined ? newName : updatedMilestones[index].name,
    //             // unit_amount: {
    //             //     ...updatedMilestones[index].unit_amount,
    //             //     value: newValue !== undefined ? newValue : updatedMilestones[index].unit_amount.value
    //             // }
    //             // Add more properties if needed
    //         };

    //         // Set the updated milestones array
    //         setMilestones(updatedMilestones);
    //     } else {
    //         console.error("Invalid index provided for updating milestone.");
    //     }
    // }

    const updateMilestoneDescription = (index, newName) => {
        // Make a copy of the milestones array to avoid mutating the original array
        const updatedMilestones = [...milestones];

        // Update the values of the milestone at the specified index
        if (index >= 0 && index < updatedMilestones.length) {
            updatedMilestones[index] = {
                ...updatedMilestones[index],
                description: newName !== undefined ? newName : updatedMilestones[index].description,
                // unit_amount: {
                //     ...updatedMilestones[index].unit_amount,
                //     value: newValue !== undefined ? newValue : updatedMilestones[index].unit_amount.value
                // }
                // Add more properties if needed
            };

            // Set the updated milestones array
            setMilestones(updatedMilestones);
        } else {
            console.error("Invalid index provided for updating milestone.");
        }
    }


    const customStyles = {
        control: (provided) => ({
            ...provided,
            // width: 720, // Set the width as per your requirement
        }),
    };

    const handleChangeCategory = (value) => {
        setCategory(value);
    }

    const fetchUserData = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/user/details", {})
            setUserData(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    function getAllProjectNames(data) {
        let projectNames = [];

        for (let date in data) {
            for (let projectId in data[date]) {
                let projectName = data[date][projectId].projectName;
                if (!projectNames.includes(projectName)) {
                    projectNames.push(projectName);
                }
            }
        }

        return projectNames.join(", ");
    }

    const handleCreateInvoice = async (e) => {
        e.preventDefault();
        if (category?.value === undefined) {
            toastr.info('select user to send invoices');
            return;
        }
        const payload = {
            "invoice_number": invoiceData.invoice_number,
            "invoice_date": invoiceData.invoice_date,
            "paypal_email_address": invoiceData.email,
            "invoice_generated_by": userData?._id,
            "invoice_generated_to": category?.value,
            "additional_recipients": [invoiceData.additional_recipients],
            // "paypal_email_address": "paypal_email_address",
            'project': getAllProjectNames(data),
            "tax": invoiceData.tax,
            "items": milestones,
            notes: invoiceData.notes
        }
        console.log(payload)
        // return

        try {
            const response = await apiServiceHandler("POST", "api/invoice", payload);
            // console.log(response)
            dispatch(updateOpenInvoiceFromTimeLogpopup(false))
            dispatch(updateRefreshInvoiceList(true))
            toastr.success(response?.message)
            // setMilestones({
            //     "name": "",
            //     "description": "",
            //     "quantity": "1",
            //     "unit_amount": {
            //         "currency_code": "USD",
            //         "value": ""
            //     },
            //     "unit_of_measure": "QUANTITY"
            // })
            setInvoiceData(
                {
                    email: "",
                    invoice_number: "",
                    invoice_date: "",
                    tax: "",
                    address_line_1: "",
                    address_line_2: "",
                    city: "",
                    state: "",
                    country: "",
                    zip_code: "",
                    notes: "",
                    amount_per_hour: '',
                    additional_recipients: '',

                }
            )
            setMilestones([
                {
                    
                    "description": "",
                    
                    "quantity": "1",
                    "tax": {
                        "name": "",
                        "percent": ""
                    },
                    "unit_amount": {
                        "currency_code": "USD",
                        "value": ""
                    },
                    "unit_of_measure": "QUANTITY"
                }
            ])
        } catch (err) {
            toastr.error(err?.message)
            console.log(err)
        }


    }

    // const CalculateSubtotal = () => {
    //     let total = 0
    //     milestones.forEach(value => {
    //         total += value.unit_amount.value !== "" ? parseFloat(value.unit_amount.value) : 0;
    //     })
    //     return total
    // }

    const CalculateSubtotal = () => {
        let subtotal = 0;
    
        milestones.forEach(value => {
            const unitAmount = parseFloat(value.unit_amount.value);
            const taxPercent = parseFloat(value.tax?.percent);
            
            const subtotalWithTax = unitAmount + (unitAmount * taxPercent / 100);
            subtotal += isNaN(subtotalWithTax) ? 0 : subtotalWithTax;
        });
    
        return subtotal;
    };

    // time log calculation
    const calculateTotalTime = (data) => {
        let totalHours = 0;
        let totalMinutes = 0;

        for (const date in data) {
            const entries = data[date];
            for (const entry in entries) {
                const time = entries[entry].totalTime.split(",");
                const hours = parseInt(time[0]);
                const minutes = parseInt(time[1]);

                totalHours += hours;
                totalMinutes += minutes;
            }
        }

        // Convert excess minutes to hours
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes = totalMinutes % 60;

        return { hours: totalHours, minutes: totalMinutes };
    }



    const calculateTotalMoneyWithMin = (totalTime, perHourRate) => {
        const totalHours = totalTime / 60;
        const totalMoney = totalHours * perHourRate;
        return totalMoney.toFixed(2);
    }


    const updateValue = () => {
        const updatedMilestones = Object.entries(data).map(([date, projectData]) => {
            const totalTime = Object.values(projectData)[0].totalTime.split(",");
            const projectName = Object.values(projectData)[0].projectName;
            // Parse hours and minutes from totalTime
            const [hours, minutes] = totalTime.map(Number);
            // Calculate total time in minutes
            const totalTimeInMinutes = hours * 60 + minutes;
            const amount = calculateTotalMoneyWithMin(totalTimeInMinutes, invoiceData.amount_per_hour)
            return {
                "name": `${date} - ${projectName}`,
                "description": "",
                "quantity": "1",
                "time": totalTimeInMinutes.toString(), // Convert to string format
                "unit_amount": {
                    "currency_code": "USD",
                    "value": amount || 0
                },
                "unit_of_measure": "QUANTITY"
            };
        });
        setMilestones(updatedMilestones);
    }

    const calculateProjectWiseTime = (data) => {
        let projectWiseTime = {};

        for (let date in data) {
            for (let projectId in data[date]) {
                let projectName = data[date][projectId].projectName;
                let [hours, minutes] = data[date][projectId].totalTime.split(',');

                if (!isNaN(parseFloat(hours)) && !isNaN(parseFloat(minutes))) {
                    let totalMinutes = parseFloat(hours) * 60 + parseFloat(minutes);
                    if (!projectWiseTime[projectName]) {
                        projectWiseTime[projectName] = 0;
                    }
                    projectWiseTime[projectName] += totalMinutes;
                }
            }
        }

        // Convert total minutes to hours and minutes and prepare array of strings
        let projectWiseTimeString = [];
        for (let projectName in projectWiseTime) {
            let totalMinutes = projectWiseTime[projectName];
            let totalHours = Math.floor(totalMinutes / 60);
            let remainingMinutes = totalMinutes % 60;
            let totalTime = `${totalHours}h ${remainingMinutes}m`;
            projectWiseTimeString.push(`${projectName}: ${totalTime}`);
        }

        return projectWiseTimeString.join(", ");
    }

    const updateValueByAtOnce = () => {
        let totalTime = calculateTotalTime(data);
        let totalTimeInMinutes = totalTime.hours * 60 + totalTime.minutes;
        const amount = calculateTotalMoneyWithMin(totalTimeInMinutes, invoiceData.amount_per_hour)
        const updatedMilestones = [{
            "description": "Time log for invoice " + selectedDateRange, //`${date} - ${projectName}`,
            "name": "Projects:  " + calculateProjectWiseTime(data),
            "quantity": "1",
            "time": totalTimeInMinutes.toString(), // Convert to string format
            "unit_amount": {
                "currency_code": "USD",
                "value": amount || 0
            },
            "unit_of_measure": "QUANTITY"
        }]
        setMilestones(updatedMilestones);
    }

    // console.log('calculateProjectWiseTime', calculateProjectWiseTime(data));

    useEffect(() => {
        updateValueByAtOnce()
    }, [data]);

    useEffect(() => {
        updateValueByAtOnce()
    }, [invoiceData.amount_per_hour]);

    return (
        <div style={{ display: invoiceFlag ? "block" : "none" }} class={`modal fade new-invoice show ${invoiceFlag ? "show backdrop" : " "}`} id="newInvoiceModal" tabindex="-1" aria-labelledby="newInvoiceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content border-0">
                    <div class="modal-header">
                        <h2 class="modal-title">New invoice no {invoiceData.invoice_number ? `(${invoiceData.invoice_number})` : ""}</h2>
                        <button onClick={() => dispatch(updateOpenInvoiceFromTimeLogpopup(false))} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src={CloseIcon} />
                        </button>
                    </div>
                    <div class="modal-body">
                        <form onSubmit={handleCreateInvoice}>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row g-0 align-items-center">
                                        <div class="col-md-6">
                                            <img src={JllLogo} width="100" />
                                        </div>
                                        <div class="col-md-6">
                                            <div class="d-flex align-items-center">
                                                <select class="form-select" aria-label="recurring">
                                                    <option value="Amounts Only">Amounts Only</option>
                                                </select>
                                                <div class="ms-3 fw-bold">USD</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <h3>Customer Details</h3>
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <label>Email</label>
                                            <input onChange={(e) => handleChange(e)} name='email' value={invoiceData.email} type="email" class="form-control" required />
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label>Email (cc)</label>
                                            <input onChange={(e) => handleChange(e)} name='additional_recipients' value={invoiceData.additional_recipients} type="email" class="form-control" />
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label>Select user to send Invoice</label>
                                            <Select
                                                className="btn-color"
                                                options={Options}
                                                name='category'
                                                styles={customStyles}
                                                value={category}
                                                onChange={(e) => handleChangeCategory(e)}
                                                style={{ width: "auto" }}
                                            />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Address Line 1</label>
                                            <input value={invoiceData.address_line_1} name='address_line_1' onChange={handleChange} type="text" class="form-control" required />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Address Line 2</label>
                                            <input value={invoiceData.address_line_2} name='address_line_2' onChange={handleChange} type="text" class="form-control" />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>City</label>
                                            <input type="text" value={invoiceData.city} onChange={handleChange} name='city' class="form-control" required />
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>State</label>
                                            <input type="text" onChange={handleChange} value={invoiceData.state} name='state' class="form-control" required />
                                        </div>
                                        <div class="col-md-6 mb-3 mb-md-0">
                                            <label>Country</label>
                                            <input type="text" onChange={handleChange} name='country' value={invoiceData.country} class="form-control" required />
                                        </div>
                                        <div class="col-md-6">
                                            <label>Postal Code</label>
                                            <input type="text" value={invoiceData.zip_code} name='zip_code' onChange={handleChange} class="form-control" required />
                                        </div>
                                    </div>
                                    <hr />

                                    {/* <hr /> */}
                                    {/* <div class="mb-3">
                                        <label>Notes</label>
                                        <textarea value={invoiceData.notes} name='notes' onChange={handleChange} class="form-control" rows="3"></textarea>
                                    </div> */}

                                </div>
                                <div class="col-md-4">
                                    <div class="mb-3">
                                        <label>New Invoice</label>
                                        <input type="text" onChange={handleChange} value={invoiceData.invoice_number} name='invoice_number' class="form-control" placeholder="invoice number" required />
                                    </div>
                                    <div class="mb-3">
                                        <label>Date</label>
                                        <input type="date" value={invoiceData.invoice_date} name='invoice_date' onChange={handleChange} class="form-control" required />
                                    </div>
                                    <div class="mb-3">
                                        <label>Recurring</label>
                                        <select class="form-select" aria-label="recurring">
                                            <option value="One Time">One Time</option>
                                            <option value="Yearly">Yearly</option>
                                        </select>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-md-7">Subtotal</div>
                                        <div class="col-md-5 text-end">${CalculateSubtotal().toFixed(2)}</div>
                                    </div>
                                    {/* <div class="mb-3">
                                        <label>Tax</label>
                                        <input type="number" min={0} step={1} value={invoiceData.tax} name='tax' onChange={handleChange} class="form-control" />
                                    </div>
                                    <hr /> */}
                                    <div class="row">
                                        <div class="col-md-7"><strong>Total</strong></div>
                                        <div class="col-md-5 text-end"><strong>${(CalculateSubtotal()).toFixed(2)}</strong></div>
                                    </div>
                                </div>
                                <div class="col-md-6 pb-2">
                                    <label>Amount per hour</label>
                                    <input type="number" value={invoiceData.amount_per_hour} name='amount_per_hour' onChange={handleChange} class="form-control" required />
                                </div>
                                <h3>Items</h3>
                                <div class="items-name">
                                    {milestones.map((milestone, index) => (
                                        <div className="milestone-loop" key={index}>
                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <label>Enter item name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Item name"
                                                        name="item_name"
                                                        value={milestone.name}
                                                        onChange={(e) => updateMilestone(index, e.target.value)}
                                                        //
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <label>Time(in min.)</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="time"
                                                        name="time"
                                                        value={milestone.time}
                                                        onChange={(e) => updateMilestoneTime(index, e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <label>Amount</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="amount"
                                                        name="amount"
                                                        value={milestone.unit_amount.value}
                                                        onChange={(e) => updateMilestoneAmount(index, e.target.value, 'unit_amount')}
                                                        required
                                                        readOnly
                                                    />
                                                </div>
                                                <div className='col-md-4 mb-2'>
                                                    <label>Tax Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Tax name"
                                                        name="tax_name"
                                                        value={milestone.tax?.name}
                                                        onChange={(e) => updateMilestone(index, e.target.value, 'tax')}
                                                        //
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                <label>Tax Percent %</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Tax Percent"
                                                        name="tax_percent"
                                                        value={milestone.tax?.percent}
                                                        onChange={(e) => updateMilestoneAmount(index, e.target.value, 'tax')}
                                                        required
                                                    />
                                                </div>


                                                <div className="col-md-11 mb-2">
                                                    <label>Description</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        name="description"
                                                        value={milestone.description}
                                                        onChange={(e) => updateMilestoneDescription(index, e.target.value)}
                                                        //
                                                        required
                                                    />
                                                </div>


                                                <div className="col-md-1 mb-2">
                                                    <label>Action</label>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={() => deleteMilestone(index)}
                                                    >
                                                        <img src={Trash} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="add-more-mile">
                                        <button type="button"
                                            onClick={addMilestone}
                                        >
                                            <img src={AddIcon} alt='plus' /> Add Another Item
                                        </button>
                                    </div>

                                </div>

                                <div class="text-center pt-3">
                                    <button type="submit" class="bg-btn">Create</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default CreateInvoice;
