import React, { useState } from 'react'
import './otp.css';
import Logo from "../../assests/images/logo.png"
import { useNavigate } from 'react-router-dom'
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const SignupUrl = () => {
    const navigate = useNavigate()
    const [uniqueUrl, setUniqueUrl] = useState("")
    const handleUniqueUrlCheck = async () => {
       
        const hasProtocol = uniqueUrl.startsWith("http://") || uniqueUrl.startsWith("https://");
        let id;

        if (hasProtocol) {
            try {
                const url = new URL(uniqueUrl);
                id = url.pathname.replace(/^\//, ''); // Extract the path from the URL
            } catch (error) {
                toastr.error("Invalid URL format");
                return;
            }
        } else {
            id = uniqueUrl;
        }


        if (id === undefined) {
            toastr.info("Please enter your unique URL")
            return
        }
        const payload = {
            uniqueUrl: id
        }
        try {
            const response = await apiServiceHandler("POST", "api/auth/unique/url", payload);
            console.log(response)
            toastr.error("Unique url does not exist")
        } catch (e) {
            navigate(`/${id}`)
            console.error(e)
            toastr.success("Login via code here")
        }
    }
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleUniqueUrlCheck();
        }
    };
    return (
        <div className="bg-image">
            <div className="bg-box">
                <div className="logo text-center mb-4">
                    <img src={Logo} alt='icon' />
                </div>
                <div id="enterOTP" className="enter-url shadow">
                    <p className="mb-3">Welcome to <strong>South Bison Connect</strong></p>
                    <h1 className="mb-4">Enter your Unique URL</h1>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <input name="unique_url" onChange={(e) => setUniqueUrl(e.target.value)} onKeyPress={handleKeyPress} className="form-control" id="exampleFormControlInput1" placeholder="Unique Url"
                                //  value={uniqueUrlvalue} onChange={(e) => setUniqueUrlvalue(e.target.value)}
                                />
                            </div>
                            <div className="btn-group-signin">
                                <button onClick={() => navigate("/")} type="button" className="prev-btn">Back</button>
                                <button onClick={handleUniqueUrlCheck} type="button" className="next-btn">Next</button>
                            </div>

                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignupUrl
