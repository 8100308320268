// counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const documents = createSlice({
  name: "documents",
  initialState: {
    refreshFolders: false,
    foldersList: [],
    refreshFolderData: false,
    folderData: {},
    folderIndex: "",
    crumbsData: []
  },
  reducers: {
    updateRefreshFolders: (state, action) => {
      state.refreshFolders = action.payload; // Update the count using the payload
    },
    updateFoldersList: (state, action) => {
      state.foldersList = action.payload;
    },
    updateFolderData: (state, action) => {
      state.folderData = action.payload;
    },
    updateRefreshFolderData: (state, action) => {
      state.refreshFolderData = action.payload;
    },
    updateFolderIndex: (state, action) => {
        state.folderIndex = action.payload
    },
    updateCrumbsData: (state, action) => {
        state.crumbsData = action.payload
    }
  },
});

export const { updateFoldersList, updateRefreshFolders, updateRefreshFolderData, updateFolderData, updateFolderIndex, updateCrumbsData } = documents.actions;
export default documents.reducer;
