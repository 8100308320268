import React, { useState, useEffect } from 'react'
import WelcomePage from './welcome'
import UpcomingAppointments from './upcoming-appointments'
import TaskAssignment from './task-assigned'
import ApprovalWaiting from './approval-waiting'
import UserManagement from './userManagement'
import ProjectProgress from './project-progress'
import Tasks from './current-tasks'
import TasksList from './tasks'
import GridLayout from "react-grid-layout";
import AddNewUser from './userManagement/addNewUser'
import EditProfile from './userManagement/editProfile';
import DeleteUser from './userManagement/deleteUser';
import RoleModal from './userManagement/roleModal';
import DeleteRole from './userManagement/deleteRole';
import AddNewUserRole from './userManagement/addUserRole';
import AddNewTask from './tasks/addNewTask'
import EditTask from './tasks/editTask';
import NewAppointmentModal from './upcoming-appointments/newUpcomingAppoint'
import RescheduleAppointment from './upcoming-appointments/RescheduleAppointment'
import ViewProgressModal from './welcome/viewProgress'
import ApprovalTaskDetails from './approval-waiting/viewDetails'
import CloseIcon from '../assests/images/close.svg';


import { rtdb } from '../pages/firebase/firebase'
import { onValue, child, get, ref, set, getDatabase } from "firebase/database"
import apiServiceHandler from "../service/apiService";
import { getUnreadNotificationCount, updateNotificationList } from '../redux/notifications'
import { updateRefreshUserDatap, updateRoleList, updateUsers } from '../redux/userManagement';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux'
import { updateAnalyticGraphPopup, updateDocumentsPopUp, updateUpcomingAppointmentPopup, updatechatsPopup, updateprogressPopup, updatetaskApprovalPopup, updatetaskAssignedPopup, updatetaskNotToClientPopup, updatetaskPopup, updateuserMgtPopup, updateProjectProgressPopup, updateInvoiceListPopup, updateClickupTasksListPopup, updateUsermgtActiveTab } from '../redux/viewmore'

import Chats from './chats'
import TimeTracking from './tasks/timeTracking'
import Documents from './documents'
import AddNewFolder from './documents/addNewFolder'
import AddNewFile from './documents/addNewFile'
import DeleteFolder from './documents/deleteFolder'
import RenameFolder from './documents/renameFolder'
import NewTickets from './chats/newTickets'
import EditTickets from './chats/editTicket'
import NewFileFolder from './documents/NewFolderFile'
import AllFiles from './documents/AllFiles'
import ShareFolder from './documents/ShareFolder'
import { updateRefreshFolderData } from '../redux/documents'
import InvoiceList from "../pages/invoice/listingPage";
import AnalyticsPage from './analytics-graph'
import ClickupTasks from './clickupTasks'

const Index = () => {

  const [userManagementProps, setUserManagementProps] = useState(null)
  const [tasksListProps, setTasksListProps] = useState(null)
  const [chatsandTicketsListProps, setChatsandTicketsListProps] = useState(null)
  const [upcomingAppointmentsProps, setUpcomingAppointmentsProps] = useState(null)
  const [welcomePageProps, setWelcomePageProps] = useState(null)
  const [documentsProps, setDocumentsProps] = useState(null)

  const [projectProgressProps, setProjectProgressProps] = useState(null)
  const [graphReportProps, setGraphReportsProps] = useState(null)
  const [invoiceListProps, setInvoiceListProps] = useState(null)
  const [clickupTasksListProps, setClickupTasksListProps] = useState(null)

  const dispatch = useDispatch()
  const upcomingPopup = useSelector(state => state.viewmore)
  const [viewMoreTaskProps, setViewMoreTaskProps] = useState(null)
  let userData = localStorage.getItem("userData")
  userData = userData ? JSON.parse(userData) : {}
  const [showPageFlag, setShowPageFlag] = useState("")

  const fetchuseData = async () => {
    try {
      const response = await apiServiceHandler("GET", `api/auth/all_users_list`, {});
      const res = await apiServiceHandler("GET", "api/auth/accounts_created_by_user", {});

      const accessToAllProject = userData.access_to_apis.access_to_all_project ? (userData.company_id ? userData.company_id : userData.parent_company_id) : ''
      const projectRes = await apiServiceHandler("POST", "api/project/client/list", { company_id: accessToAllProject });

      const resUsers = response.allUsersList
      const accUsers = res.message

      let result = resUsers.concat(accUsers).filter((item, index, arr) => {
        return arr.findIndex((t) => t.unique_url === item.unique_url) === index;
      });

      const projectUsers = []

      Object.keys(projectRes.data).forEach(element => {
        projectUsers.push(projectRes.data[element].client_data)
      });

      result = result.concat(projectUsers).filter((item, index, arr) => {
        return arr.findIndex((t) => t.unique_url === item.unique_url) === index;
      });

      // setFilteredData(response?.all_users_list)
      dispatch(updateUsers(result))
    } catch (err) {
      // toastr.error(err?.response?.data?.message)
      console.log(err)
    }
  }
  useEffect(() => {
    fetchuseData()
    return () => {
      //   second
    }
  }, [])

  let x = 0, y = 0
  let initialLayout = [
    { i: "welcomePage", x: x, y: y, w: 1, h: 1 },
  ];

  if (userData.access_to_apis.calendar) {
    initialLayout.push({ i: "calendar", x: ++x, y: y, w: 1, h: 1 });
  }
  if (userData.access_to_apis.own_task) {
    initialLayout.push({ i: "ownTask", x: x, y: ++y, w: 2, h: 1.065 });
  }
  // if (userData.access_to_apis.client_task) {
  //   initialLayout.push({ i: "clickupTasksPage", x: x, y: ++y, w: 2, h: 1.20 });
  // }
  if (userData.access_to_apis.waiting_for_user_approval) {
    initialLayout.push({ i: "approvalWaiting", x: x, y: ++y, w: 2, h: 1.47 });
  }
  if (userData.access_to_apis.progress) {
    initialLayout.push({ i: "projectProgress", x: x, y: ++y, w: 2, h: 1.87 });
  }
  if (userData.access_to_apis.all_tasks) {
    initialLayout.push({ i: "allTasks", x: x, y: ++y, w: 2, h: 1.24 });
  }
  if (userData.access_to_apis.chats_and_tickets) {
    initialLayout.push({ i: "chats", x: x, y: ++y, w: 2, h: 1.52 });
  }
  if (userData.access_to_apis.user_management) {
    initialLayout.push({ i: "userManagement", x: x, y: ++y, w: 2, h: 1.37 });
  }
  if (userData.access_to_apis.files_and_folders) {
    initialLayout.push({ i: "documents", x: x, y: ++y, w: 2, h: 1.25 });
  }
  if (userData.access_to_apis.reports) {
    initialLayout.push({ i: "graphPage", x: x, y: ++y, w: 2, h: 1.38 });
  }
  if (userData.access_to_apis.invoices) {
    initialLayout.push({ i: "invoices", x: x, y: ++y, w: 2, h: 1.28 });
  }

  const localLayout = userData.layout?.length > 0 ? userData.layout : initialLayout;
  const [layout, setLayout] = useState(localLayout);
  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
    userData.layout = newLayout
    localStorage.setItem('userData', JSON.stringify(userData));
    updateCurrentUserLayout(newLayout);
  };

  // Update Layout when someone change their layout
  const updateCurrentUserLayout = async (newLayout) => {
    let payload = {
      "user": {
        "uniqueUrl": `${userData.unique_url}`,
        "layout": newLayout
      }
    }
    try {
      await apiServiceHandler("PUT", "api/auth/manage_user_data", payload)
    } catch (err) {
      console.log(err)
    }
  }

  // useEffect(() => {
  //   if (userData.layout && userData.layout.length > 0) {
  //     setLocalLayout(userData.layout);
  //   }
  // }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1280);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    getNotification()
    userUnreadNotificationMap()

  })

  const sendNotification = async (notification, receiverUniqueUrl, timestampString, notificationRead, from, projectName) => {
    if (notification === "") {
      alert("Enter a valid notification");
      return;
    }
    if (notificationRead == undefined) notificationRead = false
    const timestampDate = new Date();
    var timestampString = timestampString || timestampDate.getTime();

    let databasePath = receiverUniqueUrl + "_notification";

    const parentRef = ref(rtdb, databasePath);

    // Use the `get` method inside a `then` block to ensure it completes before further processing
    get(parentRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          // Data exists, so create a new child object
          const childObjectRef = child(parentRef, `notification_${timestampString}`);
          return set(childObjectRef, {
            notification: notification,
            createdAt: timestampString,
            uniqueUrl: receiverUniqueUrl,
            notificationRead: notificationRead,
            projectName: projectName || "",
            from: from || ""
          });
        } else {
          // Data doesn't exist, so set the parent object and create a child object
          return set(parentRef, {}).then(() => {
            const childObjectRef = child(parentRef, `notification_${timestampString}`);
            return set(childObjectRef, {
              notification: notification,
              createdAt: timestampString,
              uniqueUrl: receiverUniqueUrl,
              notificationRead: notificationRead,
              projectName: projectName || "",
              from: from || ""
            });
          });
        }
      })
      .then(() => {
        console.log("Notification sent successfully");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const userUnreadNotificationMap = async () => {
    // let userData = localStorage.getItem("userData")
    // userData = userData ? JSON.parse(userData) : {}
    let uniqueUrl = userData?.unique_url

    const starCountRef = ref(rtdb, "/");

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        let map = {}
        Object.keys(data).forEach(x => {
          if (x == (uniqueUrl + "_notification")) {
            Object.keys(data[x]).forEach((y) => {
              if (data[x][y]["notificationRead"] != undefined && data[x][y]["notificationRead"] == false) {
                if (data[x][y]["uniqueUrl"] == uniqueUrl) {
                  if (!map[data[x][y]["uniqueUrl"]]) {
                    map[data[x][y]["uniqueUrl"]] = 1;
                  } else {
                    map[data[x][y]["uniqueUrl"]]++;
                  }
                }
              } else if (data[x][y]["notificationRead"] != undefined && data[x][y]["notificationRead"] == true) {
                if (data[x][y]["uniqueUrl"] == uniqueUrl) {
                  if (!map[data[x][y]["uniqueUrl"]]) {
                    map[data[x][y]["uniqueUrl"]] = 0;
                  }
                }
              }
            });
          }
        });
        // console.log("read", map[uniqueUrl]);
        dispatch(getUnreadNotificationCount(map[uniqueUrl]))
      } else {
        // Handle the case when data is null or undefined
        // Invoke the callback with an empty array
        console.log("")
      }
    })
  }

  const getNotification = async () => {
    // let userData = localStorage.getItem("userData")
    // userData = userData ? JSON.parse(userData) : {}

    let databasePath = userData.unique_url + "_notification"

    const starCountRef = ref(rtdb, databasePath);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let notifications = [];
      if (data) {
        Object.keys(data).forEach((x) => {
          notifications.push(data[x]);
        });
        notifications.sort((a, b) => b.createdAt - a.createdAt);
        // sendPushNotification(notifications[notifications.length - 1]["message"])
        // console.log("notification", notifications) // Invoke the callback with the chats data
        dispatch(updateNotificationList(notifications))
      } else {
        // Handle the case when data is null or undefined
        console.log([])// Invoke the callback with an empty array
      }
    });
  };
  const userMarkNotificationRead = async () => {
    // let userData = localStorage.getItem("userData")
    // userData = userData ? JSON.parse(userData) : {}
    let uniqueUrl = userData?.unique_url

    let databasePath = userData?.uniqueUrl + "_" + "notification"

    const dbRef = ref(getDatabase());

    get(child(dbRef, databasePath)).then((snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val()

        if (data) {
          Object.keys(data).forEach(async (x) => {

            // console.log(data[x])

            if (data[x]["notificationRead"] != undefined && data[x]["notificationRead"] == false) {
              await sendNotification(data[x]["notification"], data[x]["uniqueUrl"], data[x]["createdAt"], true, data[x]["from"], data[x]["projectName"])
            }

          });
        } else {
          // Handle the case when data is null or undefined
          // Invoke the callback with an empty array
          console.log("")
        }
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <section>
      <div className="container-fluid">
        <div className="row">

          {isMobile && <div className='mobile-container'>
            <WelcomePage setWelcomePageProps={setWelcomePageProps} />
            {
              userData.access_to_apis.calendar &&
              <UpcomingAppointments setUpcomingAppointmentsProps={setUpcomingAppointmentsProps} />
            }
            {
              userData.access_to_apis.own_task &&
              <TaskAssignment setTasksListProps={setTasksListProps} />
            }
            {/*
            {
              userData.access_to_apis.own_task &&
              <ClickupTasks setClickupTasksListProps={setClickupTasksListProps} />
            }
            */}
            {
              userData.access_to_apis.waiting_for_user_approval &&
              <ApprovalWaiting setTasksListProps={setTasksListProps} />
            }
            {
              userData.access_to_apis.progress &&
              <ProjectProgress setProjectProgressProps={setProjectProgressProps} />
            }
            {
              userData.access_to_apis.all_tasks &&
              <TasksList setTasksListProps={setTasksListProps} />
            }
            {
              userData.access_to_apis.chats_and_tickets &&
              <Chats setChatsandTicketsListProps={setChatsandTicketsListProps} />
            }
            {
              userData.access_to_apis.user_management &&
              <UserManagement setUserManagementProps={setUserManagementProps} />
            }
            {
              userData.access_to_apis.files_and_folders &&
              <Documents setDocumentsProps={setDocumentsProps} showPageFlag={showPageFlag} setShowPageFlag={setShowPageFlag} />
            }
            {
              userData.access_to_apis.invoices &&
              <InvoiceList setInvoiceListProps={setInvoiceListProps} />
            }
            {
              userData.access_to_apis &&
              <AnalyticsPage setGraphReportsProps={setGraphReportsProps} />
            }

            {/* <AnalyticsPage setGraphReportsProps={setGraphReportsProps} /> */}
          </div>}

          {!isMobile && <GridLayout
            className="layout"
            layout={layout}
            cols={2}
            rowHeight={345}
            autoSize={true}
            isRearrangeable
            draggableHandle=".drag-me"
            width={window.innerWidth * 0.98}
            onLayoutChange={onLayoutChange}
          >
            {layout.map((item) => (
              <div
                key={item.i}
              >
                {item.i === "welcomePage" && <WelcomePage setWelcomePageProps={setWelcomePageProps} />}
                {item.i === "calendar" && <UpcomingAppointments setUpcomingAppointmentsProps={setUpcomingAppointmentsProps} />}
                {item.i === "ownTask" && <TaskAssignment setTasksListProps={setTasksListProps} />}
                {/*
                {item.i === "clickupTasksPage" && <ClickupTasks setClickupTasksListProps={setClickupTasksListProps} />}
                */}
                {item.i === "approvalWaiting" && <ApprovalWaiting setTasksListProps={setTasksListProps} />}
                {item.i === "projectProgress" && <ProjectProgress setProjectProgressProps={setProjectProgressProps} />}
                {/* 
                {item.i === "tasks" && <Tasks setTasksListProps={setTasksListProps} />}
                */}
                {item.i === "allTasks" && <TasksList setTasksListProps={setTasksListProps} />}
                {item.i === "chats" && <Chats setChatsandTicketsListProps={setChatsandTicketsListProps} />}
                {item.i === "documents" && <Documents setDocumentsProps={setDocumentsProps} showPageFlag={showPageFlag} setShowPageFlag={setShowPageFlag} />}
                {item.i === "userManagement" && <UserManagement setUserManagementProps={setUserManagementProps} />}
                {item.i === "invoices" && <InvoiceList setInvoiceListProps={setInvoiceListProps} />}
                {item.i === "graphPage" && <AnalyticsPage setGraphReportsProps={setGraphReportsProps} />}
              </div>
            ))}

          </GridLayout>}
          {/* UserManagement Modals */}
          {userManagementProps && userManagementProps.ModalName == 'AddNewUser' ? <AddNewUser {...userManagementProps} /> : ''}
          {userManagementProps && userManagementProps.ModalName == 'EditProfile' ? <EditProfile {...userManagementProps} /> : ''}
          {userManagementProps && userManagementProps.ModalName == 'DeleteUser' ? <DeleteUser {...userManagementProps} /> : ''}
          {userManagementProps && userManagementProps.ModalName == 'RoleModal' ? <RoleModal {...userManagementProps} /> : ''}
          {userManagementProps && userManagementProps.ModalName == 'DeleteRole' ? <DeleteRole {...userManagementProps} /> : ''}
          {userManagementProps && userManagementProps.ModalName == 'AddNewUserRole' ? <AddNewUserRole {...userManagementProps} /> : ''}

          {/* Tasks Modals */}
          {tasksListProps && tasksListProps.ModalName == 'AddNewTask' ? <AddNewTask {...tasksListProps} /> : ''}
          {tasksListProps && tasksListProps.ModalName == 'EditTask' ? <EditTask {...tasksListProps} /> : ''}
          {tasksListProps && tasksListProps.ModalName == 'TimeTracking' ? <TimeTracking {...tasksListProps} /> : ''}
          {tasksListProps && tasksListProps.ModalName == 'ViewDetails' ? <ApprovalTaskDetails {...tasksListProps} setTasksListProps={setTasksListProps} /> : ''}

          {/* Chat Modals */}
          {chatsandTicketsListProps && chatsandTicketsListProps.ModalName == "AddNewTickets" ? <NewTickets {...chatsandTicketsListProps} /> : ''}
          {chatsandTicketsListProps && chatsandTicketsListProps.ModalName == "EditTickets" ? <EditTickets {...chatsandTicketsListProps} /> : ''}

          {/* Documents Props */}
          {documentsProps && documentsProps.ModalName == 'AddNewFolder' ? <AddNewFolder {...documentsProps} /> : ''}
          {documentsProps && documentsProps.ModalName == 'AddNewFile' ? <AddNewFile {...documentsProps} /> : ''}
          {documentsProps && documentsProps.ModalName == 'DeleteFolder' ? <DeleteFolder {...documentsProps} /> : ''}
          {documentsProps && documentsProps.ModalName == 'RenameFolder' ? <RenameFolder {...documentsProps} /> : ''}
          {documentsProps && documentsProps.ModalName == 'RenameChildFolder' ? <RenameFolder {...documentsProps} /> : ''}
          {documentsProps && documentsProps.ModalName == 'AddChildFolder' ? <NewFileFolder {...documentsProps} /> : ''}
          {documentsProps && documentsProps.ModalName == 'ShareFolder' ? <ShareFolder {...documentsProps} /> : ''}

          {/* UpcomingAppoinments Modals */}
          {upcomingAppointmentsProps && upcomingAppointmentsProps.ModalName == 'NewAppointment' ? <NewAppointmentModal {...upcomingAppointmentsProps} /> : ''}
          {upcomingAppointmentsProps && upcomingAppointmentsProps.ModalName == 'OpenReschedule' ? <RescheduleAppointment {...upcomingAppointmentsProps} /> : ''}

          {/* WelcomePage Modals */}
          {welcomePageProps && welcomePageProps.ModalName == 'ViewProgress' ? <ViewProgressModal {...welcomePageProps} /> : ''}

          {/* Analytics Modals */}
          {/* {graphReportProps && graphReportProps.ModalName == 'ViewProgress' ? <ViewProgressModal {...welcomePageProps} /> : ''} */}

          {clickupTasksListProps && clickupTasksListProps.ModalName == 'AddNewTask' ? <AddNewTask {...clickupTasksListProps} /> : ''}
          {clickupTasksListProps && clickupTasksListProps.ModalName == 'EditTask' ? <EditTask {...clickupTasksListProps} /> : ''}

          {(upcomingPopup.upcomingAppointmentPopup || upcomingPopup.taskAssignedPopup || upcomingPopup.taskNotToClientPopup || upcomingPopup.taskPopup || upcomingPopup.taskApprovalPopup || upcomingPopup.userMgtPopup || upcomingPopup.viewProgressPopup || upcomingPopup.chatsPopup || upcomingPopup.documentsPopup || upcomingPopup.projectProgressPopup || upcomingPopup.analyticGraphPopup || upcomingPopup.invoiceListPopup || upcomingPopup.clickupTasksListPopup) && <div

            className={`modal fade grey-popup ${true ? "show backdrop viewmore-expand" : " "}`}
            id="modals"
            tabindex="-1"
            aria-labelledby="modalLabel" aria-hidden="true"
            style={{ display: true ? "block" : "none" }}
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">

                      <li className="breadcrumb-item active" aria-current="page">
                        {upcomingPopup.upcomingHeadline}
                      </li>
                    </ol>
                  </nav>
                  <div className="mark-done d-flex align-items-center">


                    <button type="button"

                      className="btn-close" onClick={() => {
                        dispatch(updateUpcomingAppointmentPopup(false));
                        dispatch(updatetaskAssignedPopup(false));
                        dispatch(updatetaskNotToClientPopup(false));
                        dispatch(updatetaskPopup(false));
                        dispatch(updatetaskApprovalPopup(false));
                        dispatch(updateuserMgtPopup(false));
                        dispatch(updateprogressPopup(false));
                        dispatch(updatechatsPopup(false));
                        dispatch(updateDocumentsPopUp(false));
                        dispatch(updateProjectProgressPopup(false));
                        dispatch(updateAnalyticGraphPopup(false));
                        dispatch(updateInvoiceListPopup(false));
                        dispatch(updateClickupTasksListPopup(false));
                        dispatch(updateUsermgtActiveTab("manage-users"));


                      }} data-bs-dismiss="modal" aria-label="Close">
                      <img src={CloseIcon} />
                    </button>
                  </div>
                </div>
                <div className="modal-body pt-0">
                  {upcomingPopup.upcomingAppointmentPopup && <UpcomingAppointments flag={true} setUpcomingAppointmentsProps={setUpcomingAppointmentsProps} />}
                  {upcomingPopup.taskAssignedPopup && <TaskAssignment flag={true} setTasksListProps={setTasksListProps} />}
                  {upcomingPopup.clickupTasksListPopup && <ClickupTasks flag={true} setClickupTasksListProps={setClickupTasksListProps} />}
                  {upcomingPopup.taskNotToClientPopup && <Tasks flag={true} setTasksListProps={setTasksListProps} />}
                  {upcomingPopup.taskPopup && <TasksList flag={true} setTasksListProps={setTasksListProps} />}
                  {upcomingPopup.taskApprovalPopup && <ApprovalWaiting flag={true} setTasksListProps={setTasksListProps} />}
                  {upcomingPopup.userMgtPopup && <UserManagement flag={true} setUserManagementProps={setUserManagementProps} />}
                  {upcomingPopup.projectProgressPopup && <ProjectProgress flag={true} />}
                  {upcomingPopup.chatsPopup && <Chats flag={true} setChatsandTicketsListProps={setChatsandTicketsListProps} />}
                  {upcomingPopup.documentsPopup && <Documents setDocumentsProps={setDocumentsProps} flag={true} showPageFlag={showPageFlag} setShowPageFlag={setShowPageFlag} />}
                  {upcomingPopup.analyticGraphPopup && <AnalyticsPage flag={true} setGraphReportsProps={setGraphReportsProps} />}
                  {upcomingPopup.invoiceListPopup && <InvoiceList flag={true} setInvoiceListProps={setInvoiceListProps} />}
                </div>

              </div>
            </div>
          </div>}



        </div>
      </div>
    </section>
  );
};

export default Index;
