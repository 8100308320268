import React, { useEffect, useState } from 'react';
import DeleteIcon from '../../assests/images/delete.svg';
import PlusIcon from '../../assests/images/plus.svg';
import SearchIcon from '../../assests/images/search.svg';
import Trash from '../../assests/images/delete-black.svg';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux';
import { updateAccessRoleList, updateDeleteRoleData, updateDeleteRolePopup, updateUserToAccessData, updateUserToAccessRole } from '../../redux/userManagement';

const ManageUser = ({ setIsRoleOpen, setIsOpenAddUser, flag, refreshRoleList, setRefreshRoleList, setIsOpenDeleteUserRole, setIsUserRoleOpen }) => {
    const [roleAccessList, setRoleAccessList] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const upcomingPopup = useSelector(state => state.viewmore)
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState("")
    let debounceTimer;
    const debounceTimeout = 500; // 500ms delay
    const user = JSON.parse(localStorage.getItem('userData'));

    const fetchRoleData = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/company_details", {})
            setRoleAccessList(getRolesWithAccessAsArray(response.companyDetails))
            dispatch(updateAccessRoleList(roleAccessList))
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchRoleData()
    }, [])

    useEffect(() => {
        if (refreshRoleList) {
            fetchRoleData()
            setRefreshRoleList(false)
        }
    }, [refreshRoleList])


    const getRolesWithAccessAsArray = (data) => {
        const companyData = data[0]
        const rolesWithAccessArray = [];

        if (companyData && companyData.access_to_apis) {
            for (const roleName in companyData.access_to_apis) {
                if (companyData.access_to_apis.hasOwnProperty(roleName)) {
                    const access = companyData.access_to_apis[roleName];
                    const accessArray = Object.entries(access)
                        .filter(([_, value]) => value === true)
                        .map(([key]) => key);

                    rolesWithAccessArray.push({ role: roleName, access: accessArray });
                }
            }

            // Handle roles with no access
            const roles = companyData.roles;
            for (const roleName of roles) {
                if (!rolesWithAccessArray.some(item => item.role === roleName)) {
                    rolesWithAccessArray.push({ role: roleName, access: [] });
                }
            }
        }

        return rolesWithAccessArray;
    }

    const handleSearch = (e) => {
        e.preventDefault();
        const filterData = roleAccessList.filter(value => value?.role?.includes(searchValue) || searchValue.trim() === "")
        setFilteredData(filterData)
    }

    useEffect(() => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleSearch({ preventDefault: () => { } })
        }, debounceTimeout);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue, roleAccessList]);

    const handleEditRoles = async (role, value) => {
        const payload = {
            company_id: user.company_id ? user.company_id : user.parent_compnay_id,
            role: role,
            accessToApis: {
                [value]: false
            }
        }
        try {
            const response = await apiServiceHandler("PUT", "api/auth/company_details", payload)
            setRefreshRoleList(true)
            toastr.success(response?.message)
        } catch (err) {
            toastr.error("error in updating role")
            console.log(err)
        }
    }
    const handleUserRoleAccess = (value) => {
        setIsUserRoleOpen(true)
        dispatch(updateUserToAccessData(value?.access))
        dispatch(updateUserToAccessRole(value?.role))

    }
    const handleDeleteRole = (data) => {
        const role = data.role;
        if (role === 'admin' || role === 'team') {
            toastr.error(`You can not delete ${role.charAt(0).toUpperCase() + role.slice(1)} Role`);
        } else {
            setIsOpenDeleteUserRole(true)
            dispatch(updateDeleteRolePopup(true))
            dispatch(updateDeleteRoleData(data))
        }
    }

    return (
        <div>
            <div class={flag ? "manage-roles-tab-search d-flex align-items-center" : "manage-roles-tab-search d-flex align-items-center view-line"}>
                <form onSubmit={handleSearch}>
                    <input onChange={(e) => setSearchValue(e.target.value)} type="text" name="searchusers" placeholder="Search..." class="form-control" />
                    <button type="submit"><img src={SearchIcon} /></button>
                </form>
                {!flag && <button type="button" class="add-icon-two" onClick={() => setIsRoleOpen(true)}><img data-placement="top" title="Add New Role" src={PlusIcon} /></button>}
            </div>
            <div class="manage-roles-tab">
                <div class="manage-roles-tab-label d-lg-block mb-3">
                    <div class="row">
                        <div class="col-lg-2">
                            <h3>Role</h3>
                        </div>
                        <div class="col-lg-9">
                            <h3>Access To</h3>
                        </div>
                        {/* <div class="col-lg-1 text-lg-center">
                            <h3>Add</h3>
                        </div> */}
                    </div>
                </div>
                <div class={flag ? "manage-roles-tab-data-viewmore" : "manage-roles-tab-data"}>
                    {filteredData && filteredData.map((data) => (
                        <div class="row g-0 align-items-center">
                            <div class="col-lg-2">
                                {/* <label class="mb-0">Role</label> */}
                                <p class="mb-0">{data?.role.split('_')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}</p>
                            </div>
                            <div class="col-lg-9">
                                {/* <label class="mb-0">Access To</label> */}
                                <ul class="list-unstyled m-0 access-to">
                                    {data?.access && data.access?.map((value, i) => (
                                        <li>{value.split('_')
                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                            .join(' ')}<button type="button" class="delete-role"><img data-placement="top" title="delete access" onClick={() => handleEditRoles(data?.role, value)} src={DeleteIcon} /></button></li>
                                    ))}
                                </ul>
                                
                            </div>
                            <div class="col-lg-1">
                                {/* <label class="mb-0">Add</label> */}
                                <div class="d-flex align-items-center justify-content-end delete-btn">
                                    <div>
                                        <button type="button" data-bs-target="#userRoleModal" className="add-role" onClick={() => handleUserRoleAccess(data)}><img src={PlusIcon} width="28" /></button>
                                        <button type="button" data-bs-target="#deleteRoleModal" className="delete-roles m-2" onClick={() => handleDeleteRole(data)}><img src={Trash} width="20" /></button>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ManageUser