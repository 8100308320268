import React, { useEffect, useState } from 'react'
import './signup.css'
import Logo from '../../assests/images/LogoSouthBisonConnect.svg'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
// import { baseUrl } from '../../service/apiService'
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from '../../service/apiService'
import { countries } from 'countries-list';

const SignUp = () => {
    const baseUrl = process.env.REACT_APP_baseUrl;
    const [currentPage, setCurrentPage] = useState(1);
    const [uniqueUrlvalue, setUniqueUrlvalue] = useState('')
    const [uniqueUrlStatusMessage, setUniqueUrlStatusMessage] = useState("")
    const signupUrl = `${baseUrl}/api/auth/signup`
    const baseUniqueUrl = `${baseUrl}/api/auth/unique/url`
    const { id } = useParams()
    const navigate = useNavigate()
    const [selectedCountry, setSelectedCountry] = useState({ countryCode: '', countryName: '', countryPhoneCode: '' });

    const handleCountryChange = (e) => {
        setSelectedCountry({
            countryCode: e.target.value,
            countryName: countries[e.target.value].name,
            countryPhoneCode: countries[e.target.value].phone[0],
        });
    };
    const [signupData, setSignupData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        unique_url: '',
        company_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: {},
        zipcode: '',
        login_code: ''
    });

    localStorage.setItem('signupData', JSON.stringify(signupData));

    const handleNext = () => {
        if (signupData.login_code.length < 4) {
            toastr.error("Please enter a valid Login Code")
            return
        }
        if (uniqueUrlStatusMessage) {
            setCurrentPage(2);
        } else {
            toastr.error("Please set unique URL")
        }

    };

    const debounceTimeout = 500; // 500ms delay

    let debounceTimer;

    useEffect(() => {
        if (uniqueUrlvalue === "") {
            setUniqueUrlStatusMessage("")
            return
        }
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            console.log('Performing action with:', uniqueUrlvalue);
            handleVerifyUrl()
        }, debounceTimeout);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [uniqueUrlvalue]);

    const handlePrevious = () => {
        setCurrentPage(1);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setSignupData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSignup = async (e) => {
        e.preventDefault();
        let payload = signupData
        payload.country = selectedCountry;
        // payload.unique_url = uniqueUrlvalue
        try {
            // const response = await axios.post(signupUrl, signupData);
            const response = await apiServiceHandler("POST", "api/auth/signup", payload);
            // console.log(response)
            if (response?.status === true) {
                toastr.success(response?.message);
                setSelectedCountry({ countryCode: '', countryName: '', countryPhoneCode: '' })
                localStorage.setItem('responseData', JSON.stringify(response));
                localStorage.setItem('secret', response?.secret)
                navigate(`/otp-verify`)
            }

        } catch (e) {
            // navigate(`/otp-verify}`)
            // toastr.error("Please enter the following details")
            toastr.error(e?.message)
            console.error(e)
        }
    }
    const handleVerifyUrl = async (e) => {
        const payload = {
            uniqueUrl: uniqueUrlvalue
        }
        try {
            // const response = await axios.post(baseUniqueUrl, payload);
            const response = await apiServiceHandler("POST", "api/auth/unique/url", payload);
            console.log(response)
            if (response?.status) {
                setUniqueUrlStatusMessage(true)
                setSignupData(prevData => ({
                    ...prevData,
                    unique_url: uniqueUrlvalue
                }));
            } else {
                setUniqueUrlStatusMessage(false)
            }
        } catch (e) {
            console.error(e)
            setUniqueUrlStatusMessage(false)
        }
    }
    return (
        <div className="bg-image">
            <div className="bg-box">
                <div className="logo text-center mb-4">
                    <img alt='' src={Logo} />
                </div>

                <div id="signupPage" className="signup-page shadow">
                    <div className="page-heading mb-3">
                        <div className="row align-items-center" >
                            <div className="col-md-8">
                                <p>Welcome to <strong>South Bison Connect</strong></p>
                            </div>

                            <div className="col-md-4">
                                <h4>Already have an Account?<br /><a href="" style={{ textDecoration: 'none' }} onClick={() => navigate("/signin-url")} >Sign in</a></h4>
                            </div>


                        </div>


                    </div>
                    <h1 className="mb-3">Sign up</h1>
                    <div className="signup-form1">
                        {currentPage === 1 && (<form>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label for="exampleFormControlInput1" className="form-label" >First Name</label>
                                    <input type="text" name="first_name" className="form-control" id="exampleFormControlInput1" value={signupData.first_name} onChange={handleChange} placeholder="First Name" />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label for="exampleFormControlInput1" className="form-label" >Last Name</label>
                                    <input name="last_name" className="form-control" id="exampleFormControlInput1" placeholder="Last Name" value={signupData.last_name} onChange={handleChange} />
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label for="exampleFormControlInput1" className="form-label" >Email</label>
                                    <input name="email" className="form-control" id="exampleFormControlInput1" placeholder="Email" value={signupData.email} onChange={handleChange} />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label for="exampleFormControlInput1" className="form-label" >Contact Number</label>
                                    <input type="tel" name="phone" className="form-control" id="exampleFormControlInput1" placeholder="Contact Number" value={signupData.phone} onChange={handleChange} />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label for="exampleFormControlInput1" className="form-label" >Login Code
                                        <span className="tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Enter your 4-digit login code.">
                                            <i className="bi bi-info-circle ms-1"></i>
                                        </span></label>
                                    <input type="text" name="login_code" maxLength="4" className="form-control" title="Enter your login code" id="exampleFormControlInput1" placeholder="Login Code" value={signupData.login_code} onChange={handleChange} />
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label for="exampleFormControlInput1" className="form-label" >JLL Unique URL</label>
                                    <input name="unique_url" defaultValue="www.southbisonconnect/" className="form-control" id="exampleFormControlInput1" placeholder="Unique Url" value={uniqueUrlvalue} onChange={(e) => setUniqueUrlvalue(e.target.value)} />
                                    {uniqueUrlStatusMessage === true ?
                                        <p style={{ color: 'green', textAlign: 'left', marginTop: '5px' }}>Unique URL is available</p> :
                                        uniqueUrlStatusMessage === false ?
                                            <p style={{ color: 'red', textAlign: 'left', marginTop: '5px' }}>Unique URL is Unavailable</p> : <></>}
                                </div>

                                <div className="btn-group">
                                    {currentPage === 2 && <button type="button" className="prev-btn"> Back </button>}
                                    <button type="button" className="next-btn" onClick={handleNext}>Next</button>

                                </div>
                            </div>

                        </form>)}
                        {currentPage === 2 && (<form>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label for="exampleFormControlInput1" className="form-label" >Company Name</label>
                                    <input name="company_name" className="form-control" id="exampleFormControlInput1" placeholder="Company Name" value={signupData.company_name} onChange={handleChange} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label for="exampleFormControlInput1" className="form-label" >Address Line 1</label>
                                    <input name="address_line_1" className="form-control" id="exampleFormControlInput1" placeholder="Address Line 1" value={signupData.address_line_1} onChange={handleChange} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label for="exampleFormControlInput1" className="form-label" >Address Line 2</label>
                                    <input name="address_line_2" className="form-control" id="exampleFormControlInput1" placeholder="Address Line 2" value={signupData.address_line_2} onChange={handleChange} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label for="exampleFormControlInput1" className="form-label" >City</label>
                                    <input name="city" className="form-control" id="exampleFormControlInput1" placeholder="City" value={signupData.city} onChange={handleChange} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label for="exampleFormControlInput1" className="form-label" >State</label>
                                    <input name="state" className="form-control" id="exampleFormControlInput1" placeholder="State" value={signupData.state} onChange={handleChange} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label for="exampleFormControlInput1" className="form-label" >Country</label>
                                    <select
                                        value={selectedCountry.countryCode}
                                        onChange={handleCountryChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="">Select a country*</option>
                                        {Object.keys(countries).map((countryCode) => (
                                            <option key={countryCode} value={countryCode}>
                                                {countries[countryCode].name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label for="exampleFormControlInput1" className="form-label" >Postal Code</label>
                                    <input name="zipcode" className="form-control" id="exampleFormControlInput1" placeholder="Postal Code" value={signupData.zipcode} onChange={handleChange} />
                                </div>



                                <div className="btn-group">
                                    <button type="button" className="prev-btn" onClick={handlePrevious}>Back</button>
                                    <button type="button" className="next-btn" onClick={handleSignup}>Next</button>

                                </div>
                            </div>
                        </form>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
