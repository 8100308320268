import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import { useParams } from 'react-router-dom';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshUserDatap } from '../../redux/userManagement';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsersCount } from '../../redux/userlogs';

const EditProfile = ({ edituserData, isOpen, onClose }) => {
    const [userUpdatedData, setUserUpdatedData] = useState(edituserData)
    const [rolesList, setRolesList] = useState([])
    const [selectRole, setSelectRole] = useState("")
    const user = JSON.parse(localStorage.getItem('userData'));
    const paymentDetails = useSelector(state => state.allUserData.paymentDetails.details)
    const userLists = useSelector(state => state.allUserData.users)
    const userListsCount = useSelector(state => state.userlogs.usersCount)
    const ownedUser = userLists.filter(acc => (acc.owned_by === user.owned_by ? user.owned_by : user.unique_url) && acc.status === 1);
    const { id } = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        setUserUpdatedData(edituserData)
    }, [edituserData]);
    const [errorMessage, setErrorMessage] = useState('');

    let usermData = localStorage.getItem("userData")
    usermData = usermData ? JSON.parse(usermData) : {}

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "status") {
            setUserUpdatedData((prevUser) => ({
                ...prevUser,
                [name]: value === 'Active' ? 1 : 0,
            }));
            return
        }
        setUserUpdatedData((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleCompanyChange = (event) => {
        const { name, value } = event.target;
        let existingcompanyName = usermData?.company_name

        setUserUpdatedData((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        if (value !== existingcompanyName) {
            setErrorMessage(`External to ${existingcompanyName}`);
        } else {
            setErrorMessage('');
        }
    }
    const handleEditUser = async (e) => {
        e.preventDefault();
        const payload = {
            "user": {
                "uniqueUrl": userUpdatedData.unique_url,
                "firstName": userUpdatedData.first_name,
                "lastName": userUpdatedData.last_name,
                "role": userUpdatedData.role,
                "email": userUpdatedData.email,
                "state": userUpdatedData.state,
                "status": userUpdatedData.status,
                "addressLine1": userUpdatedData.address_line_1,
                "addressLine2": userUpdatedData.address_line_2,
                "city": userUpdatedData.city,
                "zipCode": userUpdatedData.zipcode,
                "country": userUpdatedData.country,
                "phone": userUpdatedData.phone,
            }
        }
        try {
            if (edituserData.role != userUpdatedData.role) {
                payload.user.layout = [];
            }
            if (edituserData.status != 1 && userUpdatedData.status === 1 && userListsCount >= paymentDetails.user_quantity) {
                toastr.error('You can not change the status as you are exceeding your plans User Quantity')
                throw new Error('You can not change the status as you are exceeding your plans User Quantity')
            }
            const response = await apiServiceHandler("PUT", "api/auth/manage_user_data", payload)
            const userCount = await apiServiceHandler(
                "POST",
                "api/auth/user/users/count",
                { unique_url: user.owned_by ? user.owned_by : user.unique_url }
            );
            dispatch(updateUsersCount(userCount.data));
            toastr.success(response?.message)
            dispatch(updateRefreshUserDatap(true))
            onClose()
            // if (edituserData.status != payload.user.status) {
            //     if (paymentDetails?.payment_details?.id) {
            //         let revisePayload = {
            //             subscriptionID: paymentDetails.payment_details.id,
            //             planId: paymentDetails.payment_details.plan_id,
            //             quantity: (user.owned_by ? ownedUser.length - 1 : ownedUser.length)
            //         }
            //         if (payload.user.status) {
            //             revisePayload.quantity = (user.owned_by ? ownedUser.length + 1 : ownedUser.length + 2)
            //         }
            //         const updatedDetails = await apiServiceHandler('POST', 'api/payment/paypal/subscription/upgrade', revisePayload)
            //         window.location.replace(updatedDetails?.data.links[0].href)
            //     }
            // }
        } catch (err) {
            toastr.error("error updating user")
            console.log("error", err)
        }
    }

    const fetchRoleList = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/get_roles_list", {})
            setRolesList(response?.role_list)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchRoleList()
    }, [])

    return (
        <div>
            <div
                className={`modal fade edit-profile edit-task-viewmore ${isOpen ? "show backdrop" : " "}`}
                id="editProfileModal"
                tabindex="-1"
                aria-labelledby="editProfileModalLabel"
                aria-hidden="true"
                style={{ display: isOpen ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h2 className="modal-title">Edit Profile</h2>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <img src={CloseIcon} />
                            </button>
                        </div>

                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <label>First name</label>
                                        <input type="text" className="form-control" placeholder="First name*" name='first_name' value={userUpdatedData.first_name} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Last name</label>
                                        <input type="text" className="form-control" name='last_name' placeholder="Last name*" value={userUpdatedData.last_name} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Email</label>
                                        <input type="email" className="form-control" name='email' placeholder="Email*" value={userUpdatedData.email} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Role</label>
                                        <select onChange={(e) => handleChange(e)} value={userUpdatedData?.role} name='role' placeholder='select role' className="form-select" aria-label="status">
                                            <option>Select role</option>
                                            {rolesList && rolesList.map(role => <option value={role}>{role}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Contact Number <span>(optional)</span></label>
                                        <input type="text" className="form-control" name='phone' placeholder="Contact Number" value={userUpdatedData?.phone} onChange={handleChange} />
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                        <label>Login PIN</label>
                                        <input type="text" className="form-control" name='login_code' placeholder="Set Login PIN*" value="Set Login PIN" />
                                    </div> */}
                                    <div className="col-6 mb-2">
                                        <label>URL</label>
                                        <input disabled type="text" className="form-control" name='unique_url' value={userUpdatedData?.unique_url} placeholder="southbisonconnect.com*" onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Company Name</label>
                                        <input type="text" className="form-control" name='company_name' value={userUpdatedData?.company_name} placeholder="Company Name*" onChange={handleCompanyChange} />
                                        {errorMessage && <p style={{ color: 'red', textAlign: 'left', margin: '2px', fontSize: '15px' }}>{errorMessage}</p>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Status</label>
                                        <select name='status' onChange={handleChange} value={userUpdatedData?.status === 1 ? "Active" : "Inactive"} className="form-select" aria-label="status">
                                            <option>Select status</option>
                                            <option value="Active" selected>Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Address Line 1</label>
                                        <input type="text" className="form-control" placeholder="Address Line 1*" name='address_line_1' value={userUpdatedData.address_line_1} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>Address Line 2</label>
                                        <input type="text" className="form-control" placeholder="Address Line 2*" name='address_line_2' value={userUpdatedData.address_line_2} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>City</label>
                                        <input type="text" className="form-control" placeholder="City" name='city' value={userUpdatedData.city} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label>State</label>
                                        <input type="text" className="form-control" placeholder="State*" name='state' value={userUpdatedData.state} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6 mb-2 mb-md-0">
                                        <label>Country</label>
                                        <input type="text" className="form-control" placeholder="Country*" name='country' value={userUpdatedData.country} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Postal Code</label>
                                        <input type="text" className="form-control" placeholder="Postal Code*" name='zipcode' value={userUpdatedData.zipcode} onChange={handleChange} />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="submit" className="bg-btn" onClick={handleEditUser}>Next</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditProfile;
