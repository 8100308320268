// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const notification = createSlice({
  name: 'notification',
  initialState: {
    refreshProjectList: false,
    notification: [],
    unreadNotificationCount: 0
    
  },
  reducers: {
    updateNotificationList: (state, action) => {
      state.notification = action.payload; // Update the count using the payload
    },
    getUnreadNotificationCount: (state, action) => {
      state.unreadNotificationCount = action.payload; // Update the count using the payload
    },
    
    
  },
});

export const { updateNotificationList, getUnreadNotificationCount } = notification.actions;
export default notification.reducer;
