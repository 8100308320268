import React, { useEffect, useState } from 'react'
import './tasksList.css';
import CloseIcon from '../../assests/images/close.svg';
import StartBlue from '../../assests/images/start-blue.svg';
import StopRed from '../../assests/images/stop-red.svg';
import Manual from '../../assests/images/manual.svg';
import EditIcon from '../../assests/images/edit.svg';
import apiServiceHandler from '../../service/apiService';
import { updateEditTaskData, updateRefreshTaskList } from '../../redux/task';
import { useDispatch, useSelector } from 'react-redux';
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const formatDate = (inputDate) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(new Date(inputDate));
  return formattedDate;
};
function formatTimeString(timeString) {
  const [hours, minutes] = timeString.split(',');
  return `${parseInt(hours)}h ${parseInt(minutes)}m`;
}

export function convertArrayToMinutes(inputArray) {
  if (inputArray && inputArray.length > 0) {
    const timeArray = inputArray.map(obj => obj.time);
    const totalMinutes = timeArray.reduce((sum, timeString) => {
      const [hours, minutes] = timeString.split(',').map(Number);
      return sum + hours * 60 + minutes;
    }, 0);

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`
  }

  return `${0}h ${0}m`
}

export function formatTimeConvert(timeArray) {
  const arrayToProcess = Array.isArray(timeArray) ? timeArray : [timeArray];

  let totalHours = 0;
  let totalMinutes = 0;

  arrayToProcess.forEach(item => {
    if (item.time && typeof item.time === 'string' && item.time.trim() !== '') {
      const [hours, minutes] = item.time.split(',').map(Number);
      totalHours += hours;
      totalMinutes += minutes;
    }
  });

  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  const formattedTime = `${totalHours}h ${totalMinutes}m`;
  return formattedTime;
}

export function getCurrentFormattedDate() {
  let date = new Date()
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getTimeDifference(date1, date2) {
  const diffInMilliseconds = Math.abs(new Date(date2) - new Date(date1));
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  const formattedDifference = `${hours},${minutes}`;
  return formattedDifference;
}

export function formatDateToISOString(dateString) {
  const dateObject = new Date(dateString);
  const isoString = dateObject.toISOString();
  return isoString;
}

const TimeTracking = ({ isOpen, onClose }) => {
  const [clickManual, setClickManual] = useState(false);
  const [date, setDate] = useState('')
  const [description, setDescription] = useState('')
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [getTimeTracking, setGetTimeTracking] = useState('')
  const [isEdit, setIsEdit] = useState(false);
  const [editDesc, setEditDesc] = useState('');
  const [editHours, setEditHours] = useState('');
  const [editMinutes, setEditMinutes] = useState('');
  const [editDate, setEditDate] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editIntervalId, setEditIntervalId] = useState(null);
  const [clickStart, setClickStart] = useState(false);

  const dispatch = useDispatch()
  const myTimeTracking = useSelector(state => state.task.editData)
  const timetrackedData = useSelector(state => state.task.TasksList.assignedToMe)
  const myTimeTrackingTaskData = timetrackedData.find(obj => obj._id === myTimeTracking._id);
  let userData = localStorage.getItem("userData");
  userData = userData ? JSON.parse(userData) : {};
  let currentUserId = userData?.user_id

  const isAssigned = myTimeTracking?.assigned_to.includes(currentUserId);

  const handleDate = (event) => {
    setDate(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleHours = (event) => {
    setHours(event.target.value);
  };
  const handleMinutes = (event) => {
    setMinutes(event.target.value);
  };
  const handleClickManual = () => {
    if (isAssigned) {
      clickManual ? setClickManual(false) : setClickManual(true)
    }
    else {
      toastr.error("You are not assigned to this task")
    }

  }
  const handleManualSave = () => {

    if (myTimeTrackingTaskData?.category != "clickup") {
      addManualTime()
    } else {
      addClickupManualTime()
    }
    setDescription('')
    setDate('')
    setHours('')
    setMinutes('')
  }
  // Add Manual Time API
  const addManualTime = async () => {
    let payload = {
      "timeTracked": `${hours || 0},${minutes || 0}`,
      "taskId": myTimeTrackingTaskData._id,
      "description": description || `Time Tracked ( ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()} )`,
      "date": date || getCurrentFormattedDate()
    };
    try {
      await apiServiceHandler("POST", "api/tasks/update/time/tracking", payload)
      dispatch(updateEditTaskData(myTimeTrackingTaskData))
      dispatch(updateRefreshTaskList(true))
      getAllTimeTracking()
    } catch (err) {
      console.log(err)
    }
  }

  // Add Manual Time API CLICKUP
  const addClickupManualTime = async () => {
    let payload = {
      "timeTracked": `${hours || 0},${minutes || 0}`,
      "id": myTimeTrackingTaskData._id,
      "description": description || `Time Tracked ( ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()} )`,
      "date": date || getCurrentFormattedDate()
    };
    try {
      await apiServiceHandler("POST", "api/tasks/track_time", payload)
      dispatch(updateEditTaskData(myTimeTrackingTaskData))
      // dispatch(updateRefreshTaskList(true))
      getClickupAllTimeTracking()
    } catch (err) {
      console.log(err)
    }
  }

  // Start Stop Button
  const handleClickStart = () => {
    if (isAssigned) {
      setClickStart(true)
      let payload = {
        "id": myTimeTrackingTaskData._id,
        "live_time_tracking_flag": true,
        "live_time_tracking_start_time": new Date()
      };
      updateTask(payload, false)
    } else {
      // Display message indicating user is not assigned to this task
      // console.log("You are not assigned to this task.");
      toastr.error("You are not assigned to this task.")
    }
    // setClickStart(true)
    // let payload = {
    //   "id": myTimeTrackingTaskData._id,
    //   "live_time_tracking_flag": true,
    //   "live_time_tracking_start_time": new Date()
    // };
    // updateTask(payload, false)
  }
  const handleClickStop = () => {
    setClickStart(false)
    let payload = {
      "id": myTimeTrackingTaskData._id,
      "live_time_tracking_flag": false,
    };

    // if (myTimeTrackingTaskData?.category == "clickup") {

    // }
    updateTask(payload, true)
  }

  // Task Update API
  const updateTask = async (payload, flag) => {
    try {
      if (myTimeTrackingTaskData?.category == "clickup") {
        payload.isClickUpTask = true
        payload.clickup_id = payload.id
        delete payload["id"]
      }
      await apiServiceHandler("POST", "api/task/update", payload)
      dispatch(updateRefreshTaskList(true))
      dispatch(updateEditTaskData(myTimeTrackingTaskData))
      if (flag && myTimeTrackingTaskData?.category != "clickup") {
        addStartStopTime()
      } else if (flag && myTimeTrackingTaskData?.category == "clickup") {
        addClickupStartStopTime()
      }
    } catch (err) {
      console.log(err)
    }
  }
  const addStartStopTime = async () => {
    let currentTime = new Date()
    let parsedTimeStart = formatDateToISOString(currentTime)
    let time = getTimeDifference(myTimeTrackingTaskData.live_time_tracking_start_time, parsedTimeStart)
    const descTimeStamph = `${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}:${new Date().getSeconds().toString().padStart(2, '0')}`;
    let payload = {
      "timeTracked": time,
      "taskId": myTimeTrackingTaskData._id,
      "description": `Time Tracked ( ${descTimeStamph} )`,
      "date": getCurrentFormattedDate(),
      "to": parsedTimeStart,
      "from": myTimeTrackingTaskData.live_time_tracking_start_time
    };
    try {
      await apiServiceHandler("POST", "api/tasks/update/time/tracking", payload)
      dispatch(updateEditTaskData(myTimeTrackingTaskData))
      getAllTimeTracking()
    } catch (err) {
      console.log(err)
    }
  }
  const addClickupStartStopTime = async () => {
    let currentTime = new Date()
    let parsedTimeStart = formatDateToISOString(currentTime)
    let time = getTimeDifference(myTimeTrackingTaskData.live_time_tracking_start_time, parsedTimeStart)
    const descTimeStamph = `${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}:${new Date().getSeconds().toString().padStart(2, '0')}`;
    let payload = {
      "timeTracked": time,
      "id": myTimeTrackingTaskData._id,

      "description": `Time Tracked (${descTimeStamph})`,
      "date": getCurrentFormattedDate()

    };
    try {
      await apiServiceHandler("POST", "api/tasks/track_time", payload)
      dispatch(updateEditTaskData(myTimeTrackingTaskData))
      getClickupAllTimeTracking()
    } catch (err) {
      console.log(err)
    }
  }

  // Edit Time Tracking
  const handleEditTime = (index, item) => {
    if (isAssigned) {
      const [hours, minutes] = item.time.split(',');
      setIsEdit(true)
      setEditDesc(item.description);
      setEditHours(hours)
      setEditMinutes(minutes)
      setEditDate(item.date)
      setEditIndex(index)
      setEditIntervalId(item.id)
    } else {
      // Display message indicating user is not assigned to this task
      // console.log("You are not assigned to this task.");
      toastr.error("You cannot not edit this time period.")
    }
    // const [hours, minutes] = item.time.split(',');
    // setIsEdit(true)
    // setEditDesc(item.description);
    // setEditHours(hours)
    // setEditMinutes(minutes)
    // setEditDate(item.date)
    // setEditIndex(index)
    // setEditIntervalId(item.id)
  }

  const handleEditDate = (event) => {
    setEditDate(event.target.value);
  };
  const handleEditDesc = (event) => {
    setEditDesc(event.target.value);
  };
  const handleEditHours = (event) => {
    setEditHours(event.target.value);
  };
  const handleEditMinutes = (event) => {
    setEditMinutes(event.target.value);
  };
  const handleEditManualSave = () => {

    if (myTimeTrackingTaskData?.category != "clickup") {
      EditTime()
    }
    else {
      clickupEditTime()
    }
    setEditDesc('')
    setEditDate('')
    setEditHours('')
    setEditMinutes('')
    setEditIndex(null)
    setEditIntervalId(null)
    setIsEdit(false)
  }

  // Edit Time API
  const EditTime = async () => {
    let payload = {
      "timeTracked": `${editHours},${editMinutes}`,
      "taskId": myTimeTrackingTaskData._id,
      "description": editDesc,
      "date": editDate,
      "index": (editIndex + 1)
    };
    try {
      await apiServiceHandler("POST", "api/tasks/update/time/tracking", payload)
      dispatch(updateEditTaskData(myTimeTrackingTaskData))
      dispatch(updateRefreshTaskList(true))
      getAllTimeTracking()
    } catch (err) {
      console.log(err)
    }
  }
  const clickupEditTime = async () => {
    let payload = {
      "timeTracked": `${editHours},${editMinutes}`,
      "id": myTimeTrackingTaskData._id,
      "description": editDesc,
      "date": editDate,
      "intervalId": editIntervalId,
    };
    try {
      await apiServiceHandler("POST", "api/tasks/edit_time_tracked", payload)
      dispatch(updateEditTaskData(myTimeTrackingTaskData))
      // dispatch(updateRefreshTaskList(true))
      getClickupAllTimeTracking()
    } catch (err) {
      console.log(err)
    }
  }

  // Get All Time Tracking API
  const getAllTimeTracking = async () => {
    let payload = {
      "taskId": myTimeTrackingTaskData ? myTimeTrackingTaskData?._id : myTimeTracking?._id,
    };
    try {
      const response = await apiServiceHandler("POST", "api/tasks/get_time_tracking", payload)
      setGetTimeTracking(response)
    } catch (err) {
      // toastr.error(err?.response?.data?.message)

      console.log(err)
    }
  }
  const getClickupAllTimeTracking = async () => {
    let payload = {
      "id": myTimeTrackingTaskData ? myTimeTrackingTaskData?._id : myTimeTracking?._id,
    };
    try {
      const response = await apiServiceHandler("POST", "api/tasks/get_time_tracking_clickup", payload)
      setGetTimeTracking(response)
    } catch (err) {
      // toastr.error(err?.response?.data?.message)

      console.log(err)
    }
  }

  useEffect(() => {

    if (myTimeTrackingTaskData?.category == "clickup") {
      getClickupAllTimeTracking()
    } else {
      getAllTimeTracking()
    }
    if (myTimeTrackingTaskData?.live_time_tracking_flag) {
      setClickStart(true)
    } else {
      setClickStart(false)
    }
  }, [myTimeTrackingTaskData])

  return (
    <div
      className={`modal fade time-tracking-modal ${isOpen ? "show backdrop" : " "} timeTrackinIndex`}
      id="timeTrackingModal"
      tabIndex={-1}
      aria-labelledby="timeTrackingModalLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0">
          <div className="modal-header">
            <h2 className="modal-title">Time Tracking</h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <img src={CloseIcon} />
            </button>
          </div>
          <div className="modal-body">
            <div className="task-list mb-2">
              <ul className="m-2">
                {getTimeTracking.length > 0 ? getTimeTracking?.map((item, index) => (
                  <li>
                    <div className="row g-0">
                      <div className="col-md-7 f-500">{item.description}</div>
                      <div className="col-md-2 f-500 text-md-end">{formatTimeString(item.time)}</div>
                      <div className="col-md-3 text-md-end">{formatDate(item.date)}
                        <button className='no-default ms-5 editButton' type='button'>
                          <img src={EditIcon} width={15} onClick={() => handleEditTime(index, item)} />
                        </button>
                      </div>
                    </div>
                  </li>
                )) : <div style={{ alignItems: "center" }}><h5>No Data...</h5></div>}
              </ul>
            </div>

            <div className="total-time row mb-2">
              <div className="col-md-8">Total</div>
              <div className="col-md-4 text-md-end">{formatTimeConvert(getTimeTracking)}</div>
            </div>
            <div className="time-panel row">
              <div className="col-6">
                {clickStart ? <button
                  type="button"
                  id="stopTime"
                  className="no-default"
                  onClick={() => handleClickStop()}
                  style={{ display: "block", color: 'red' }}
                >
                  <img src={StopRed} width={22} />
                  Stop
                </button>
                  :
                  <button type="button"
                    id="startTime"
                    className="no-default"
                    onClick={() => handleClickStart()}
                    style={{ display: "block" }}
                  >
                    <img src={StartBlue} width={22} />
                    Start
                  </button>
                }

              </div>

              <div className="col-6">

                <button
                  type="button"
                  id="manualTime"
                  className="no-default ms-auto"
                  onClick={handleClickManual}
                >
                  <img src={Manual} width={22} />
                  Manual
                </button>
              </div>
            </div>
            {clickManual && <div className="time-desc mt-3" style={{ display: "block" }}>
              <textarea
                className="form-control border-0 p-0"
                placeholder="Add Description"
                rows={2}
                defaultValue={""}
                required
                value={description}
                onChange={handleDescription}
              />
              <div className="manual-time row mt-2">
                <div className="col-md-9">
                  <div className="manual-input d-md-flex align-items-center">
                    <div>
                      <label>Select Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="00:00"
                        required
                        value={date}
                        onChange={handleDate}
                      />
                    </div>
                    <div className="time-dots">:</div>
                    {/* <div className='mb-3 mb-md-0 mt-3 mt-md-0'> */}
                      <div className='mt-3 mb-3'>
                        <label>Enter Hours</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="00"
                          required
                          value={hours}
                          onChange={handleHours}
                        />
                      </div>
                      <div className='mt-3 mt-md-0'>
                        <label>Enter Minutes</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="00"
                          value={minutes}
                          onChange={handleMinutes}
                        />
                      </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-md-3 text-md-end mt-2">
                  <button type="submit" className="bg-btn" onClick={handleManualSave}>
                    Save
                  </button>
                </div>
              </div>
            </div>}

            {isEdit && isAssigned && <div className="time-desc mt-3" style={{ display: "block" }}>
              <textarea
                className="form-control border-0 p-0"
                placeholder="Add Description"
                rows={2}
                defaultValue={editDesc}
                required
                value={editDesc}
                onChange={handleEditDesc}
              />
              <div className="manual-time row mt-2">
                <div className="col-md-9">
                  <div className="manual-input d-flex align-items-center">
                    <div>
                      <label>Select Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={editDate}
                        required
                        value={editDate}
                        onChange={handleEditDate}
                      />
                    </div>
                    <div className="time-dots">:</div>
                    {/* <div className='mb-3 mb-md-0 mt-3 mt-md-0'> */}
                      <div className='mt-3 mb-3'>
                        <label>Enter Hours</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={editHours}
                          required
                          value={editHours}
                          onChange={handleEditHours}
                        />
                      </div>
                      <div className='mt-3 mt-md-0'>
                        <label>Enter Minutes</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={editMinutes}
                          value={editMinutes}
                          onChange={handleEditMinutes}
                        />
                      </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-md-3 text-md-end mt-2">
                  <button type="submit" className="bg-btn" onClick={handleEditManualSave}>
                    Save
                  </button>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeTracking
