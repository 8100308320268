import React, { useEffect, useRef, useState } from 'react'
import EditIcon from '../../assests/images/edit.svg';
import EditwhiteIcon from '../../assests/images/pencil-white.svg';
import { useDispatch } from 'react-redux';
import { updateEditAndDeleteData, updateOpenDeletePopup } from '../../redux/userManagement';

const Actions = (props) => {
    const dispatch = useDispatch()
    const { userData, setEdituserData, setIsOpenEditUser } = props
    const [optionsVisible, setOptionsVisible] = useState(false);
    const toggleOptions = () => {
        setOptionsVisible((prevIsVisible) => !prevIsVisible);
        //   setOptionsVisible((prevOption) => (prevOption === option ? null : option));
    };
    const modalRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (modalRef.current && !(modalRef.current).contains(event.target)) {
            setOptionsVisible(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleDelete = () => {
        dispatch(updateOpenDeletePopup(true))
        dispatch(updateEditAndDeleteData(userData))
    }

    return (
        <div class="col-lg-1 text-lg-center user-action-col">
            {/* <label class="mb-0">Action</label> */}
            <div class="dropdown">
                <button type="button" class="user-action dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{window.screen.width > 760  ? <img src={EditIcon} /> : <img src={EditwhiteIcon} />}</button>
                <div class="user-action-data dropdown-menu">
                    <button type="button" data-bs-target="#editProfileModal" onClick={() => { setEdituserData(userData); setIsOpenEditUser(true) }}>Edit Profile</button>
                    <button type="button" data-bs-target="#deleteUserModal" onClick={() => handleDelete()}>Delete</button>
                </div>
            </div>
        </div>
    )
}
export default Actions;






