// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const viewmore = createSlice({
  name: 'viewmore',
  initialState: {
    upcomingAppointmentPopup: false,
    taskPopup: false,
    taskNotToClientPopup: false,
    taskAssignedPopup: false,
    taskApprovalPopup: false,
    userMgtPopup: false,
    chatsPopup: false,
    viewProgressPopup: false,
    upcomingHeadline: [],
    documentsPopup: false,
    projectProgressPopup: false,
    analyticGraphPopup: false,
    invoiceListPopup: false,
    clickupTasksListPopup: false,
    usermgtActiveTab: "manage-users"
  },
  reducers: {
    updateUpcomingAppointmentPopup: (state, action) => {
      state.upcomingAppointmentPopup = action.payload; // Update the count using the payload
    },
    updatetaskPopup: (state, action) => {
      state.taskPopup = action.payload; // Update the count using the payload
    },
    updatetaskNotToClientPopup: (state, action) => {
      state.taskNotToClientPopup = action.payload; // Update the count using the payload
    },
    updatetaskAssignedPopup: (state, action) => {
      state.taskAssignedPopup = action.payload; // Update the count using the payload
    },
    updatetaskApprovalPopup: (state, action) => {
      state.taskApprovalPopup = action.payload; // Update the count using the payload
    },
    updateuserMgtPopup: (state, action) => {
      state.userMgtPopup = action.payload; // Update the count using the payload
    },
    updatechatsPopup: (state, action) => {
      state.chatsPopup = action.payload; // Update the count using the payload
    },
    updateprogressPopup: (state, action) => {
      state.viewProgressPopup = action.payload; // Update the count using the payload
    },
    updateupcomingHeadline: (state, action) => {
      state.upcomingHeadline = action.payload; // Update the count using the payload
    },
    updateDocumentsPopUp: (state, action) => {
      state.documentsPopup = action.payload
    },
    updateProjectProgressPopup: (state, action) => {
      state.projectProgressPopup = action.payload
    },
      updateAnalyticGraphPopup: (state, action) => {
      state.analyticGraphPopup = action.payload
    },
    updateInvoiceListPopup: (state, action) => {
      state.invoiceListPopup = action.payload
    },
    updateClickupTasksListPopup: (state, action) => {
      state.clickupTasksListPopup = action.payload
    },
    updateUsermgtActiveTab: (state, action) => {
      state.usermgtActiveTab = action.payload
    },
   
    
  },
});

export const { updateUpcomingAppointmentPopup, updateInvoiceListPopup, updateAnalyticGraphPopup, updatechatsPopup, updateprogressPopup, updatetaskPopup, updatetaskNotToClientPopup, updateupcomingHeadline, updatetaskApprovalPopup, updatetaskAssignedPopup, updateuserMgtPopup, updatewelcomePopup, updateDocumentsPopUp, updateProjectProgressPopup, updateClickupTasksListPopup, updateUsermgtActiveTab  } = viewmore.actions;

export default viewmore.reducer;
